import { Route, Routes } from "react-router-dom";
import AddressDetailsContainer from "../../../../../Container/Health/CARE/AddressDetailsContainer/AddressDetailsContainer";
import AdultOneDetailsContainer from "../../../../../Container/Health/CARE/AdultOneDetailsContainer/AdultOneDetailsContainer";
import MemberDetailContainer from "../../../../../Container/Health/CARE/AdultTwoDetailsContainer/AdultTwoDetailsContainer";
import ChildFourDetailsContainer from "../../../../../Container/Health/CARE/ChildFourDetailsContainer/ChildFourDetailsContainer";
import ChildOneDetailsContainer from "../../../../../Container/Health/CARE/ChildOneDetailsContainer/ChildOneDetailsContainer";
import ChildThreeDetailsContainer from "../../../../../Container/Health/CARE/ChildThreeDetailsContainer/ChildThreeDetailsContainer";
import ChildTwoDetailsContainer from "../../../../../Container/Health/CARE/ChildTwoDetailsContainer/ChildTwoDetailsContainer";
import InsuredMemberContainer from "../../../../../Container/Health/CARE/InsuredMemberContainer/InsuredMemberContainer";
import PreviewDetailsContainer from "../../../../../Container/Health/CARE/PreviewDetailsContainer/PreviewDetailsContainer";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

export const COMMON_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}>
        <Route
          path={HEALTH_ROUTE.ADULT_ONE_DETAILS}
          element={<AdultOneDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.INSURED_MEMBER}
          element={<InsuredMemberContainer />}
        />
        <Route
          path={HEALTH_ROUTE.ADULT_TWO_DETAILS}
          element={<MemberDetailContainer />}
        />
        <Route
          path={HEALTH_ROUTE.CHILD_ONE_DETAILS}
          element={<ChildOneDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.CHILD_TWO_DETAILS}
          element={<ChildTwoDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.CHILD_THREE_DETAILS}
          element={<ChildThreeDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.CHILD_FOUR_DETAILS}
          element={<ChildFourDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.ADDRESS_DETAILS}
          element={<AddressDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.PREVIEW_DETAILS}
          element={<PreviewDetailsContainer />}
        />
      </Route>
    </Routes>
  );
};
