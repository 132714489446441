import { useEffect, useState } from "react";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import MedicalDetail from "../../../Page/Desktop/Health/CIGNA/MedicalDetails";
import { TCignaMedicalQuestion } from "../../../types/Health/HealthQuotation/TCignaMedicalQuestion";
import {
  Consume_alcohal,
  diabetes_complication,
  diabetes_current_status,
  diabetes_diagnosis,
  diabetes_histopathology,
  diabetes_treatment,
  tobacco_consume,
  tobacco_year,
} from "./MedicalDropdownValue";
import { toast } from "react-toastify";
import MMedicalDetail from "../../../Page/Mobile/Health/CIGNA/MMedicalDetails"


const MedicalDetailContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_CIGNA,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
  } = useAppSelector((state) => state.Health);
  const [change_status, setchange_status] = useState(false);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TCignaMedicalQuestion>(MEDICAL_QUESTION_DATA_CIGNA)

  console.log("medicalQuestionData", medicalQuestionData)
  const currentYear = new Date().getFullYear();


  const AdultOneyearOptions = Array.from(
    { length: currentYear - parseInt(`${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`.split("-")[2]) + 1 },
    (_, index) => {
      const year = parseInt(`${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`.split("-")[2]) + index;
      return { label: String(year), value: String(year) };
    }
  );

  const AdultTwoyearOptions = Array.from(
    { length: currentYear - parseInt(`${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`.split("-")[2]) + 1 },
    (_, index) => {
      const year = parseInt(`${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`.split("-")[2]) + index;
      return { label: String(year), value: String(year) };
    }
  );

  const ChildOneyearOptions = Array.from(
    { length: currentYear - parseInt(`${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`.split("-")[2]) + 1 },
    (_, index) => {
      const year = parseInt(`${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`.split("-")[2]) + index;
      return { label: String(year), value: String(year) };
    }
  );

  const ChildTwoyearOptions = Array.from(
    { length: currentYear - parseInt(`${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`.split("-")[2]) + 1 },
    (_, index) => {
      const year = parseInt(`${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`.split("-")[2]) + index;
      return { label: String(year), value: String(year) };
    }
  );

  const ChildThreeyearOptions = Array.from(
    { length: currentYear - parseInt(`${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`.split("-")[2]) + 1 },
    (_, index) => {
      const year = parseInt(`${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`.split("-")[2]) + index;
      return { label: String(year), value: String(year) };
    }
  );

  const ChildFouryearOptions = Array.from(
    { length: currentYear - parseInt(`${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`.split("-")[2]) + 1 },
    (_, index) => {
      const year = parseInt(`${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`.split("-")[2]) + index;
      return { label: String(year), value: String(year) };
    }
  );

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_CIGNA };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Cancer",
          description:
            "Has any of the applicants ever been diagnosed with or suspected to have Cancer or Rheumatoid Arthritis or Ulcerative Colitis or Crohn's disease or Chronic Liver Disease, Hepatitis B, Cirrhosis or Chronic Kidney Disease or Kidney failure or Epilepsy or Fits or Stroke or Paralysis or Parkinsonism or Alzheimer's or Multiple sclerosis or Brain Tumor or Cerebral Palsy or Heart Failure or Heart Attack or Angina or Coronary Artery Disease or Ischemic Heart Disease or Chronic Bronchitis or Intestitial Lung Diseases or Pneumoconiosis or Emphysema.?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "Hospitalised",
          description:
            "Has any member ever suffered or currently suffering from; operated, hospitalised, investigated, under treatment for or been under medication for more than a week for any medical condition?",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Diabetes Mellitus?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "Hypertension?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "High Cholesterol?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ4-SQ4": {
              id: "MQ4-SQ4",
              icon_class: "",
              status: false,
              title: "Thyroid disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ5-SQ5": {
              id: "MQ5-SQ5",
              icon_class: "",
              status: false,
              title: "Heart and Lung disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ6-SQ6": {
              id: "MQ6-SQ6",
              icon_class: "",
              status: false,
              title: "Digestive system disorders (Stomach and related organs)?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ7-SQ7": {
              id: "MQ7-SQ7",
              icon_class: "",
              status: false,
              title: "Brain, nerve and Psychiatric (Mental) disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ8-SQ8": {
              id: "MQ8-SQ8",
              icon_class: "",
              status: false,
              title: "Other Endocrine (Hormonal) disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ9-SQ9": {
              id: "MQ9-SQ9",
              icon_class: "",
              status: false,
              title: "Bone, joints and muscle disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ10-SQ10": {
              id: "MQ10-SQ10",
              icon_class: "",
              status: false,
              title: "Ear, nose, eye and throat disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ11-SQ11": {
              id: "MQ11-SQ11",
              icon_class: "",
              status: false,
              title: "Genito-urinary and Gynaecological disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ12-SQ12": {
              id: "MQ12-SQ12",
              icon_class: "",
              status: false,
              title: "Blood and related disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ13-SQ13": {
              id: "MQ13-SQ13",
              icon_class: "",
              status: false,
              title: "Skin disorders?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
            "MQ14-SQ14": {
              id: "MQ14-SQ14",
              icon_class: "",
              status: false,
              title: "Any other condition / illness / disorder / surgery?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: AdultTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildOneyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildTwoyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildThreeyearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Treatment taken",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_treatment,
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Current Status",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_current_status,
                    },
                    "MQ1-SQ1-F3": {
                      id: "MQ1-SQ1-F3",
                      title: "Complication/Recurrences",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: diabetes_complication,
                    },
                    "MQ1-SQ1-F4": {
                      id: "MQ1-SQ1-F4",
                      title: "Diagnosis year",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: ChildFouryearOptions,
                    },
                    "MQ1-SQ1-F5": {
                      id: "MQ1-SQ1-F5",
                      title: "Last Consultation Date",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F6": {
                      id: "MQ1-SQ1-F6",
                      title: "Histo-Pathology Examination Report",
                      field_type: "DROPDOWN_SURGICAL",
                      value: { value: "", warning: false },
                      data_value: diabetes_histopathology,
                    },
                  },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "Pre-Existing Diseases",
          description:
            "Has any of the applicant recommended to undergo or has undergone any pathologic or radiologic tests for any illness other than the ones listed above and routine or annual health check-up?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "Good Health",
          description:
            "Are you currently in good health and not undergoing any treatment or medication for any illness/medical condition (Physical, Psychiatric, Mental illness /disorders, Sleep disorders)?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "Intoxication Declaration",
          description:
            "Select 'Yes' if any insured member Smokes, consumes Tobacco in any form, or drinks Alcohol?",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Does any of the members consume Alcohol?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohol",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohol",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohol",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohol",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohol",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohol",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: Consume_alcohal,
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "Does any of the members consume smoking?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Smoking",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Smoking",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Smoking",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohol",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Smoking",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Smoking",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "Does any of the members consume tobacco?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Tobacco",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_year,
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Tobacco",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_year,
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Tobacco",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_year,
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Alcohol",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_consume,
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Tobacco",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_year,
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Tobacco",
                      field_type: "DROPDOWN",
                      value: { value: "", warning: false },
                      data_value: tobacco_year,
                    },
                  },
                },
              },
            },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "Critical Illness",
          description:
            "Have any first degree relatives (i.e. parents, brothers, sisters or children) of any of the applicants (who are not themselves applicants for this insurance policy) had cancer, motor neurone disease or any other hereditary disorders?",
          status: false,
        },
      },
      MQ07: {
        main_question: {
          id: "MQ07",
          icon_class: "tobaco",
          title: "Terminal illness(Personal Accident)",
          description:
            "Does any proposed to be insured suffer from any terminal illness, seizure disorders or any disease/deformity affecting or restricting mobility, sight, hearing or speech?",
          status: false,
        },
      },
      MQ08: {
        main_question: {
          id: "MQ08",
          icon_class: "tobaco",
          title: "Hazardous Activities(Personal Accident)",
          description:
            "Does any proposed to be insured's occupation or nature of duties require them to be a part of armed forces, expose them to hazardous substances/chemicals or hazardous activities?",
          status: false,
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_CIGNA);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_CIGNA]);

  let flag = false;

  const updateMasterState = (attrName: any, value: any) => {
    flag = value;
    // debugger;

    // console.log('sss',flag)
    console.log(attrName, "attrName", value, medicalQuestionData);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      console.log(manipulatedMedicalQuestion[attrName[1]], "Before Update");
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      if (value === false) {
        if (["MQ02", "MQ05"].includes(attrName[1])) {
          // Set all subquestions to false for MQ02 and MQ05
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question
          ).forEach((subQuestionKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              subQuestionKey
            ].status = false;
          });
        } else if (["MQ01", "MQ03", "MQ04"].includes(attrName[1])) {
          // Set selection status of all member data to false for MQ01, MQ03, MQ04
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.member_data
          ).forEach((key) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
              key
            ].selection_status = false;
          });
        }
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      setchange_status(flag);

      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
          ],
          status: value,
        },
      };
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };
      if (value === false) {
        // Set mainMember and mainMemberDataField to false and empty
        Object.keys(
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data
        ).forEach((memberDataKey) => {
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data[memberDataKey].selection_status = false;
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[memberDataKey].field_data
          ).forEach((fieldDataKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[memberDataKey].field_data[fieldDataKey].value = {
              value: "",
              warning: false,
            };
          });
        });
        manipulatedMedicalQuestion[attrName[1]].main_question.mainMember =
          false;
        // manipulatedMedicalQuestion[attrName[1]].main_question.mainMemberDataField = '';
      }
      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMember") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };

        if (value === false) {
          // Set mainMember and mainMemberDataField to false and empty
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data[attrName[3]].selection_status = false;
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[attrName[3]].field_data
          ).forEach((fieldDataKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[attrName[3]].field_data[fieldDataKey].value = {
              value: "",
              warning: false,
            };
          });
          manipulatedMedicalQuestion[attrName[1]].main_question.mainMember =
            false;
          // manipulatedMedicalQuestion[attrName[1]].main_question.mainMemberDataField = '';
        }

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
        attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (
      attrName[0] === "mainMemberDataField" &&
      attrName[1] === "MQ02"
    ) {
      console.log(
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[4]
        ].member_data[attrName[2]].field_data[attrName[3]].value
      );
      console.log("value", value);
      manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
        attrName[4]
      ].member_data[attrName[2]].field_data[attrName[3]].value = {
        value: value,
        warning: isEmpty(value),
      };
      console.log(manipulatedMedicalQuestion, "manipulatedMedicalQuestion");

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (
      attrName[0] === "mainMemberDataField" &&
      attrName[1] === "MQ05"
    ) {
      manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
        attrName[4]
      ].member_data[attrName[2]].field_data[attrName[3]].value = {
        value: value,
        warning: isEmpty(value),
      };

      console.log(manipulatedMedicalQuestion, "manipulatedMedicalQuestion");

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };


  const validateForm = () => {
    if (!medicalQuestionData) {
      return;
    }
    let data: TCignaMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;
    let isSurgicalSelected = false;
    console.log("data", data);
    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      console.log("mfsgdgfhgain", mainQuestion);

      // Check if mainQuestion id is MQ02 or MQ05 and status is truthy
      if (
        (mainQuestion?.id === "MQ01" ||
          mainQuestion?.id === "MQ03" ||
          mainQuestion?.id === "MQ04") &&
        mainQuestion.status
      ) {
        const { member_data } = mainQuestion;
        const allSelectionsFalse =
          !member_data?.adult_one?.selection_status &&
          !member_data?.adult_two?.selection_status &&
          !member_data?.child_one?.selection_status &&
          !member_data?.child_two?.selection_status &&
          !member_data?.child_three?.selection_status &&
          !member_data?.child_four?.selection_status;

        if (allSelectionsFalse) {
          toast.error("Please Select Adult Or Child");
          hasError = true;
        }
      }

      if (mainQuestion?.id === "MQ02" && medicalQuestionData.MQ02.main_question.status
      ) {
        // console.log(medicalQuestionData.MQ02.main_question.sub_question["MQ1-SQ1"].status, "prabh3")
        if (
          medicalQuestionData.MQ02.main_question.status &&
          medicalQuestionData.MQ02.main_question.sub_question &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ1-SQ1"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ2-SQ2"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ3-SQ3"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ4-SQ4"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ5-SQ5"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ6-SQ6"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ7-SQ7"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ8-SQ8"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ9-SQ9"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ10-SQ10"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ11-SQ11"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ12-SQ12"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ13-SQ13"]?.status &&
          !medicalQuestionData.MQ02.main_question.sub_question["MQ14-SQ14"]?.status
        ) {
          toast.error("Please Select Sub Question");
          hasError = true;
        }
      }

      if (mainQuestion?.id === "MQ05" && medicalQuestionData.MQ05.main_question.status
      ) {
        if (
          medicalQuestionData.MQ05.main_question.sub_question &&
          !medicalQuestionData.MQ05.main_question.sub_question["MQ1-SQ1"]?.status &&
          !medicalQuestionData.MQ05.main_question.sub_question["MQ2-SQ2"]?.status &&
          !medicalQuestionData.MQ05.main_question.sub_question["MQ3-SQ3"]?.status
        ) {
          toast.error("Please Select Sub Question");
          hasError = true;
        }
      }

    });

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status && mainQuestion.sub_question) {
        Object.values(mainQuestion.sub_question).forEach((subQuestion: any) => {
          if (subQuestion && subQuestion.status) {
            Object.values(subQuestion.member_data).forEach(
              (memberdata: any) => {
                if (
                  memberdata &&
                  memberdata.selection_status &&
                  memberdata.field_data
                ) {
                  Object.values(memberdata.field_data).forEach(
                    (field_data: any) => {
                      console.log(field_data);
                      if (field_data?.id !== "MQ1-SQ1-F6") {
                        if (field_data && field_data.value?.value === "") {
                          let clonedValue = {
                            ...field_data.value,
                            warning: true,
                          };

                          if (field_data.value) {
                            field_data.value = clonedValue;
                          }
                          hasError = true;
                        }
                      }
                    }
                    // }
                  );
                }
              }
            );
          }
        });
      }
    });

    setMedicalQuestionData(data);
    console.log("hasError", hasError);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_CIGNA(data));
      const onSuccess = (res: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

      const param = {
        medical_info: "Yes",
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: "G027",
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medicalCancerDiseases:
          data.MQ01.main_question.status === true ? "Yes" : "NO",
        medicalCancer:
          data.MQ01.main_question.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        spouse_medicalCancer:
          data.MQ01.main_question.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child1_medicalCancer:
          data.MQ01.main_question.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        child2_medicalCancer:
          data.MQ01.main_question.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child3_medicalCancer:
          data.MQ01.main_question.member_data?.child_three.selection_status ===
            true
            ? "Yes"
            : "NO",
        child4_medicalCancer:
          data.MQ01.main_question.member_data?.child_four.selection_status ===
            true
            ? "Yes"
            : "NO",

        ////////////////////////////////////////////////////////

        medicalHospitalizedDiseases:
          data.MQ02.main_question.status === true ? "Yes" : "NO", // Hospitalized(Has any member ever suffered or currently suffering from; operated, hospitalised, investigated, under treatment for or been under medication for more than a week for any medical condition?)

        medicalDiabetesDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.status === true
            ? "Yes"
            : "No", //1. Diabetes Mellitus?

        medicalDiabetes:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        diabetes_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        diabetes_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        diabetes_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        diabetes_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        diabetes_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        diabetes_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        spouse_medicalDiabetes:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_diabetes_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_diabetes_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_diabetes_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_diabetes_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_diabetes_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_diabetes_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child1_medicalDiabetes:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_diabetes_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_diabetes_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_diabetes_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_diabetes_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_diabetes_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_diabetes_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalDiabetes:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_diabetes_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_diabetes_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_diabetes_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_diabetes_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_diabetes_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_diabetes_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalDiabetes:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_diabetes_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_diabetes_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_diabetes_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_diabetes_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_diabetes_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_diabetes_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalDiabetes:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_diabetes_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_diabetes_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_diabetes_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_diabetes_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_diabetes_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_diabetes_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        //////////////////////////////

        medicalHypertensionDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.status === true
            ? "Yes"
            : "No", // Hypertension

        medicalHypertension:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        hypertension_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        hypertension_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        hypertension_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        hypertension_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        hypertension_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        hypertension_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        spouse_medicalHypertension:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_hypertension_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_hypertension_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_hypertension_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_hypertension_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_hypertension_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_hypertension_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child1_medicalHypertension:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_hypertension_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_hypertension_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_hypertension_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_hypertension_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_hypertension_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_hypertension_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalHypertension:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_hypertension_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_hypertension_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_hypertension_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_hypertension_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_hypertension_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_hypertension_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalHypertension:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_hypertension_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_hypertension_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_hypertension_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_hypertension_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_hypertension_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_hypertension_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalHypertension:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_hypertension_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_hypertension_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_hypertension_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_hypertension_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_hypertension_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_hypertension_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        ///////////////////////////////////////////

        cholesterolDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.status === true
            ? "Yes"
            : "No", //3. High Cholesterol

        cholesterol:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        cholesterol_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        cholesterol_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        cholesterol_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        cholesterol_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        cholesterol_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        cholesterol_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        spouse_cholesterol:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_cholesterol_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_cholesterol_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_cholesterol_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_cholesterol_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_cholesterol_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_cholesterol_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child1_cholesterol:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_cholesterol_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_cholesterol_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_cholesterol_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_cholesterol_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_cholesterol_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_cholesterol_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_cholesterol:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_cholesterol_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_cholesterol_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_cholesterol_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_cholesterol_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_cholesterol_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_cholesterol_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_cholesterol:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_cholesterol_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_cholesterol_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_cholesterol_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_cholesterol_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_cholesterol_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_cholesterol_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_cholesterol:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_cholesterol_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_cholesterol_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_cholesterol_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_cholesterol_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_cholesterol_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_cholesterol_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        ///////////////////////////////////////////////////////////////////////////

        medicalEndocrineDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.status === true
            ? "Yes"
            : "No", //4.Thyroid disorders?

        medicalEndocrine:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        endocrine_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        endocrine_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        endocrine_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        endocrine_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        endocrine_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        endocrine_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        spouse_medicalEndocrine:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_endocrine_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_endocrine_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_endocrine_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_endocrine_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_endocrine_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_endocrine_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child1_medicalEndocrine:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_endocrine_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_endocrine_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_endocrine_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_endocrine_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_endocrine_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_endocrine_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalEndocrine:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_endocrine_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_endocrine_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_endocrine_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_endocrine_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_endocrine_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_endocrine_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalEndocrine:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_endocrine_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_endocrine_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_endocrine_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_endocrine_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_endocrine_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_endocrine_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalEndocrine:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_endocrine_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_endocrine_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_endocrine_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_endocrine_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_endocrine_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_endocrine_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        ////////////////////////////////////////////////////////////////////

        medicalRespiratoryDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.status === true
            ? "Yes"
            : "No", //5. Heart and Lung disorders?

        medicalRespiratory:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        respiratory_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        respiratory_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        respiratory_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        respiratory_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        respiratory_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        respiratory_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        spouse_medicalRespiratory:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_respiratory_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_respiratory_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_respiratory_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_respiratory_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_respiratory_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_respiratory_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child1_medicalRespiratory:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_respiratory_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_respiratory_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_respiratory_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_respiratory_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_respiratory_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_respiratory_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalRespiratory:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_respiratory_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_respiratory_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_respiratory_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_respiratory_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_respiratory_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_respiratory_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalRespiratory:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_respiratory_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_respiratory_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_respiratory_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_respiratory_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_respiratory_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_respiratory_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalRespiratory:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_respiratory_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_respiratory_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_respiratory_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_respiratory_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_respiratory_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_respiratory_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        //////////////////////////////////////////////////////

        medicalKidneyDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.status === true
            ? "Yes"
            : "No", //6. Digestive system disorders (Stomach and related organs)?

        medicalKidney:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        kidney_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        kidney_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        kidney_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        kidney_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        kidney_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        kidney_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        spouse_medicalKidney:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_kidney_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_kidney_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_kidney_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_kidney_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_kidney_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_kidney_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child1_medicalKidney:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_kidney_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_kidney_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_kidney_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_kidney_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_kidney_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_kidney_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalKidney:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_kidney_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_kidney_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_kidney_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_kidney_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_kidney_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_kidney_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalKidney:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_kidney_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_kidney_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_kidney_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_kidney_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_kidney_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_kidney_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalKidney:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_kidney_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_kidney_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_kidney_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_kidney_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_kidney_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_kidney_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        ///////////////////////////////////////////////////////////

        medicalParalysisDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.status === true
            ? "Yes"
            : "No", //7. Brain nerve and Psychiatric (Mental) disorders?

        medicalParalysis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        paralysis_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        paralysis_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        paralysis_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        paralysis_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        paralysis_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        paralysis_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        spouse_medicalParalysis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_paralysis_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_paralysis_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_paralysis_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_paralysis_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_paralysis_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_paralysis_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child1_medicalParalysis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_paralysis_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_paralysis_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_paralysis_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_paralysis_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_paralysis_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_paralysis_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalParalysis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_paralysis_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_paralysis_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_paralysis_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_paralysis_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_paralysis_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_paralysis_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalParalysis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_paralysis_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_paralysis_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_paralysis_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_paralysis_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_paralysis_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_paralysis_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalParalysis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_paralysis_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_paralysis_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_paralysis_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_paralysis_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_paralysis_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_paralysis_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        //////////////////////////////

        medicalCongenitalDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.status === true
            ? "Yes"
            : "No", // 8. Other Endocrine (Hormonal) disorders?

        // Adult One
        medicalCongenital:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        congenital_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        congenital_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        congenital_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        congenital_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        congenital_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        congenital_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Adult Two
        spouse_medicalCongenital:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_congenital_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_congenital_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_congenital_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_congenital_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_congenital_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_congenital_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Children
        child1_medicalCongenital:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_congenital_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_congenital_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_congenital_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_congenital_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_congenital_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_congenital_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalCongenital:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_congenital_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_congenital_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_congenital_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_congenital_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_congenital_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_congenital_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalCongenital:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_congenital_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_congenital_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_congenital_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_congenital_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_congenital_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_congenital_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalCongenital:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_congenital_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_congenital_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_congenital_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_congenital_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_congenital_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_congenital_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        //////////////////////////////////////////////

        medicalJointDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.status === true
            ? "Yes"
            : "No", //9. Bone, joints and muscle disorders?

        // Adult One
        medicalJoint:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        joint_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        joint_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        joint_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        joint_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        joint_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        joint_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Adult Two
        spouse_medicalJoint:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_joint_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_joint_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_joint_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_joint_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_joint_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_joint_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Children
        child1_medicalJoint:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_joint_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_joint_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_joint_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_joint_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_joint_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_joint_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalJoint:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_joint_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_joint_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_joint_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_joint_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_joint_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_joint_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalJoint:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_joint_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_joint_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_joint_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_joint_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_joint_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_joint_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalJoint:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_joint_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_joint_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_joint_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_joint_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_joint_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_joint_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        //////////////////////////////////////////////////////////

        medicalProlongedDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.status ===
            true
            ? "Yes"
            : "No", // 10. Ear, nose, eye and throat disorders?

        // Adult One
        medicalProlonged:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        prolonged_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        prolonged_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        prolonged_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        prolonged_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        prolonged_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        prolonged_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Adult Two
        spouse_medicalProlonged:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_prolonged_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_prolonged_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_prolonged_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_prolonged_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_prolonged_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_prolonged_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Children
        child1_medicalProlonged:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_prolonged_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_prolonged_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_prolonged_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_prolonged_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_prolonged_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_prolonged_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalProlonged:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_prolonged_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_prolonged_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_prolonged_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_prolonged_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_prolonged_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_prolonged_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalProlonged:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_prolonged_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_prolonged_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_prolonged_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_prolonged_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_prolonged_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_prolonged_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalProlonged:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_prolonged_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_prolonged_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_prolonged_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_prolonged_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_prolonged_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_prolonged_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        ////////////////////////////////////

        medicalOtherDiseasesDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.status ===
            true
            ? "Yes"
            : "No", // 11. Genito-urinary and Gynaecological disorders?

        // Adult One
        medicalOtherDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        other_diseases_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        other_diseases_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        other_diseases_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        other_diseases_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        other_diseases_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        other_diseases_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Adult Two
        spouse_medicalOtherDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_other_diseases_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_other_diseases_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_other_diseases_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_other_diseases_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_other_diseases_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_other_diseases_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Children
        child1_medicalOtherDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_other_diseases_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_other_diseases_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_other_diseases_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_other_diseases_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_other_diseases_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_other_diseases_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalOtherDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_other_diseases_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_other_diseases_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_other_diseases_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_other_diseases_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_other_diseases_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_other_diseases_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalOtherDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_other_diseases_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_other_diseases_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_other_diseases_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_other_diseases_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_other_diseases_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_other_diseases_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalOtherDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_other_diseases_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_other_diseases_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_other_diseases_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_other_diseases_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_other_diseases_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_other_diseases_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        ////////////////////////////////////

        medicalCancelDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.status ===
            true
            ? "Yes"
            : "No", // 12. Blood and related disorders?

        // Adult One
        medicalCancel:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        cancel_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        cancel_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        cancel_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        cancel_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        cancel_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        cancel_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Adult Two
        spouse_medicalCancel:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_cancel_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_cancel_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_cancel_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_cancel_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_cancel_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_cancel_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Children
        child1_medicalCancel:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_cancel_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_cancel_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_cancel_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_cancel_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_cancel_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_cancel_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalCancel:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_cancel_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_cancel_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_cancel_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_cancel_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_cancel_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_cancel_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalCancel:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_cancel_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_cancel_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_cancel_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_cancel_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_cancel_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_cancel_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalCancel:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_cancel_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_cancel_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_cancel_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_cancel_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_cancel_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_cancel_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        /////////////////////////////

        medicalOtherPolicyDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.status ===
            true
            ? "Yes"
            : "No", // 13. Skin disorders?

        // Adult One
        medicalOtherPolicy:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        other_policy_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        other_policy_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        other_policy_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        other_policy_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        other_policy_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        other_policy_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Adult Two
        spouse_medicalOtherPolicy:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_other_policy_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_other_policy_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_other_policy_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_other_policy_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_other_policy_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_other_policy_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Children
        child1_medicalOtherPolicy:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_other_policy_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_other_policy_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_other_policy_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_other_policy_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_other_policy_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_other_policy_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        child2_medicalOtherPolicy:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_other_policy_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_other_policy_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_other_policy_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_other_policy_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_other_policy_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_other_policy_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        child3_medicalOtherPolicy:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_other_policy_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_other_policy_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_other_policy_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_other_policy_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_other_policy_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_other_policy_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        child4_medicalOtherPolicy:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_other_policy_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_other_policy_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_other_policy_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_other_policy_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_other_policy_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_other_policy_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        ////////////////////////////////////

        medicalHIVDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.status ===
            true
            ? "Yes"
            : "No", // 14.Any other condition / illness / disorder / surgery?

        // Adult One
        medicalHIV:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        hiv_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        hiv_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        hiv_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        hiv_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        hiv_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        hiv_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Adult Two
        spouse_medicalHIV:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        spouse_hiv_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        spouse_hiv_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        spouse_hiv_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        spouse_hiv_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        spouse_hiv_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        spouse_hiv_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Children
        child1_medicalHIV:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child1_hiv_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value,
        child1_hiv_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value,
        child1_hiv_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F3"].value?.value,
        child1_hiv_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F4"].value?.value,
        child1_hiv_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F5"].value?.value,
        child1_hiv_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_one.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Child Two
        child2_medicalHIV:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child2_hiv_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F1"].value?.value,
        child2_hiv_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F2"].value?.value,
        child2_hiv_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F3"].value?.value,
        child2_hiv_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F4"].value?.value,
        child2_hiv_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F5"].value?.value,
        child2_hiv_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_two.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Child Three
        child3_medicalHIV:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child3_hiv_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value,
        child3_hiv_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value,
        child3_hiv_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F3"].value?.value,
        child3_hiv_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F4"].value?.value,
        child3_hiv_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F5"].value?.value,
        child3_hiv_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_three.field_data?.["MQ1-SQ1-F6"].value?.value,

        // Child Four
        child4_medicalHIV:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        child4_hiv_treatment:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value,
        child4_hiv_current_status:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value,
        child4_hiv_complication:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F3"].value?.value,
        child4_hiv_histopathology:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F4"].value?.value,
        child4_hiv_diagnosis:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F5"].value?.value,
        child4_hiv_last_consultation_date:
          data?.MQ02?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F6"].value?.value,

        /////////////////////////////////////

        medicalClaimDiseases:
          data.MQ03.main_question.status === true ? "Yes" : "NO", //(Pre-Existing Diseases) Has any of the applicant recommended to undergo or has undergone any pathologic or radiologic tests for any illness other than the ones listed above and routine or annual health check-up?
        medicalClaim:
          data.MQ03.main_question.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        spouse_medicalClaim:
          data.MQ03.main_question.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child1_medicalClaim:
          data.MQ03.main_question.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        child2_medicalClaim:
          data.MQ03.main_question.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child3_medicalClaim:
          data.MQ03.main_question.member_data?.child_three.selection_status ===
            true
            ? "Yes"
            : "NO",
        child4_medicalClaim:
          data.MQ03.main_question.member_data?.child_four.selection_status ===
            true
            ? "Yes"
            : "NO",
        //////////////////////////////////////////////////////////////////////////////
        medicalMedicationDiseases:
          data.MQ04.main_question.status === true ? "Yes" : "NO", // Good Health(Are you currently in good health and not undergoing any treatment or medication for any illness/medical condition (Physical, Psychiatric, Mental illness /disorders, Sleep disorders)?)
        medicalMedication:
          data.MQ04.main_question.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        spouse_medicalMedication:
          data.MQ04.main_question.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child1_medicalMedication:
          data.MQ04.main_question.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        child2_medicalMedication:
          data.MQ03.main_question.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child3_medicalMedication:
          data.MQ04.main_question.member_data?.child_three.selection_status ===
            true
            ? "Yes"
            : "NO",
        child4_medicalMedication:
          data.MQ04.main_question.member_data?.child_four.selection_status ===
            true
            ? "Yes"
            : "NO",

        ///////////////////////////////////////////////////////////////////

        personalHabitDetailsDiseases:
          data.MQ05.main_question.status === true ? "Yes" : "NO", //Intoxication Declaration(Select "Yes" if any insured member Smokes, consumes Tobacco in any form, or drinks Alcohol?)
        medicalAlcoholDiseases:
          data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.status === true
            ? "Yes"
            : "NO", //Does any of the members consume Alcohol?
        medicalAlcohol:
          data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalAlcohol:
          data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalAlcohol:
          data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalAlcohol:
          data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalAlcohol:
          data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalAlcohol:
          data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        alcohol_year: `${data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        spouse_alcohol_year: `${data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child1_alcohol_year: `${data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child2_alcohol_year: `${data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child3_alcohol_year: `${data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child4_alcohol_year: `${data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value}`,

        medicalSmokingDiseases:
          data?.MQ05?.main_question?.sub_question?.["MQ2-SQ2"]?.status === true // Does any of the members consume smoking?
            ? "Yes"
            : "NO",
        medicalSmoking:
          data?.MQ05?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "NO",
        spouse_medicalSmoking:
          data?.MQ05?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "NO",
        child1_medicalSmoking:
          data?.MQ05?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "NO",
        child2_medicalSmoking:
          data?.MQ05?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "NO",
        child3_medicalSmoking:
          data?.MQ05?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "NO",
        child4_medicalSmoking:
          data?.MQ05?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "NO",

        smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,

        spouse_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,

        child1_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,

        child2_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,

        child3_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value}`,

        child4_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value}`,

        medicalTobaccoDiseases:
          data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.status === true //Does any of the members consume tobacco?
            ? "Yes"
            : "NO",
        medicalTobacco:
          data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "NO",
        spouse_medicalTobacco:
          data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "NO",
        child1_medicalTobacco:
          data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "NO",
        child2_medicalTobacco:
          data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "NO",
        child3_medicalTobacco:
          data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "NO",
        child4_medicalTobacco:
          data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "NO",

        tobacco_year: `${data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        spouse_tobacco_year: `${data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child1_tobacco_year: `${data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child2_tobacco_year: `${data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child3_tobacco_year: `${data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child4_tobacco_year: `${data?.MQ05?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value}`,

        criticalMedicalDetails:
          data.MQ06.main_question.status === true ? "Yes" : "NO", // critical illness when yes(Critical Illness :Have any first degree relatives (i.e. parents, brothers, sisters or children) of any of the applicants (who are not themselves applicants for this insurance policy) had cancer, motor neurone disease or any other hereditary disorders?)
        paOneMedicalDetails:
          data.MQ06.main_question.status === true ? "Yes" : "NO", // when personal accident addons yes()
        paTwoMedicalDetails:
          data.MQ06.main_question.status === true ? "Yes" : "NO", // personal accident yes addons yes()


      };

      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");
      
      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    }
  };

  let data: TCignaMedicalQuestion = JSON.parse(
    JSON.stringify(medicalQuestionData)
  );

  return (
    <>
      {isMobile ?
        <MMedicalDetail
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
        : (
          <MedicalDetail
            medicalQuestionData={medicalQuestionData}
            updateMasterState={updateMasterState}
            validateForm={validateForm}
            PAGE_STATUS={PAGE_STATUS}
          />
        )}
    </>
  );
};

export default MedicalDetailContainer;
