import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { ETermCompanyCode } from "../../../../../Enum/ETermCompanyCode";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  formatNumberToLakhOrCrores,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";
import "./MPaymentConfirmation.scss";

function MPaymentConfirmation({
  continue_function,
}: {
  continue_function: Function;
}) {
  const { proposerDetails } = useAppSelector((state) => state.TermFullJourney);
  const { SELECTED_QUOTE_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Term
  );
  return (
    <Box className="paymentWrapperMobile ">
      <Grid container className="topbar">
        <Grid xs={12} spacing={3} alignSelf="center">
          <img src="./images/motilalOswal.svg" className="logo" alt="" />
        </Grid>
      </Grid>
      <Grid container className="innerWrapper">
        <Grid xs={12} spacing={3} className="mb-6 imgsec">
          <img src="./images/policy-confirmation.svg" alt="" />
        </Grid>
        <Grid xs={12} textAlign="center">
          <h3>Payment Confirmation</h3>
          <p className=" mb-6">
            <span>GREAT !!</span> Congratulations on buying your investment.
          </p>
        </Grid>
        <Grid xs={12} textAlign="center">
          <p className="mb-0 paymentDetail_p">
            Hi, {`${proposerDetails.full_name.value}`}
          </p>
          <p className="paymentDetail_p mb-6">
            {`${proposerDetails.gender.value}` === "M" ? "Male" : "Female"}-{" "}
            {ADD_FORM.age.value} Years
          </p>
        </Grid>
        <Grid xs={12}>
          <div className="policy_detail mb-6">
            <div className="policy_detail_topbar">
              <Grid container padding="12px">
                <Grid xs={6}>
                  <p className="mb-0 detail_p" style={{ textAlign: "left" }}>
                    {SELECTED_QUOTE_DATA.productDetails.product_name}
                  </p>
                </Grid>
                <Grid xs={6}>
                  <p className="mb-0 detail_p" style={{ textAlign: "right" }}>
                    Transaction ID :-
                    {!isEmpty(SELECTED_QUOTE_DATA.transaction_id)
                      ? SELECTED_QUOTE_DATA.transaction_id
                      : SELECTED_QUOTE_DATA.appno}
                  </p>
                </Grid>
              </Grid>
            </div>
            <Grid container padding="12px">
              <Grid xs={6}>
                <p className="mb-0 detail_p" style={{ textAlign: "left" }}>
                  <img
                    src={SELECTED_QUOTE_DATA.companyDetails.logo}
                    alt=""
                    style={{ width: "70px" }}
                  />
                </p>
              </Grid>
              <Grid xs={6}>
                <p className="mb-0 detail_p" style={{ textAlign: "right" }}>
                  Sum Assred{" "}
                  <span>
                    {formatNumberToLakhOrCrores(
                      Number(SELECTED_QUOTE_DATA?.premiumDetails?.sum_assured)
                    )}
                  </span>{" "}
                </p>
              </Grid>
              <Grid xs={6}>
                <p className="mb-0 detail_p" style={{ textAlign: "right" }}>
                  Application No.<span>{SELECTED_QUOTE_DATA.appno}</span>{" "}
                </p>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid xs={12} className="mb-6">
          <ul className="steps">
            <li className="activeLi">
              <span className="activebg">1</span>Basic Details
            </li>
            <li className="activeLi">
              <span className="activebg">2</span>Payment Done
            </li>
            <li>
              <span>3</span>Nominee Details
            </li>
            <li>
              <span>4</span>Address Details
            </li>
            <li>
              <span>5</span>CKYC Details
            </li>
            <li>
              <span>6</span>Underwriting Details
            </li>
            <li>
              <span>7</span>Preview Details
            </li>
            {SELECTED_QUOTE_DATA.companyDetails.company_code ===
            ETermCompanyCode.HDFC ? (
              <li>
                <span>8</span>Upload Document
              </li>
            ) : null}
          </ul>
        </Grid>
        <Grid xs={12} textAlign="center">
          <div className="footer ctaBtn">
            <Button
              className="regularSecondaryBtn"
              onClick={() => {
                continue_function();
              }}>
              Continue
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MPaymentConfirmation;
