import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RidersBenefits from "../../../Page/Desktop/Term/ShortPay/HDFC/RidersBenefits";
import MRidersBenefits from "../../../Page/Mobile/Term/HDFC/MRidersBenefits";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { formatAmountToCurrency } from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { DTOTermRiderStatusRequest } from "../../../types/Term/DTOTermRidersStatus";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";

function RidersBenefitsContainer() {
  const isMobile = useIsMobile();

  const dispatch = useAppDispatch();
  const [tf001Data, setTf001Data] = useState<TDropdown[]>([]);
  const [tf002Data, setTf002Data] = useState<TDropdown[]>([]);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [tf004Data, setTf004Data] = useState<TDropdown[]>([]);
  const [tf005Data, setTf005Data] = useState<TDropdown[]>([]);

  const { SELECTED_QUOTE_DATA, RIDERS_BENEFITS, ADD_FORM_RESPONSE, LOADER } =
    useAppSelector((state) => state.Term);
  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);
  const [loaderTf004, setLoaderTf004] = useState<boolean>(false);
  const [loaderTf005, setLoaderTf005] = useState<boolean>(false);

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, [RIDERS_BENEFITS.local_value]);

  const GET_RIDERS_PREMIUM = () => {
    const onSuccess = (data: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));

      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      setLoaderTf005(false);
      const error = data.response?.premiumDetails?.error;
      const message = data.message;
      const response: TRidersBenefitsResponse = data.response;

      if (!error) {
        dispatch(TermSlice.actions.SET_RIDERS_BENEFITS_DATA(response));
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      setLoaderTf004(false);
      setLoaderTf005(false);
      // toast.error("There are some technical error. Please contact to admin.");
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
    };

    const data: DTOTermRiderStatusRequest = {
      // name: PROPOSER_DATA.full_name.value,
      // mobile: ADD_FORM.mobile.value,
      // pincode: ADD_FORM.pincode.value,
      // product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      // quote_no: ADD_FORM_RESPONSE.quote_no,
      // company_code: SELECTED_QUOTE_DATA.companyDetails.company_code,
      // frequency: `${QUOTE_FIELDS_DATA.payMode}`,
      // gender: ADD_FORM.gender.value,
      // pay_term: `${SELECTED_QUOTE_DATA.premiumDetails.pay_term}`,
      // product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      // smoker: ADD_FORM.smoke,
      // term: `${SELECTED_QUOTE_DATA.premiumDetails.term}`,
      // dob: PROPOSER_DATA.dob.value,
      // criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
      //   ? "Yes"
      //   : "No",
      // accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
      //   ? "Yes"
      //   : "No",
      // personalAccSelected:
      //   SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
      //     ? "No"
      //     : RIDERS_BENEFITS.local_value.tf003Status
      //     ? "Yes"
      //     : "No",
      // cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      // accidentalDeathSelected:
      //   SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
      //     ? "No"
      //     : RIDERS_BENEFITS.local_value.tf005Status
      //     ? "Yes"
      //     : "No",
      // criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
      //   ? RIDERS_BENEFITS.local_value.tf001Value
      //   : RIDERS_BENEFITS.local_value.tf004Value,
      // accidentalDisValue: RIDERS_BENEFITS.local_value.tf002Value,
      // accidentalDeathValue:
      //   SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
      //     ? ""
      //     : RIDERS_BENEFITS.local_value.tf003Status
      //     ? RIDERS_BENEFITS.local_value.tf003Value
      //     : RIDERS_BENEFITS.local_value.tf005Value,
      // adb: RIDERS_BENEFITS.local_value.BNF001Status ? "Yes" : "No",
      // rop: RIDERS_BENEFITS.local_value.BNF002Status ? "Yes" : "No",
      // wop: RIDERS_BENEFITS.local_value.BNF003Status ? "Yes" : "No",
      // sum_assured: `${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`,
      product_ids: [`${SELECTED_QUOTE_DATA.productDetails.id}`],
      criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDeathSelected:
        SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf005Status
          ? "Yes"
          : "No",
      cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
      accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
        ? RIDERS_BENEFITS.local_value.tf001Value
        : RIDERS_BENEFITS.local_value.tf004Value,
      accidentalDisValue: RIDERS_BENEFITS.local_value.tf002Value,
      accidentalDeathValue:
        SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
          ? ""
          : RIDERS_BENEFITS.local_value.tf003Status
          ? RIDERS_BENEFITS.local_value.tf003Value
          : RIDERS_BENEFITS.local_value.tf005Value,
      personalAccSelected:
        SELECTED_QUOTE_DATA.productDetails.product_code === "LTH101"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf003Status
          ? "Yes"
          : "No",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      adb: RIDERS_BENEFITS.local_value.BNF001Status ? "Yes" : "No",
      rop: RIDERS_BENEFITS.local_value.BNF002Status ? "Yes" : "No",
      wop: RIDERS_BENEFITS.local_value.BNF003Status ? "Yes" : "No",
    };
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));

    TERM_SERVICES.UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  useEffect(() => {
    const start_tf001 = 10000;
    const end_tf001 = 15000000;

    let data_tf001: TDropdown[] = [];
    for (let i = start_tf001; i <= end_tf001; i += 5000) {
      data_tf001.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf001Data(data_tf001);

    const start_tf002 = 100000;
    const end_tf002 = 20000000;

    let data_tf002: TDropdown[] = [];
    for (let i = start_tf002; i <= end_tf002; i += 100000) {
      data_tf002.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf002Data(data_tf002);

    const start_tf003 = 10000;
    const end_tf003 = 5000000;

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 5000) {
      data_tf003.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf003Data(data_tf003);

    const start_tf004 = 10000;
    const end_tf004 = 5000000;

    let data_tf004: TDropdown[] = [];
    for (let i = start_tf004; i <= end_tf004; i += 5000) {
      data_tf004.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf004Data(data_tf004);

    const start_tf005 = 10000;
    const end_tf005 = 10000000;

    let data_tf005: TDropdown[] = [];
    for (let i = start_tf005; i <= end_tf005; i += 5000) {
      data_tf005.push({ label: formatAmountToCurrency(i), value: `${i}` });
    }

    setTf005Data(data_tf005);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    setLoaderTf001(true);
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf001Value,
  ]);

  useEffect(() => {
    setLoaderTf002(true);
  }, [
    RIDERS_BENEFITS.local_value.tf002Status,
    RIDERS_BENEFITS.local_value.tf002Value,
  ]);

  useEffect(() => {
    setLoaderTf003(true);
  }, [
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf003Value,
  ]);

  useEffect(() => {
    setLoaderTf004(true);
  }, [
    RIDERS_BENEFITS.local_value.tf004Status,
    RIDERS_BENEFITS.local_value.tf004Value,
  ]);

  useEffect(() => {
    setLoaderTf005(true);
  }, [
    RIDERS_BENEFITS.local_value.tf005Status,
    RIDERS_BENEFITS.local_value.tf005Value,
  ]);

  return (
    <>
      {LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MRidersBenefits
          loaderTf001={loaderTf001}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          loaderTf005={loaderTf005}
          tf001Data={tf001Data}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          tf005Data={tf005Data}
          loader={LOADER}
        />
      ) : (
        <RidersBenefits
          loaderTf001={loaderTf001}
          loaderTf002={loaderTf002}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          loaderTf005={loaderTf005}
          tf001Data={tf001Data}
          tf002Data={tf002Data}
          tf003Data={tf003Data}
          tf004Data={tf004Data}
          tf005Data={tf005Data}
        />
      )}
    </>
  );
}

export default RidersBenefitsContainer;
