import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import MedicalDetailsRedCarpet from "../../../../Page/Desktop/Health/STAR/MedicalDetailsRedCarpet";
import { TStarMedicalQuestion } from "../../../../types/Health/HealthQuotation/TStarMedicalQuestion";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { useNavigate } from "react-router-dom";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import MMedicalDetailsRedCarpet from "../../../../Page/Mobile/Health/STAR/MMedicalDetailsRedCarpet";
import { toast } from "react-toastify";

const MedicalDetailsContainerRedCarpet = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_STAR_RED_CARPET,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
  } = useAppSelector((state: any) => state.Health);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TStarMedicalQuestion>(MEDICAL_QUESTION_DATA_STAR_RED_CARPET);
  const [pastHealthInsurance, setPastHealthInsurance] = useState("");

  useEffect(() => {
    if (pastHealthInsurance !== "") {
      dispatch(
        HealthSlice.actions.UPDATE_PAST_HEALTH_INSURANCE_VALUE(
          pastHealthInsurance
        )
      );
    }
  }, [pastHealthInsurance]);
  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_STAR_RED_CARPET };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Social Status",
          description:
            "Social Status (Please choose ‘Yes’ in case of BPL families, Disabled Persons etc, Persons working in UnOrganized/Informal Sectors)?",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title:
                "Please choose ‘Yes’, if you are working in UnOrganized Sector",
              description: "",
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title:
                "Please choose ‘Yes’, if you live below the poverty line (BPL)",
              description: "",
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title:
                "Please choose ‘Yes’, if you are Physically Handicaped/Disabled",
              description: "",
            },
            "MQ4-SQ4": {
              id: "MQ4-SQ4",
              icon_class: "",
              status: false,
              title:
                "Please choose ‘Yes’, if you are working in Informal Sector",
              description: "",
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "Other Health Insurance Policy",
          description:
            "Do you have any other health insurance covering the persons proposed in the past or current ?",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Past Health Insurance",
              description: "",
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "Health problems",
          description: "Do you have any health problems ?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Hypertension",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Diabetes",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Any other Diseases",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Diseases Name",
                  field_type: "DISEASES_TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Hypertension",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Diabetes",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F3": {
                  id: "MQ1-SQ1-F3",
                  title: "Any other Diseases",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F4": {
                  id: "MQ1-SQ1-F4",
                  title: "Diseases Name",
                  field_type: "DISEASES_TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            },
          },
        },
      },

      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "Pre-Existing Diseases",
          description:
            "Have you or any member of your family proposed to be insured, suffered or are suffering from any disease/ailment/adverse medical condition of any kind especially Heart/Stroke/Cancer/Real Disorder/Alzheimer's disease/Parkinson's disease?",
          status: false,
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_STAR_RED_CARPET);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_STAR_RED_CARPET, PAGE_STATUS]);
  let flag = false;

  const updateMasterState = (attrName: any, value: any) => {
    flag = value;
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName === "past_health_insurance") {
      dispatch(
        HealthSlice.actions.UPDATE_PAST_HEALTH_INSURANCE_VALUE(
          pastHealthInsurance
        )
      );
      setPastHealthInsurance(value);
    }

    if (attrName[0] === "question") {
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      if (value === false && attrName[1] === "MQ03") {
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data &&
          Object.values(
            manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data
          ).forEach((member: any) => {
            member.selection_status = false;
            member.field_data["MQ1-SQ1-F1"] = {
              id: "MQ1-SQ1-F1",
              title: "Hypertension",
              field_type: "CHECKBOX",
              isChecked: false,
            };
            member.field_data["MQ1-SQ1-F2"] = {
              id: "MQ1-SQ1-F2",
              title: "Diabetes",
              field_type: "CHECKBOX",
              isChecked: false,
            };
            member.field_data["MQ1-SQ1-F3"] = {
              id: "MQ1-SQ1-F3",
              title: "Any other Diseases",
              field_type: "CHECKBOX",
              isChecked: false,
            };
          });
      } else if (value === false && attrName[1] === "MQ04") {
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data &&
          Object.values(
            manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data
          ).forEach((member: any) => {
            member.selection_status = false;
            member.field_data["MQ1-SQ1-F1"] = {
              id: "MQ1-SQ1-F1",
              title: "Engage in manual labour",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            };
          });
      } else if (value === false && attrName[1] === "MQ05") {
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data &&
          Object.values(
            manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data
          ).forEach((member: any) => {
            member.selection_status = false;
            member.field_data["MQ1-SQ1-F1"] = {
              id: "MQ1-SQ1-F1",
              title: "Engage in Winter Sports",
              field_type: "TEXTFIELD",
              value: { value: "", warning: false },
            };
          });
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
          ],
          status: value,
        },
      };
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };
      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };
      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "memberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };
        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
        attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField") {
      if (
        attrName[4] === "adult_one" ||
        attrName[4] === "adult_two" ||
        attrName[4] === "child_one" ||
        attrName[4] === "child_two" ||
        attrName[4] === "child_three" ||
        (attrName[4] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
        ].member_data[attrName[4]].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      } else {
        if (
          attrName[4] === "adult_one" ||
          attrName[2] === "adult_two" ||
          attrName[2] === "child_one" ||
          attrName[2] === "child_two" ||
          attrName[2] === "child_three" ||
          (attrName[2] === "child_four" &&
            manipulatedMedicalQuestion !== undefined)
        ) {
        }
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
          attrName[2]
        ].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    }
  };

  const validateForm = () => {
    if (!medicalQuestionData) {
      return;
    }
    let data: TStarMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status) {
        const memberData = mainQuestion.member_data;
        if (memberData) {
          Object.values(memberData).forEach((member) => {
            if (member.selection_status && member.field_data) {
              Object.values(member.field_data).forEach((field) => {
                if (field.id === "MQ1-SQ1-F4") {
                  if (
                    member?.field_data &&
                    member?.field_data["MQ1-SQ1-F3"].value?.value === true &&
                    field.value
                  ) {
                    field.value = {
                      ...field.value,
                      warning:
                        member?.field_data["MQ1-SQ1-F3"]?.value?.value === true
                          ? isEmpty(field?.value?.value)
                          : false,
                    };
                    if (isEmpty(field?.value?.value)) {
                      hasError = true;
                    }
                  }
                } else {
                  if (field.value?.value === "") {
                    field.value = {
                      ...field.value,
                      warning: true,
                    };
                    hasError = true;
                  }
                }
              });
            }
          });
        }
        if(mainQuestion.id === "MQ01") {
          const subQuesNotSelected =  Object.values(JSON.parse(JSON.stringify(mainQuestion.sub_question))).find((item: any) => item.status)
          if(subQuesNotSelected && Object.values(subQuesNotSelected).length) {
            hasError = false;
          } else {
            hasError = true;
            toast.error("Please Select Sub Questions!")
          }
        }
        if(mainQuestion.id === "MQ03") {
          if(mainQuestion.status) {
          const memberNotSelected: any =  Object.values(JSON.parse(JSON.stringify(mainQuestion.member_data))).filter((item: any) => item.selection_status)
          if(memberNotSelected && Object.values(memberNotSelected).length) {
            const diseaseSelected = memberNotSelected.filter((item: any) => item.selection_status ? Object.values(item.field_data).every((obj:any) => !obj.value.value) : {}
          ) || null;
            if(diseaseSelected.length) {
              hasError = true;
              toast.error("Please Select Member Data!")
            }
            else hasError = false;
          } else {
            hasError = true;
            toast.error("Please Select Member!")
          }
        }
      }
      }
    });

    setMedicalQuestionData(data);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_STAR_RED_CARPET(data));
      const onSuccess = (res: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
      const param = {
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        quote_no: ADD_FORM_RESPONSE.quote_no,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medical_info: "Yes",
        // Ques 1
        medicalHospitalized:
          data.MQ01.main_question.status === true ? "Yes" : "No", //Social Status (Please choose "Yes" in case of BPL families, Disabled Persons etc, Persons working in UnOrganized/Informal Sectors)?
        medicalMedicines:
          data.MQ01.main_question.sub_question?.["MQ1-SQ1"]?.status === true
            ? "Yes"
            : "No", //Please choose "Yes", if you are working in UnOrganized Sector
        medicalProblem:
          data.MQ01.main_question.sub_question?.["MQ2-SQ2"]?.status === true
            ? "Yes"
            : "No", //Please choose "Yes", if you live below the poverty line (BPL)
        medicalHospitalization:
          data.MQ01.main_question.sub_question?.["MQ3-SQ3"]?.status === true
            ? "Yes"
            : "No", //Please choose "Yes", if you are Physically Handicaped/Disabled
        medicalAlcohol:
          data.MQ01.main_question.sub_question?.["MQ4-SQ4"]?.status === true
            ? "Yes"
            : "No", //Please choose "Yes", if you are working in Informal Sector

        // Ques 2
        medicalOtherPolicy: `${pastHealthInsurance}`, //Other Health Insurance Policy(Do you have any other health insurance covering the persons proposed in the past or current?)

        // Ques 3
        alcoholQuantityDiseases:
          data.MQ03.main_question.status === true ? "Yes" : "No", //Do you have any health problems ?
        alcoholQuantityAdult1:
          data?.MQ03?.main_question.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "No",
        cMedicalHypertension:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value === true
            ? "Yes"
            : "No", //Hypertension
        cMedicalDiabetes:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value === true
            ? "Yes"
            : "No", //Diabetes
        cMedicalProblem:
          data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
            "MQ1-SQ1-F3"
          ]?.value?.value === true
            ? "Yes"
            : "No", //Any other Diseases
        alcoholQuantity: `${data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value}`, // Any other Diseases

        alcoholQuantityAdult2:
          data?.MQ03?.main_question.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "No",
        spouse_cMedicalHypertension:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F1"
          ]?.value?.value === true
            ? "Yes"
            : "No", //Hypertension
        spouse_cMedicalDiabetes:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F2"
          ]?.value?.value === true
            ? "Yes"
            : "No", //Diabetes
        spouse_cMedicalProblem:
          data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
            "MQ1-SQ1-F3"
          ]?.value?.value === true
            ? "Yes"
            : "No", //Any other Diseases
        spouse_alcoholQuantity: `${data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value}`, // Any other Diseases

        // Ques 4

        medicalSmoking: data.MQ06.main_question.status === true ? "Yes" : "No", //Pre-Existing Diseases(Have you or any member of your family proposed to be insured, suffered or are suffering from any disease/ailment/adverse medical condition of any kind especially Heart/Stroke/Cancer/Real Disorder/Alzheimer's disease/Parkinson's disease?),
        react_medical: data,
      };
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");
      
      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    }
  };
  return (
    <>
      {isMobile ? <MMedicalDetailsRedCarpet
        medicalQuestionData={medicalQuestionData}
        updateMasterState={updateMasterState}
        validateForm={validateForm}
        PAGE_STATUS={PAGE_STATUS}
      /> : (
        <MedicalDetailsRedCarpet
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalDetailsContainerRedCarpet;
