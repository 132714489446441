import { Routes, Route } from "react-router-dom";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import { useAppSelector } from "../../../../../Store/hooks";
import { EProductId } from "../../../../../Services/Enum/EHome";
import CignaCKYCContainer from "../../../../../Container/CKYC/Cigna/CignaCKYCContainer";
import CignaManualKYCContainer from "../../../../../Container/CKYC/Cigna/CignaManualKycContainer";
import AddonDetailsContainerPrimeAdvantage from "../../../../../Container/Health/CIGNA/AddonDetailsContainerPrimeAdvantage";
import AddonDetailsContainerPrimeActive from "../../../../../Container/Health/CIGNA/AddonDetailsContainerPrimeActive";
import MedicalDetailContainer from "../../../../../Container/Health/CIGNA/MedicalDetailContainer";
import AddonDetailsContainerPrimeProtect from "../../../../../Container/Health/CIGNA/AddonDetailsContainerPrimeProtect";
import AddonDetailsContainerLifeTime from "../../../../../Container/Health/CIGNA/AddonDetailsContainerLifeTime";
import AddonDetailsContainerLifeTimeGlobal from "../../../../../Container/Health/CIGNA/AddonDetailsContainerLifeTimeGlobal";
import CignaBankDetailsContainer from "../../../../../Container/BankDetails/Cigna/CignaBankDetailsContainer";

const CIGNA_ROUTES = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);

  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<CignaCKYCContainer />} />
        <Route
          path={CKYC_ROUTES.MANUAL_CKYC}
          element={<CignaManualKYCContainer />}
        />
        {SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.CIGNA_PRIME_ADVANTAGE ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerPrimeAdvantage />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.CIGNA_PRIME_ACTIVE ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerPrimeActive />}
          />
        ) : (
          <></>
        )}

        {SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.CIGNA_PRIME_PROTECT ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerPrimeProtect />}
          />
        ) : (
          <></>
        )}

        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.CIGNA_LIFETIME_INDIA && (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerLifeTime />}
          />
        )}

        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.CIGNA_LIFETIME_GLOBAL && (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerLifeTimeGlobal />}
          />
        )}

        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailContainer />}
        />

        <Route
          path={HEALTH_ROUTE.BANK_DETAILS}
          element={<CignaBankDetailsContainer />}
        />
      </Route>
    </Routes>
  );
};

export default CIGNA_ROUTES;
