import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
// import { TRSAAddon } from "../../../../Type/Health/HealthQuotation/TAddonRSA";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
// import { THealthQuote } from "../../../../Type/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import AddonDetailsRSA from "../../../../Page/Desktop/Health/RSA/AddonDetails/AddonDetailsRSA";
import { CKYCServcies } from "../../../../Services/CKYC/CKYCServices";
import AddonDetailsADITYABIRLA from "../../../../Page/Desktop/Health/ADITYA_BIRLA/AddonDetails/AddonDetailsADITYABIRLA";
import { TADITYABIRLAAddon } from "../../../../Type/Health/ProposalDeatail/TADITYABIRLAAddon/TADITYABIRLAAddon";
import { CKYCSlice } from "../../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import MAddonDetailsADITYABIRLA from "../../../../Page/Mobile/Health/ADITYA_BIRLA/MAddonDetails/MAddonDetailsADITYABIRLA";

const AddonDetailsContainer = () => {
  const isMobile = useIsMobile();

  const { Health } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    QUOTE_LOADER,
    ADDON_DETAILS_ADITYA_BIRLA,
    REDIRECTED,
  } = useAppSelector((state) => state.Health);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [addonStatus, setAddonStatus] = useState<TADITYABIRLAAddon>(
    ADDON_DETAILS_ADITYA_BIRLA
  );
  const [kycDetail, setKycDetail] = useState(false);
  const [kycOpen, setKycOpen] = useState<boolean>(false);
  const [selectedCheckboxList, setSelectedCheckboxList] = useState<string[]>(
    []
  );

  console.log(addonStatus, "addonStatus");

  const { CKYC_DETAILS, VERIFY_KYC_FORM } = useAppSelector(
    (state) => state.CKYC
  );
  const { CKYC } = useAppSelector((state) => state);
  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const transactionId = params.get("transactionId");
    const status = params.get("status");
    if (transactionId && status) {
      if (status === "needs_review" || status === "auto_declined") {
        toast.error("No Data Found!");
        window.location.href = `${window.location.origin}/proposal${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`;
      }
      const prams = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        transactionId: transactionId,
      };
      GET_KYC_DETAIL_ADITYA_BIRLA(prams);
    }
  }, []);

  useEffect(() => {
    updatePreiumApi();
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_ADITYA_BIRLA(addonStatus));
  }, [addonStatus]);

  const updateMasterState = (attrName: string, value: any) => {
    console.log(attrName, "attrName", value);

    // If value is falsy, update state and return early
    if (!value) {
      setSelectedCheckboxList((prevState) => {
        return prevState.filter((item) => item !== attrName[0]);
      });
    }

    // List of attributes with special handling
    const specialAttributes = [
      "NonChronic",
      "Diabetes",
      "Asthama",
      "Hypertension",
      "Hyperlipidaemia",
      "PTCA",
      "COPD",
      "HighBMI",
    ];

    // Check if attrName is in the list of special attributes
    if (specialAttributes.includes(attrName)) {
      // Ensure the number of selected checkboxes is within the limit
      if (selectedCheckboxList?.length >= 3) {
        toast.error("You can't select more than 3 options at once");
        return;
      }
    }

    if (
      attrName === "chronic_care" ||
      attrName === "chronic_management_program"
    ) {
      setAddonStatus((prevState) => ({
        ...prevState,
        chronic_care: value,
        chronic_management_program: value,
      }));
    } else {
      setAddonStatus((prevState) => ({
        ...prevState,
        [attrName]: value,
      }));
    }

    // Update state regardless of whether attrName is special or not
  };

  useEffect(() => {
    if (
      addonStatus?.NonChronic &&
      !selectedCheckboxList.includes("NonChronic")
    ) {
      setSelectedCheckboxList((prevState) => [...prevState, "NonChronic"]);
    }
    if (addonStatus?.Diabetes && !selectedCheckboxList.includes("Diabetes")) {
      setSelectedCheckboxList((prevState) => [...prevState, "Diabetes"]);
      return;
    }
    if (addonStatus?.Asthama && !selectedCheckboxList.includes("Asthama")) {
      setSelectedCheckboxList((prevState) => [...prevState, "Asthama"]);
      return;
    }
    if (
      addonStatus?.Hypertension &&
      !selectedCheckboxList.includes("Hypertension")
    ) {
      setSelectedCheckboxList((prevState) => [...prevState, "Hypertension"]);
      return;
    }
    if (
      addonStatus?.Hyperlipidaemia &&
      !selectedCheckboxList.includes("Hyperlipidaemia")
    ) {
      setSelectedCheckboxList((prevState) => [...prevState, "Hyperlipidaemia"]);
      return;
    }
    if (addonStatus?.PTCA && !selectedCheckboxList.includes("PTCA")) {
      setSelectedCheckboxList((prevState) => [...prevState, "PTCA"]);
      return;
    }
    if (addonStatus?.COPD && !selectedCheckboxList.includes("COPD")) {
      setSelectedCheckboxList((prevState) => [...prevState, "COPD"]);
      return;
    }
    if (addonStatus?.HighBMI && !selectedCheckboxList.includes("HighBMI")) {
      setSelectedCheckboxList((prevState) => [...prevState, "HighBMI"]);
      return;
    }
  }, [addonStatus, SELECTED_QUOTE_DATA]);

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_ADITYA_BIRLA: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      // addon: {
      //   second_medical_opinion_for_listed_major_illness:
      //     addonStatus?.second_medical_opinion_for_listed_major_illness === true
      //       ? "Yes"
      //       : "No",
      //   compassionate_visit:
      //     addonStatus?.compassionate_visit === true ? "Yes" : "No",
      //   durable_equipment_cover:
      //     addonStatus?.durable_equipment_cover === true ? "Yes" : "No",
      //   cancer_booster: addonStatus?.cancer_booster === true ? "Yes" : "No",
      //   reduction_in_specific_disease_waiting_period:
      //     addonStatus?.reduction_in_specific_disease_waiting_period === true
      //       ? "Yes"
      //       : "No",
      //   reduction_in_pre_existing_disease_waiting_period:
      //     addonStatus?.reduction_in_pre_existing_disease_waiting_period === true
      //       ? "Yes"
      //       : "No",
      //   room_rent_type_options:
      //     addonStatus?.room_rent_type_options === true ? "Yes" : "No",
      //   per_claim_deductible:
      //     addonStatus?.per_claim_deductible === true ? "Yes" : "No",
      //   in_patient_hospitalization:
      //     addonStatus?.in_patient_hospitalization === true ? "Yes" : "No",
      //   road_ambulance_cover_per_hospitalization:
      //     addonStatus?.road_ambulance_cover_per_hospitalization === true
      //       ? "Yes"
      //       : "No",
      //   modern_procedures_treatments:
      //     addonStatus?.modern_procedures_treatments === true ? "Yes" : "No",
      //   hiv_aids_and_std_over:
      //     addonStatus?.hiv_aids_and_std_over === true ? "Yes" : "No",
      //   mental_illness_hospitalization:
      //     addonStatus?.mental_illness_hospitalization === true ? "Yes" : "No",
      //   obesity_treatment:
      //     addonStatus?.obesity_treatment === true ? "Yes" : "No",
      //   pre_hospitalization_expenses:
      //     addonStatus?.pre_hospitalization_expenses === true ? "Yes" : "No",
      //   post_hospitalization_expenses:
      //     addonStatus?.post_hospitalization_expenses === true ? "Yes" : "No",
      //   domiciliary_hospitalization:
      //     addonStatus?.domiciliary_hospitalization === true ? "Yes" : "No",
      //   home_health_care: addonStatus?.home_health_care === true ? "Yes" : "No",
      //   ayush_treatment: addonStatus?.ayush_treatment === true ? "Yes" : "No",
      //   organ_donor_expenses:
      //     addonStatus?.organ_donor_expenses === true ? "Yes" : "No",
      //   super_reload: addonStatus?.super_reload === true ? "Yes" : "No",
      //   health_assessmentTM:
      //     addonStatus?.health_assessmentTM === true ? "Yes" : "No",
      //   healthReturnsTM: addonStatus?.healthReturnsTM === true ? "Yes" : "No",
      //   claim_protect_non_medical:
      //     addonStatus?.claim_protect_non_medical === true ? "Yes" : "No",
      //   preferred_provider_network:
      //     addonStatus?.preferred_provider_network === true ? "Yes" : "No",
      //   critical_illness_cover:
      //     addonStatus?.critical_illness_cover === true ? "Yes" : "No",
      //   personal_accident_cover:
      //     addonStatus?.personal_accident_cover === true ? "Yes" : "No",
      //   Super_credit_increases_irrespective_of_claim:
      //     addonStatus?.Super_credit_increases_irrespective_of_claim === true
      //       ? "Yes"
      //       : "No",
      //   chronic_care: addonStatus?.chronic_care === true ? "Yes" : "No",
      //   chronic_management_program:
      //     addonStatus?.chronic_management_program === true ? "Yes" : "No",
      //   chronic_care_restriction:
      //     addonStatus?.chronic_care_restriction === true ? "Yes" : "No",
      //   annual_screening_package_for_cancer_diagnosed_patients:
      //     addonStatus?.annual_screening_package_for_cancer_diagnosed_patients ===
      //     true
      //       ? "Yes"
      //       : "No",
      //   annual_health_check_up:
      //     addonStatus?.annual_health_check_up === true ? "Yes" : "No",
      //   vaccine_cover: addonStatus?.vaccine_cover === true ? "Yes" : "No",
      //   tele_OPD_consultation:
      //     addonStatus?.tele_OPD_consultation === true ? "Yes" : "No",
      //   opd_add_on: addonStatus?.opd_add_on === true ? "Yes" : "No",
      // },
      addon: {
        cancer_booster: addonStatus?.cancer_booster === true ? "Yes" : "No",
        reduction_in_pre_existing_disease_waiting_period:
          addonStatus?.reduction_in_pre_existing_disease_waiting_period === true
            ? "Yes"
            : "No",
        reduction_ped_waiting_period: addonStatus?.reduction_ped_waiting_period, //  DropDown => 3TO1 = 3 Years to 1 Year , 3TO2 = 3 Years to 2 Years
        room_rent_type_options:
          addonStatus?.room_rent_type_options === true ? "Yes" : "No",
        room_rent_type: addonStatus?.room_rent_type, //  DropDown => SH = Shared Accomodation , YSY = Single Private Room
        critical_illness_cover:
          addonStatus?.critical_illness_cover === true ? "Yes" : "No",
        critical_illness_cover_SI: addonStatus?.critical_illness_cover_SI, // Dropdown of SI
        personal_accident_cover:
          addonStatus?.personal_accident_cover === true ? "Yes" : "No",
        personal_accident_cover_SI: addonStatus?.personal_accident_cover_SI, // Dropdown of SI
        chronic_care:
          addonStatus?.chronic_care === true &&
          SELECTED_QUOTE_DATA.productDetails.product_code === "HPAB058"
            ? "Yes"
            : "No", // For VYTL Plan Only
        chronic_management_program:
          addonStatus?.chronic_management_program === true ? "Yes" : "No",
        durable_equipment_cover:
          addonStatus?.durable_equipment_cover === true ? "Yes" : "No",
        Non_Chronic: addonStatus?.NonChronic === true ? "Non-Chronic" : "", // Checkbox in Chronic Management
        Asthama: addonStatus?.Asthama === true ? "Asthama" : "", // Checkbox in Chronic Management
        Hypertension: addonStatus?.Hypertension === true ? "Hypertension" : "", // Checkbox in Chronic Management
        Hyperlipidaemia:
          addonStatus?.Hyperlipidaemia === true ? "Hyperlipidaemia" : "", // Checkbox in Chronic Management
        PTCA: addonStatus?.PTCA === true ? "PTCA" : "", // Checkbox in Chronic Management
        COPD: addonStatus?.COPD === true ? "COPD" : "", // Checkbox in Chronic Management
        HighBMI: addonStatus?.HighBMI === true ? "HighBMI" : "", // Checkbox in Chronic Management
        Diabetes: addonStatus?.Diabetes === true ? "Diabetes" : "",
        natureOfDuty: addonStatus?.natureOfDuty, // dropdown in Personal Accident
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonAdityaBirla(
      onSuccess,
      onError,
      param
    );
  };

  // const GET_KYC_DETAIL_ADITYA_BIRLA =()=>{
  //   const onSuccess = (res: any) => {
  //     console.log("resGET_KYC_DETAIL_ADITYA_BIRLA",res);
  //     setKycDetail(true);
  //     dispatch(
  //       CKYCSlice.actions.BULK_UPDATE({
  //         ...CKYC,
  //         // VERIFY_KYC_FORM: { ...data },
  //         CKYC_DETAILS: res?.results?.response,
  //       })
  //     );
  //   };
  //   const onError = (err: any) => {
  //     console.log("err", err);
  //   };
  // // Create a URLSearchParams object based on current URL
  // const urlParams = new URLSearchParams(window.location.search);

  // // Get transactionId from URL parameter
  // const transactionId = urlParams.get('transactionId');
  //   let param: any = {
  //     quote_no: ADD_FORM_RESPONSE.quote_no,
  //     transactionId:transactionId
  //   };

  //   CKYCServcies.GET_KYC_DETAIL_ADITYA_BIRLA(onSuccess,onError,param);
  // }

  const GET_KYC_DETAIL_ADITYA_BIRLA = (params: any) => {
    console.log("params", params);
    let param: any = {
      quote_no: params.quote_no,
      transactionId: params.transactionId,
    };

    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      setKycDetail(true);
      setLoader(false);
      if (!error) {
        const response = results.response;

        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            CKYC_DETAILS: response,
          })
        );
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: response.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: response.kycDOB,
                  warning: false,
                },
                mobile: {
                  value: CKYC.CKYC_DETAILS.kycMobile,
                  warning: false,
                },
                emergencyPhone: { value: "", warning: false },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                aadhar: { value: "", warning: false },
                gender: {
                  value: VERIFY_KYC_FORM.gender.value,
                  warning: false,
                },
                maritalStatus: { value: "", warning: false },
                weight: { value: "", warning: false },
                heightFeet: { value: "", warning: false },
                heightInches: { value: "", warning: false },
                occupation: { value: "", warning: false },
                relationship: { value: "", warning: false },
                proposer_name: { value: "", warning: false },
              },
            },
            ADDRESS_DETAILS: {
              ...Health.ADDRESS_DETAILS,
              pincode: {
                value: response.kycPincode,
                warning: false,
              },
              city: response.kycCity,
              state: response.kycState,
              addressLineOne: {
                value: response.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: response.kycAddress2,
                warning: false,
              },
              isMailingAddressSame: true,
              mailingPinCode: { value: "", warning: false },
              mailingCity: "",
              mailingState: "",
              mailingAddressLineOne: { value: "", warning: false },
              mailingAddressLineTwo: { value: "", warning: false },
            },
          })
        );
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);
    CKYCServcies.GET_KYC_DETAIL_ADITYA_BIRLA(onSuccess, onError, param);
  };

  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    if (Number(SELECTED_QUOTE_DATA.sumInsured) >= 20000000) {
      setAddonStatus((prevState) => ({
        ...prevState,
        Claim_Protector: true,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      }));
    }
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
           if (PAGE_STATUS) {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    // let param: any = {
    //   quote_no: ADD_FORM_RESPONSE.quote_no,
    //   product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
    //   company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
    //   section: "add_ons_details",
    //   cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
    //     (parseInt(ADD_FORM?.son_count?.value) || 0) +
    //       (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
    //     0
    //       ? `${
    //           (parseInt(ADD_FORM?.son_count?.value) || 0) +
    //           (parseInt(ADD_FORM?.daughter_count?.value) || 0)
    //         }C`
    //       : ""
    //   }`,
    //   second_medical_opinion_for_listed_major_illness:
    //     addonStatus?.second_medical_opinion_for_listed_major_illness === true
    //       ? "Yes"
    //       : "No",
    //   compassionate_visit:
    //     addonStatus?.compassionate_visit === true ? "Yes" : "No",
    //   durable_equipment_cover:
    //     addonStatus?.durable_equipment_cover === true ? "Yes" : "No",
    //   cancer_booster: addonStatus?.cancer_booster === true ? "Yes" : "No",
    //   reduction_in_specific_disease_waiting_period:
    //     addonStatus?.reduction_in_specific_disease_waiting_period === true
    //       ? "Yes"
    //       : "No",
    //   reduction_in_pre_existing_disease_waiting_period:
    //     addonStatus?.reduction_in_pre_existing_disease_waiting_period === true
    //       ? "Yes"
    //       : "No",
    //   room_rent_type_options:
    //     addonStatus?.room_rent_type_options === true ? "Yes" : "No",
    //   per_claim_deductible:
    //     addonStatus?.per_claim_deductible === true ? "Yes" : "No",
    //   in_patient_hospitalization:
    //     addonStatus?.in_patient_hospitalization === true ? "Yes" : "No",
    //   road_ambulance_cover_per_hospitalization:
    //     addonStatus?.road_ambulance_cover_per_hospitalization === true
    //       ? "Yes"
    //       : "No",
    //   modern_procedures_treatments:
    //     addonStatus?.modern_procedures_treatments === true ? "Yes" : "No",
    //   hiv_aids_and_std_over:
    //     addonStatus?.hiv_aids_and_std_over === true ? "Yes" : "No",
    //   mental_illness_hospitalization:
    //     addonStatus?.mental_illness_hospitalization === true ? "Yes" : "No",
    //   obesity_treatment: addonStatus?.obesity_treatment === true ? "Yes" : "No",
    //   pre_hospitalization_expenses:
    //     addonStatus?.pre_hospitalization_expenses === true ? "Yes" : "No",
    //   post_hospitalization_expenses:
    //     addonStatus?.post_hospitalization_expenses === true ? "Yes" : "No",
    //   domiciliary_hospitalization:
    //     addonStatus?.domiciliary_hospitalization === true ? "Yes" : "No",
    //   home_health_care: addonStatus?.home_health_care === true ? "Yes" : "No",
    //   ayush_treatment: addonStatus?.ayush_treatment === true ? "Yes" : "No",
    //   organ_donor_expenses:
    //     addonStatus?.organ_donor_expenses === true ? "Yes" : "No",
    //   super_reload: addonStatus?.super_reload === true ? "Yes" : "No",
    //   health_assessmentTM:
    //     addonStatus?.health_assessmentTM === true ? "Yes" : "No",
    //   healthReturnsTM: addonStatus?.healthReturnsTM === true ? "Yes" : "No",
    //   claim_protect_non_medical:
    //     addonStatus?.claim_protect_non_medical === true ? "Yes" : "No",
    //   preferred_provider_network:
    //     addonStatus?.preferred_provider_network === true ? "Yes" : "No",
    //   critical_illness_cover:
    //     addonStatus?.critical_illness_cover === true ? "Yes" : "No",
    //   personal_accident_cover:
    //     addonStatus?.personal_accident_cover === true ? "Yes" : "No",
    //   Super_credit_increases_irrespective_of_claim:
    //     addonStatus?.Super_credit_increases_irrespective_of_claim === true
    //       ? "Yes"
    //       : "No",
    //   chronic_care: addonStatus?.chronic_care === true ? "Yes" : "No",
    //   chronic_management_program:
    //     addonStatus?.chronic_management_program === true ? "Yes" : "No",
    //   chronic_care_restriction:
    //     addonStatus?.chronic_care_restriction === true ? "Yes" : "No",
    //   annual_screening_package_for_cancer_diagnosed_patients:
    //     addonStatus?.annual_screening_package_for_cancer_diagnosed_patients ===
    //     true
    //       ? "Yes"
    //       : "No",
    //   annual_health_check_up:
    //     addonStatus?.annual_health_check_up === true ? "Yes" : "No",
    //   vaccine_cover: addonStatus?.vaccine_cover === true ? "Yes" : "No",
    //   tele_OPD_consultation:
    //     addonStatus?.tele_OPD_consultation === true ? "Yes" : "No",
    //   opd_add_on: addonStatus?.opd_add_on === true ? "Yes" : "No",
    // };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      section: "add_ons_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      cancer_booster: addonStatus?.cancer_booster === true ? "Yes" : "No",
      reduction_in_pre_existing_disease_waiting_period:
        addonStatus?.reduction_in_pre_existing_disease_waiting_period === true
          ? "Yes"
          : "No",
      reduction_ped_waiting_period: addonStatus?.reduction_ped_waiting_period, //  DropDown => 3TO1 = 3 Years to 1 Year , 3TO2 = 3 Years to 2 Years
      room_rent_type_options:
        addonStatus?.room_rent_type_options === true ? "Yes" : "No",
      room_rent_type: addonStatus?.room_rent_type, //  DropDown => SH = Shared Accomodation , YSY = Single Private Room
      critical_illness_cover:
        addonStatus?.critical_illness_cover === true ? "Yes" : "No",
      critical_illness_cover_SI: addonStatus?.critical_illness_cover_SI, // Dropdown of SI
      personal_accident_cover:
        addonStatus?.personal_accident_cover === true ? "Yes" : "No",
      personal_accident_cover_SI: addonStatus?.personal_accident_cover_SI, // Dropdown of SI
      chronic_care:
        addonStatus?.chronic_care === true &&
        SELECTED_QUOTE_DATA.productDetails.product_code === "HPAB058"
          ? "Yes"
          : "No", // For VYTL Plan Only
      chronic_management_program:
        addonStatus?.chronic_management_program === true ? "Yes" : "No",
      durable_equipment_cover:
        addonStatus?.durable_equipment_cover === true ? "Yes" : "No",
      Non_Chronic: addonStatus?.NonChronic === true ? "Non-Chronic" : "", // Checkbox in Chronic Management
      Asthama: addonStatus?.Asthama === true ? "Asthama" : "", // Checkbox in Chronic Management
      Hypertension: addonStatus?.Hypertension === true ? "Hypertension" : "", // Checkbox in Chronic Management
      Hyperlipidaemia:
        addonStatus?.Hyperlipidaemia === true ? "Hyperlipidaemia" : "", // Checkbox in Chronic Management
      PTCA: addonStatus?.PTCA === true ? "PTCA" : "", // Checkbox in Chronic Management
      COPD: addonStatus?.COPD === true ? "COPD" : "", // Checkbox in Chronic Management
      HighBMI: addonStatus?.HighBMI === true ? "HighBMI" : "", // Checkbox in Chronic Management
      Diabetes: addonStatus?.Diabetes === true ? "Diabetes" : "",
      natureOfDuty: addonStatus?.natureOfDuty, // dropdown in Personal Accident
    };

    // if (PAGE_STATUS) {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
    //   );
    // } else {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
    //   );
    // }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  
  const FinalPremium = ()=>{
    const onSuccess=(res:any)=>{
console.log("res",res);

    }

    const onError =(err:any)=>{
      console.log("err",err);
    }

    let param:any={
       quote_no: ADD_FORM_RESPONSE.quote_no,
       final_premium : SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium
    }
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  }


  
  useEffect(() => {
    FinalPremium()
}, [SELECTED_QUOTE_DATA]);

        useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MAddonDetailsADITYABIRLA
          kycDetail={kycDetail}
          setKycDetail={setKycDetail}
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <AddonDetailsADITYABIRLA
          kycDetail={kycDetail}
          setKycDetail={setKycDetail}
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default AddonDetailsContainer;
