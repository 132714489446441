import { ECompanyCode, EProductId } from "../../../Services/Enum/EHome";
import { useAppSelector } from "../../../Store/hooks";
import { CARE_ROUTES } from "./TravelProposalRoutes/CARE/CARE_ROUTES";

export default function TravelRoutes() {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Travel);
  const company_code = SELECTED_QUOTE_DATA.companyCode

  console.log(company_code, 'company_code')

  return (
    <>
      {/* <COMMON_ROUTES /> */}
      {(() => {
        switch (company_code) {
          case ECompanyCode.CARE:
            return <CARE_ROUTES />;
          default:
            return null;
        }
      })()}
    </>
  );
}
