import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "../../../Component/Footer/Footer.scss";
import CustomButton from "../../CustomButton/CustomButton";
import RKTextField from "../../FieldTypes/RKTextField/RKTextField";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { TFieldData } from "../../../types/Common/TFieldData";
import { useAppSelector } from "../../../Store/hooks";

const TermFooter = ({
  forward,
  disableButton,
  textName = "Continue",
  back_text = "Back",
  loader = false,
}: {
  forward?: Function;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
  back_text?: string;
}) => {
  const navigate = useNavigate();
  const [param, setParam] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  // const [utmMedium, setUtmMedium] = useState<any>("");

  const [previewDetail, setPreviewDetail] = useState("");
  const [linkLoad, setLinkLoad] = useState<boolean>(false);
  useEffect(() => {
    const url = window.location.href;
    const urlObj = new URL(url);
    const pathname = urlObj.pathname;
    const segments = pathname.split("/");
    const desiredSegment = segments[segments.length - 1];
    setPreviewDetail(desiredSegment);
  }, []);

  useEffect(() => {
    const fullURL = window.location.href;
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    const payment = params.get("cus_type");
    // const utm_medium = params.get("utm_medium");
    setUrl(fullURL);
    setType(type || "");
    setParam(payment || "");
    // setUtmMedium(utm_medium)
  }, []);

  const [shareURLData, setShareURLData] = useState<{
    email: TFieldData;
    mobile: TFieldData;
  }>({
    email: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);
  const { Term } = useAppSelector((state) => state);

  const handleDetails = (attrName: any, value: any) => {
    if (attrName === "email") {
      setShareURLData((prev) => ({
        ...prev,
        email: { value: value, warning: !validateEmail(value) },
      }));
    } else if (attrName === "mobile") {
      setShareURLData((prev) => ({
        ...prev,
        mobile: { value: value, warning: !validateMobileNumber(value) },
      }));
    }
  };

  const PAYMENT_LINK = () => {
    setLinkLoad(true);
    let data = { ...shareURLData };
    if (!isEmpty(data.email.value)) {
      data = {
        ...data,
        email: { ...data.email, warning: !validateEmail(data.email.value) },
      };
    } else {
      data = {
        ...data,
        email: { ...data.email, warning: false },
      };
    }
    if (!isEmpty(data.mobile.value)) {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
      };
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: false,
        },
      };
    }

    setShareURLData(data);
    // Determine if there's an error
    const hasError = data.email.warning || data.mobile.warning;

    if (isEmpty(data.email.value) && isEmpty(data.mobile.value)) {
      toast.error("Please fill mandatory fields.");
      setLinkLoad(false);
      return;
    }

    if (hasError) {
      setLinkLoad(false);
      return;
    }

    const onSuccess = (res: any) => {
      setLinkLoad(false);
      console.log("result", res);
      setShowPaymentPopup(false);
      setShareURLData({
        email: { value: "", warning: false },
        mobile: { value: "", warning: false },
      });
      toast.success("Link sent successfully");
    };

    const onError = (err: any) => {
      console.log("error", err);
      setLinkLoad(false);
    };

    let param = {
      customerName: `${Term?.ADD_FORM_RESPONSE?.name}`,
      email: data.email.value,
      number: data.mobile.value,
      planName: "Term Insurance",
      insurer: `${Term?.SELECTED_QUOTE_DATA?.companyDetails?.short_desc}`,
      paymentLink: `${url}&cus_type=user`,
      utm_source: `${Term?.ADD_FORM_RESPONSE?.utm_source}`,
      page: "ListingPage_term",
    };

    HEALTH_PROPOSAL_SERVICES.PAYMENT_LINK(onSuccess, onError, param);
  };

  const showShareLink =
    !isEmpty(Term.ADD_FORM_RESPONSE.utm_medium) && isEmpty(param);

  const CUS = Term?.CUS_TYPE;

  // const [canGoBack, setCanGoBack] = useState(false);
  // console.log("window.history.state?.idx", window.history.state?.idx);

  // useEffect(() => {
  //   if (window.history.state?.idx > 0) {
  //     setCanGoBack(true);
  //   } else {
  //     setCanGoBack(false);
  //   }
  // }, []);
  return (
    <Box
      className={`proposalFooter paymentLinkPage ${
        previewDetail === "preview-details" ? "paymentLinkPage" : ""
      }`}
    >
      <Modal open={showPaymentPopup} className="modalWrapper">
        <Box className="modalContent xsWidth kycPopups">
          <Grid container spacing={2}>
            <Grid xs={12} textAlign="center">
              <h4 className="popup-heading mb-2 mt-6">Enter Details</h4>
              <p className="grey_p">Enter Details to proceed</p>
              <Link
                className="close-button"
                onClick={() => {
                  setShowPaymentPopup(false);
                  setShareURLData({
                    email: { value: "", warning: false },
                    mobile: { value: "", warning: false },
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} textAlign="center">
            <Grid xs={12}>
              <RKTextField
                class_name="inputField mb-6"
                title={"Proposer Email"}
                value={shareURLData.email.value}
                attrName={"email"}
                value_update={handleDetails}
                error_message={
                  !isEmpty(shareURLData.email.value)
                    ? "Enter Valid E-mail"
                    : "Enter E-mail"
                }
                warn_status={shareURLData.email.warning}
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Proposer Mobile"}
                value={shareURLData.mobile.value}
                attrName={"mobile"}
                value_update={handleDetails}
                error_message={
                  !isEmpty(shareURLData.mobile.value)
                    ? "Enter Valid Mobile No."
                    : "Enter Mobile No."
                }
                warn_status={shareURLData.mobile.warning}
                validation_type="NUMBER"
                max_length={10}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} marginTop={"24px"}>
            <Grid xs={12} style={{ textAlign: "center" }}>
              <CustomButton
                text_name="Submit"
                class_name="regularPrimaryBtn mb-3"
                onClickFunction={PAYMENT_LINK}
                loading={linkLoad}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid container spacing={3}>
        <Grid xs={8} xsOffset={3} className="footerInner" alignItems="center">
          {(param && param === "user")  ||   showShareLink ? null : (
              <Link
                className="backStep"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon /> {back_text}
              </Link>
          )}
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <div className="ctaBtn">
              <CustomButton
                text_name={textName}
                class_name="regularPrimaryBtn"
                onClickFunction={() => {
                  if (forward) {
                    forward();
                  }
                }}
                loading={loader}
                disabled={disableButton}
              />

              {/* This button will display only on preview page */}
              {/* <Buttons class_name='regularPrimaryBtn ml-4' text_name='Test Link'/> */}
            </div>
            {showShareLink && !CUS  ? (
              <CustomButton
                text_name={"Share Link"}
                class_name="regularPrimaryBtn ml-3"
                onClickFunction={() => {
                  setShowPaymentPopup(true);
                }}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermFooter;
