import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
// import { toast } from "react-toastify";
import VehicleDetailsAreIncorrect from "./Component/VehicleDetailsAreIncorrect/VehicleDetailsAreIncorrect";
import { CKYC_ROUTES } from "./Router/Path/CKYCRoutes";
import { HEALTH_ROUTE } from "./Router/Path/HealthRoutes";
import { TW_ROUTES } from "./Router/Path/TWRoutes";
import CarRoutes from "./Router/Routes/CarRoutes/CarRoutes";
import CommonRoutes from "./Router/Routes/CommonRoutes/CommonRoutes";
import HealthRoutes from "./Router/Routes/Health/HealthRoutes";
import TWRoutes from "./Router/Routes/TWRoutes/TWRoutes";
import TermRoutes from "./Router/Routes/TermRoutes/TermRoutes";
import { CAR_ADD_FORM_SERVICES } from "./Services/Car/CarAddFormServices";
import { CAR_SERVICES } from "./Services/Car/CarServices";
import { CKYCSlice } from "./Store/Slice_Reducer/CKYC/CKYCSlice";
import { CarSlice } from "./Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "./Store/Slice_Reducer/Health/HealthSlice";
import { TermSlice } from "./Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "./Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "./Store/hooks";
import {
  DETERMINE_MOTOR_PLAN_TYPE,
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "./SupportingFiles/HelpingFunction";
import changeFaviconAndTitle from "./SupportingFiles/faviconUtils";
import { CLIENTS } from "./URLCollection/Clients";
import { TCKYCDetails, TVerifyCKYCForm } from "./types/CKYC/TCKYC";
import { TAddonsValueType } from "./types/Car/TAddonsValueType";
import { TCarAddonStatus } from "./types/Car/TCarAddonStatus";
import { TCarProduct } from "./types/Car/TCarProduct";
import { TCarSingleQuoteDetail } from "./types/Car/TCarSingleQuoteDetail";
import { TAddressDetails } from "./types/Common/TAddressDetails";
import { THealthFormResponse } from "./types/Health/Form/THealthFormResponse";
import { THealthQuote } from "./types/Health/HealthQuotation/THealthQuote";
import {
  INSURED_MEMBER_DETAILS,
  TBank_Details_NIVABUPA,
  TBank_Details_AdityaBirla,
  THealthAddForm,
} from "./types/Health/THealthSlice";
import {
  TCarAddForm,
  TCarAddFormResponse,
  TCarDiscount,
  TCarOwnerDetails,
  TCarSlice,
  TCarVehicleDetails,
  TClaimStatus,
  TNCB,
  TNomineeDetails,
} from "./types/TCarSlice";
import {
  TOwnerDetails,
  TTWAddFormResponse,
  TTWForm,
  TTWSlice,
  TVehicleDetails,
} from "./types/TTWSlice";
import { TSelectedQuote, TTermProposerData } from "./types/TTermSlice";
import { TRidersBenefits } from "./types/Term/TRidersBenifits";
import { TTermAddForm, TTermAddFormResponse } from "./types/Term/TTermAddForm";
import { TTWAddonStatus } from "./types/TwoWheeler/TTWAddonStatus";
import { TTWProduct } from "./types/TwoWheeler/TTWProduct";
import { TTWSingleQuoteDetail } from "./types/TwoWheeler/TTWSingleQuoteDetail";
import {
  GET_ADITYA_BIRLA_ADDON_DATA,
  GET_CARE_ADDON_DATA,
  GET_HDFC_ADDON_DATA,
  GET_ICICI_ADDON_DATA,
  GET_ICICI_ADDON_GOLDEN_DATA,
  GET_MANIPAL_CIGNA_LIFETIME_GLOBAL_ADDON_DATA,
  GET_MANIPAL_CIGNA_LIFETIME_INDIA_ADDON_DATA,
  GET_MANIPAL_CIGNA_PRIME_ACTIVE_ADDON_DATA,
  GET_MANIPAL_CIGNA_PRIME_ADVANTAGE_ADDON_DATA,
  GET_MANIPAL_CIGNA_PRIME_PROTECT_ADDON_DATA,
  GET_NIVA_BUPA_ADDON_DATA,
} from "./utils/addonDetailsForPreview/AddonDetailsForPreview";
import { ECompanyCode, EProductId } from "./Services/Enum/EHome";
import HIRoutes from "./Router/Routes/HI/Proposal/HIRoutes";
import { TTravelAddForm, TTravelSlice } from "./types/Travel/TTravelSlice";
import { TravelSlice } from "./Store/Slice_Reducer/Travel/TravelSlice";
import TravelRoutes from "./Router/Routes/TravelRoutes/TravelRoutes";

function App() {
  const dispatch = useAppDispatch();
  const { Term } = useAppSelector((state: any) => state);
  const [loader, setLoader] = useState<boolean>(false);

  const [vehicledetailspro, setVehicledetailspro] = useState<boolean>(false);
  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      // Prevent the default install prompt
      event.preventDefault();
      console.log("Install prompt disabled");
    };

    // Listen for the beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Cleanup the event listener
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const { PROPOSER_DATA } = useAppSelector((state) => state.Term);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    const quote_no = params.get("quote_no");
    const product_id = params.get("product_id");
    const companyCode = params.get("companyCode");
    changeFaviconAndTitle();

    if (type === "HI") {
      setLoader(false);
      setIsVisible(true);

      return;
    }

    if (type && quote_no && companyCode && product_id) {
      if (
        type !== "HP" ||
        (type === "HP" &&
          (companyCode === ECompanyCode.CARE ||
            companyCode === ECompanyCode.STAR ||
            companyCode === ECompanyCode.NIVA_BUPA ||
            companyCode === ECompanyCode.ICICI))
      ) {
        dispatch(
          TermSlice.actions.BULK_UPDATE({ ...TermSlice.getInitialState() })
        );
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYCSlice.getInitialState(),
          })
        );
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...HealthSlice.getInitialState(),
          })
        );
        dispatch(TWSlice.actions.BULK_UPDATE(TWSlice.getInitialState()));
        dispatch(CarSlice.actions.BULK_UPDATE(CarSlice.getInitialState()));
      } else {
        setLoader(false);
      }
      const prams = {
        type: `${type}`,
        quote_no: quote_no,
        companyCode: companyCode,
        product_id: product_id,
      };

      setTimeout(() => {
        if (
          type !== "HP" ||
          (type === "HP" &&
            (companyCode === ECompanyCode.CARE ||
              companyCode === ECompanyCode.STAR ||
              companyCode === ECompanyCode.NIVA_BUPA ||
              companyCode === ECompanyCode.ICICI))
        ) {
          GET_PREVIEW_DATA(prams);
        }
      }, 2000);

      return;
    }

    if (type && quote_no && product_id && isEmpty(companyCode)) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({ ...TermSlice.getInitialState() })
      );
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYCSlice.getInitialState(),
        })
      );
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...HealthSlice.getInitialState(),
        })
      );
      dispatch(TWSlice.actions.BULK_UPDATE(TWSlice.getInitialState()));
      dispatch(CarSlice.actions.BULK_UPDATE(CarSlice.getInitialState()));
      const prams = {
        type: type,
        quote_no: quote_no,
        product_id: product_id,
      };

      setTimeout(() => {
        GET_PROPOSAL_INFO(prams);
      }, 2000);
    }
  }, []);

  const SET_HEALTH_DATA = (response: any) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    console.log("params", params);

    const cus_type = params.get("cus_type");
    console.log(">>>", cus_type);

    const redirected: boolean = params.get("redirected") === "true";

    if (
      window.location.pathname !==
        `/proposal${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}` &&
      window.location.pathname !==
        `/proposal${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}`
    ) {
      return;
    }
    const form_data = response.healthResponse;
    const more_details = form_data.more_details;
    const premium_details = response.premiumDetails;
    const company_details = response.companyDetails;
    const product_details = response.productDetails;

    let modify_more_details: { [key: string]: any } = {};

    more_details.forEach((detail: any) => {
      Object.keys(detail).forEach((key) => {
        modify_more_details[key] = detail[key];
      });
    });

    let sonCount = 0;
    let daughterCount = 0;

    more_details.forEach((detail: any) => {
      Object.values(detail).forEach((value) => {
        if (value === "son") {
          sonCount++;
        } else if (value === "daughter") {
          daughterCount++;
        }
      });
    });

    const manipulated_data_add_form: THealthAddForm = {
      spouse_relationship: {
        value: modify_more_details?.adult2Relation,
        warning: false,
      },
      age: {
        value: `${modify_more_details?.adult1Age}`,
        warning: false,
      },
      children: form_data.child,
      gender: { value: modify_more_details?.adult1Gender, warning: false },
      pincode: { value: `${form_data.pincode}`, warning: false },
      whom_to_insure: "One Adult",
      mobile: { value: form_data.mobile, warning: false },
      name: { value: "", warning: false },
      son_count: { value: sonCount, warning: false },
      daughter_count: { value: daughterCount, warning: false },
      self_dob: {
        value: `${modify_more_details?.adult1Age}`,
        warning: false,
      },
      spouse_dob: {
        value: `${modify_more_details?.adult2Age}`,
        warning: false,
      },
      son_status: sonCount > 0,
      daughter_status: daughterCount > 0,
      self_status: true,
      spouse_status: `${form_data.adult}` === "2",
      child_one_dob: { value: modify_more_details?.childage_1, warning: false },
      child_two_dob: { value: modify_more_details?.childage_2, warning: false },
      child_three_dob: {
        value: modify_more_details?.childage_3,
        warning: false,
      },
      child_four_dob: {
        value: modify_more_details?.childage_4,
        warning: false,
      },
    };

    const manipulated_selected_quote_data: THealthQuote = {
      CashlessHospitalLists: [],
      CompanyDetails: company_details,
      loading: false,
      premiumDetails: {
        ...premium_details,
        finalPremium: premium_details.premium,
      },
      productDetails: product_details,
      SpecialFeatureLists: [],
      sumInsured: form_data.sum_insured,
    };

    let health_slice = {
      ...HealthSlice.getInitialState(),
      ADD_FORM: {
        ...manipulated_data_add_form,
        children: form_data.child,
      },
      ADD_FORM_RESPONSE: {
        ...HealthSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: form_data.quote_no,
        sum_insured: parseInt(form_data.sum_insured),
        utm_medium: form_data?.utm_medium,
        utm_source: form_data?.utm_source,
      },
      SELECTED_QUOTE_DATA: {
        ...HealthSlice.getInitialState().SELECTED_QUOTE_DATA,
        ...manipulated_selected_quote_data,
      },
      CUS_TYPE: !isEmpty(cus_type) ? true : false,
    };

    if (redirected) {
      health_slice = { ...health_slice, REDIRECTED: redirected };
    }

    dispatch(CKYCSlice.actions.BULK_UPDATE({ ...CKYCSlice.getInitialState() }));
    dispatch(HealthSlice.actions.BULK_UPLOAD({ ...health_slice }));
  };

  const SET_TERM_DATA = (response: any) => {
    try {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");
      const redirected: boolean = params.get("redirected") === "true";

      const form_data = response.lifeResponse;

      const premium_details = response.premiumDetails;
      const manipulated_data_add_form: TTermAddForm = {
        annualIncome: { value: form_data.annual_income, warning: false },
        smoke: premium_details.smoker,
        gender: { value: premium_details.gender, warning: false },
        age: {
          value: `${
            premium_details.dob_date?.length > 1
              ? premium_details.dob_date
              : `0${premium_details.dob_date}`
          }-${
            premium_details.dob_month?.length > 1
              ? premium_details.dob_month
              : `0${premium_details.dob_month}`
          }-${premium_details.dob_year}`,
          warning: false,
        },
        pincode: { value: form_data.pincode, warning: false },
        name: { value: form_data.name, warning: false },
        mobile: { value: form_data.mobile, warning: false },
      };

      const manipulated_selected_quote_data: TSelectedQuote = {
        appno: "",
        documentURL: "",
        transaction_id: "",
        premiumDetails: response.premiumDetails,
        companyDetails: response.companyDetails,
        SpecialFeatureLists: [],
        productDetailLists: null,
        buyOnlineLink: {
          method: "",
          buy_online_link: "",
          redirection_type: "",
        },
        productDetails: {
          ...response.productDetails,
          frequency: premium_details.frequency,
        },
        sumAssured: "",
        QuoteNo: "",
      };

      let term_slice = {
        ...TermSlice.getInitialState(),
        ADD_FORM: {
          ...TermSlice.getInitialState().ADD_FORM,
          ...manipulated_data_add_form,
        },
        ADD_FORM_RESPONSE: {
          ...TermSlice.getInitialState().ADD_FORM_RESPONSE,
          quote_no: form_data.quote_no,
          utm_medium: form_data?.utm_medium,
          utm_source: form_data?.utm_source,
        },
        SELECTED_QUOTE_DATA: {
          ...TermSlice.getInitialState().SELECTED_QUOTE_DATA,
          ...manipulated_selected_quote_data,
        },
        PROPOSER_DATA: {
          ...TermSlice.getInitialState().PROPOSER_DATA,
          full_name: { value: form_data.name, warning: false },
          mobile: { value: form_data.mobile, warning: false },
          dob: {
            value: `${
              premium_details.dob_date?.length > 1
                ? premium_details.dob_date
                : `0${premium_details.dob_date}`
            }-${
              premium_details.dob_month?.length > 1
                ? premium_details.dob_month
                : `0${premium_details.dob_month}`
            }-${premium_details.dob_year}`,
            warning: false,
          },
          gender: {
            value: premium_details.gender,
            warning: false,
          },
          pincode: { value: form_data.pincode, warning: false },
          sum_assured: { value: form_data.sum_assured, warning: false },
          annual_income: { value: form_data.annual_income, warning: false },
          email: { value: form_data.email, warning: false },
        },
        QUOTE_FIELDS_DATA: {
          ...TermSlice.getInitialState().QUOTE_FIELDS_DATA,
          sumAssured: premium_details.sum_assured,
          payMode: premium_details.frequency,
        },
        RIDERS_BENEFITS: {
          ...TermSlice.getInitialState().RIDERS_BENEFITS,
          riders_benefits_response: {
            ...TermSlice.getInitialState().RIDERS_BENEFITS
              .riders_benefits_response,
            finalPremium: premium_details.premium,
          },
          local_value: {
            ...TermSlice.getInitialState().RIDERS_BENEFITS.local_value,
            tf001Value:
              manipulated_selected_quote_data?.companyDetails?.company_code ===
              "L007"
                ? "500000"
                : "10000",
          },
        },
        CUS_TYPE: !isEmpty(cus_type) ? true : false,
      };

      if (redirected) {
        term_slice = { ...term_slice, REDIRECTED: redirected };
      }

      dispatch(TermSlice.actions.BULK_UPDATE({ ...term_slice }));
    } catch (e) {
      console.log("SET_TERM_DATA", e);
    }
  };

  const SET_CAR_DATA = (response: any) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    const cus_type = params.get("cus_type");

    const redirected: boolean = params.get("redirected") === "true";

    // if (
    //   window.location.pathname !==
    //   `/proposal${CAR_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}`
    // ) {
    //   dispatch(
    //     CKYCSlice.actions.BULK_UPDATE({ ...CKYCSlice.getInitialState() })
    //   );
    // }
    // if (
    //   window.location.pathname !==
    //     `/proposal${CAR_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}` &&
    //   window.location.pathname !==
    //     `/proposal${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.OWNER_DETAILS}`
    // ) {
    //   return;
    // }
    try {
      const form_data = response.insurance;
      const manipulated_data_add_form: TCarAddForm = {
        utm_medium_d: response?.utmDetails?.utm_medium_d,
        invoice_date: { value: "", warning: false },
        previous_policy_type: { value: "SAOD", warning: false },
        name: { value: "", warning: false },
        business_type: form_data.business_type,
        claimed: form_data.claim_made as TClaimStatus,
        make_model: {
          value: form_data.make_model,
          warning: false,
        },
        pincode: {
          value: form_data.pincode,
          warning: false,
        },
        fuel_type: {
          value: form_data.fuel_type,
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: { value: form_data.mobile, warning: false },
        model: { value: "", warning: false },
        prev_ncb: form_data.previous_ncb as TNCB,
        previous_insurer: { value: form_data.previous_insurer, warning: false },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data.registration_date),
          warning: false,
        },
        reg_no: { value: form_data.registration_no, warning: false },
        variant_cc: {
          value: form_data.variant_cc,
          warning: false,
        },
        rto: { value: form_data.rto_code, warning: false },
        new_ncb: { value: form_data.new_ncb, warning: false },
        policy_expiry_date: {
          value:
            form_data.business_type === "New"
              ? ""
              : FORMAT_DATE_DD_MM_YYYY(form_data.policy_expiry_date),
          warning: false,
        },
      };

      const manipulated_owner_details: TCarOwnerDetails = {
        ...CarSlice.getInitialState().OWNER_DETAILS,
        mobile: { value: form_data.mobile, warning: false },
      };

      const manipulated_addon_status: TCarAddonStatus = {
        depreciation_cover: {
          label: "Zero Depreciation",
          value: form_data.depreciation_cover === 1,
        },
        pa_owner_cover: {
          label: "Personal Accident Cover",
          value: form_data.pa_owner_driver === 1,
        },
        personalBelonging: {
          label: "Personal Belonging",
          value: form_data?.personal_belonging === 1,
        },
        rsa: {
          label: "24x7 Roadside Assistance",
          value: form_data.road_side_assistance === 1,
        },
        driver_cover: {
          label: "Driver Cover",
          value: form_data.driver_cover === 1,
        },
        consumable_cover: {
          label: "Consumable Cover",
          value: form_data.consumables_cover === 1,
        },
        engine_cover: {
          label: "Engine Cover",
          value: form_data.engine_gear_box_protection === 1,
        },
        invoice_cover: {
          label: "Invoice Cover",
          value: form_data.invoice_cover === 1,
        },
        key_replacement: {
          label: "Key Replacement",
          value: form_data.key_replacement_cover === 1,
        },
        ncb_cover: {
          label: "NCB Cover",
          value: form_data.no_claim_bonus_same_slab === 1,
        },
        tyre_cover: {
          label: "Tyre Cover",
          value: form_data.tyre_secure_cover === 1,
        },
        passenger_cover: {
          label: "Passenger Cover (₹)",
          value: form_data.passernger_cover > 0,
        },
        electrical: {
          label: "Electrical (₹)",
          value: form_data.electric_accessory > 0,
        },
        non_electrical: {
          label: "Non-Electrical (₹)",
          value: form_data.non_electric_accessory > 0,
        },
        cng_kit: { label: "CNG Kit (₹)", value: form_data.cng_kit > 0 },
      };

      const manipulated_data_discount: TCarDiscount = {
        anti_theft_device: "No",
        deductible: "0",
        vehicle_owned: response.vehicle_owned,
      };

      const manipulated_selected_quote_data: TCarSingleQuoteDetail = {
        cashlessGarageLists: [],
        CompanyDetails: response.companyDetails,
        productDetails: response.productDetails,
        premiumDetails: response.jsonData,
      };

      const manipulated_ADDON_USER_VALUE: TAddonsValueType = {
        cngKitValue: { value: form_data.cng_kit, warning: false },
        electricalAcessories: {
          value: form_data.electric_accessory,
          warning: false,
        },
        idv: { value: form_data.idv, warning: false },
        nonElectricalAcessories: {
          value: form_data.non_electric_accessory,
          warning: false,
        },
        passengerCover: { value: form_data.passernger_cover, warning: false },
      };

      const set_data_in_redux = (engine_no: string, chassis_no: string) => {
        setLoader(false);

        let car_slice: TCarSlice = {
          ...CarSlice.getInitialState(),
          ADD_FORM: {
            ...CarSlice.getInitialState().ADD_FORM,
            ...manipulated_data_add_form,
          },
          ADD_FORM_RESPONSE: {
            ...CarSlice.getInitialState().ADD_FORM_RESPONSE,
            quote_no: response.insurance.quote_no,
            utm_medium: response?.utmDetails?.utm_medium,
            utm_source: response?.utmDetails?.utm_source,
          },
          DISCOUNT_FORM: manipulated_data_discount,
          SELECTED_QUOTE_DATA: {
            ...CarSlice.getInitialState().SELECTED_QUOTE_DATA,
            quotationDetail: manipulated_selected_quote_data,
          },
          OWNER_DETAILS: {
            ...manipulated_owner_details,
          },
          VEHICLE_DETAILS: {
            ...CarSlice.getInitialState().VEHICLE_DETAILS,
            chasis_no: { value: engine_no, warning: false },
            engine_no: { value: chassis_no, warning: false },
            reg_date: {
              value: FORMAT_DATE_DD_MM_YYYY(form_data.registration_date),
              warning: false,
            },
            mfg_year: {
              value: FORMAT_DATE_DD_MM_YYYY(form_data.manufacture_date),
              warning: false,
            },
            policy_exp_date: {
              value: FORMAT_DATE_DD_MM_YYYY(form_data.policy_expiry_date),
              warning: false,
            },
            prev_insurer: {
              value: form_data.previous_insurer,
              warning: false,
            },
            tp_insurer: {
              value: form_data.tp_previous_insurer,
              warning: false,
            },
          },
          ADDON_STATUS: {
            ...CarSlice.getInitialState().ADDON_STATUS,
            ...manipulated_addon_status,
          },
          ADDON_USER_VALUE: {
            ...CarSlice.getInitialState().ADDON_USER_VALUE,
            ...manipulated_ADDON_USER_VALUE,
          },
          PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
            manipulated_selected_quote_data.productDetails.product_code,
            response.insurance.business_type
          ),
          QUOTE_LOADER: false,
          CUS_TYPE: !isEmpty(cus_type) ? true : false,
        };

        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYCSlice.getInitialState(),
            CKYC_DETAILS: { ...response?.CKYC_DETAILS },
            VERIFY_KYC_FORM: {
              ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
              customer_type: { value: form_data.vehicle_owned, warning: false },
            },
          })
        );

        if (redirected) {
          car_slice = { ...car_slice, REDIRECTED: redirected };
        }
        dispatch(CarSlice.actions.BULK_UPDATE({ ...car_slice }));
      };

      const onSuccess = (res: any) => {
        console.log("resss", res);

        const response_fast_lane = res.response;
        const chasis_no = response_fast_lane.engine_no;
        const engine_no = response_fast_lane.chasis_no;

        set_data_in_redux(engine_no, chasis_no);
      };
      const onError = (err: any) => {
        set_data_in_redux("", "");
        if (window.location.hostname === CLIENTS.JIO) {
          setVehicledetailspro(err?.response?.data?.error === true);
        }
      };

      if (
        window.location.hostname === CLIENTS.JIO &&
        window.location.hostname === CLIENTS.AMAZE
      ) {
        set_data_in_redux("", "");
      } else {
        if (manipulated_data_add_form.business_type.toLowerCase() !== "new") {
          CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
            onSuccess,
            onError,
            manipulated_data_add_form.reg_no.value,
            "CAR"
          );
        } else {
          set_data_in_redux("", "");
        }
      }
    } catch (error) {
      // toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  const SET_CAR_PREVIEW_DATA = (response: any) => {
    try {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");
      console.log("cus_type>>", cus_type);
      const form_data = response?.insurance;
      const manipulated_data_add_form: TCarAddForm = {
        utm_medium_d: response?.utmDetails?.utm_medium_d,
        invoice_date: { value: "", warning: false },
        previous_policy_type: { value: "SAOD", warning: false },
        name: { value: "", warning: false },
        business_type: form_data?.business_type,
        claimed: form_data?.claim_made as TClaimStatus,
        make_model: {
          value: form_data?.make_model,
          warning: false,
        },
        pincode: {
          value: form_data?.pincode,
          warning: false,
        },
        fuel_type: {
          value: form_data?.fuel_type,
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: { value: form_data?.mobile, warning: false },
        model: { value: "", warning: false },
        prev_ncb: form_data?.previous_ncb as TNCB,
        previous_insurer: {
          value: form_data?.previous_insurer,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.registration_date),
          warning: false,
        },
        reg_no: { value: form_data?.registration_no, warning: false },
        variant_cc: {
          value: form_data?.variant_cc,
          warning: false,
        },
        rto: { value: form_data?.rto_code, warning: false },
        new_ncb: { value: form_data?.new_ncb, warning: false },
        policy_expiry_date: {
          value:
            form_data.business_type === "New"
              ? ""
              : FORMAT_DATE_DD_MM_YYYY(form_data?.policy_expiry_date),
          warning: false,
        },
      };

      const manipulated_data_add_form_response: TCarAddFormResponse = {
        ...CarSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: response?.insurance?.quote_no,
        utm_medium: response?.utmDetails?.utm_medium,
        utm_source: response?.utmDetails?.utm_source,
      };

      const manipulated_selected_quote_data: TCarProduct = {
        ...CarSlice.getInitialState().SELECTED_QUOTE_DATA,
        quotationDetail: {
          cashlessGarageLists: [],
          CompanyDetails: response?.companyDetails,
          productDetails: response?.productDetails,
          premiumDetails: response?.jsonData,
        },
      };

      const manipulated_data_discount: TCarDiscount = {
        anti_theft_device: "No",
        deductible: "0",
        vehicle_owned: response?.vehicle_owned,
      };

      const manipulated_addon_status: TCarAddonStatus = {
        depreciation_cover: {
          label: "Zero Depreciation",
          value: form_data?.depreciation_cover === 1,
        },
        pa_owner_cover: {
          label: "Personal Accident Cover",
          value: form_data?.pa_owner_driver === 1,
        },
        personalBelonging: {
          label: "Personal Belonging",
          value: form_data?.personal_belonging === 1,
        },
        rsa: {
          label: "24x7 Roadside Assistance",
          value: form_data?.road_side_assistance === 1,
        },
        driver_cover: {
          label: "Driver Cover",
          value: form_data?.driver_cover === 1,
        },
        consumable_cover: {
          label: "Consumable Cover",
          value: form_data?.consumables_cover === 1,
        },
        engine_cover: {
          label: "Engine Cover",
          value: form_data?.engine_gear_box_protection === 1,
        },
        invoice_cover: {
          label: "Invoice Cover",
          value: form_data?.invoice_cover === 1,
        },
        key_replacement: {
          label: "Key Replacement",
          value: form_data?.key_replacement_cover === 1,
        },
        ncb_cover: {
          label: "NCB Cover",
          value: form_data?.no_claim_bonus_same_slab === 1,
        },
        tyre_cover: {
          label: "Tyre Cover",
          value: form_data?.tyre_secure_cover === 1,
        },
        passenger_cover: {
          label: "Passenger Cover (₹)",
          value: form_data?.passernger_cover > 0,
        },
        electrical: {
          label: "Electrical (₹)",
          value: form_data?.electric_accessory > 0,
        },
        non_electrical: {
          label: "Non-Electrical (₹)",
          value: form_data?.non_electric_accessory > 0,
        },
        cng_kit: { label: "CNG Kit (₹)", value: form_data?.cng_kit > 0 },
      };

      const manipulated_ADDON_USER_VALUE: TAddonsValueType = {
        cngKitValue: { value: form_data?.cng_kit, warning: false },
        electricalAcessories: {
          value: form_data?.electric_accessory,
          warning: false,
        },
        idv: { value: form_data?.idv, warning: false },
        nonElectricalAcessories: {
          value: form_data?.non_electric_accessory,
          warning: false,
        },
        passengerCover: { value: form_data?.passernger_cover, warning: false },
      };

      const proposal_data = response?.praposal;
      const CKYC_DETAILS = response?.CKYC_DETAILS;
      const utmData = response?.utmDetails;
      const manipulated_owner_details: TCarOwnerDetails = {
        ...CarSlice.getInitialState().OWNER_DETAILS,
        name: {
          value:
            proposal_data?.customer_name ||
            CKYC_DETAILS?.data?.name ||
            CKYC_DETAILS.kycCustomerName ||
            CKYC_DETAILS?.kyc_details?.full_name,
          warning: false,
        },
        // dob: { value: proposal_data?.customer_dob, warning: false },
        dob: {
          value: CKYC_DETAILS?.kyc_details?.dateOfBirth
            ? CKYC_DETAILS?.kyc_details?.dateOfBirth
            : response?.praposal?.customer_dob ||
              CKYC_DETAILS?.data?.dob ||
              CKYC_DETAILS.kycDOB ||
              CKYC_DETAILS?.kyc_details?.dob,
          warning: false,
        },
        email: { value: proposal_data?.customer_email, warning: false },
        gender: { value: proposal_data?.customer_gender, warning: false },
        gst: { value: proposal_data?.customer_gst_no, warning: false },
        maritalStatus: {
          value: proposal_data?.customer_marital,
          warning: false,
        },
        mobile: {
          value: proposal_data?.customer_mobile || utmData?.mobile,
          warning: false,
        },
      };

      const vehicle_data = response?.VEHICLE_DETAILS;
      const Insurance = response?.insurance;
      const manipulated_vehicle_details: TCarVehicleDetails = {
        ...CarSlice.getInitialState().VEHICLE_DETAILS,
        bank_name: { value: vehicle_data?.bank_name, warning: false },
        chasis_no: { value: vehicle_data?.chasis_no, warning: false },
        city: { value: vehicle_data?.city, warning: false },
        engine_no: { value: vehicle_data?.engine_no, warning: false },
        is_vehicle_on_loan:
          vehicle_data?.is_vehicle_on_loan === "No" ? false : true,
        mfg_year: { value: vehicle_data?.mfg_year, warning: false },
        pan_no: { value: vehicle_data?.pan_no, warning: false },
        policy_exp_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.policy_exp_date),
          warning: false,
        },
        prev_insurer: {
          value: response.insurance.previous_insurer,
          warning: false,
        },
        prev_policy_no: {
          value: proposal_data?.existing_policy_no,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.reg_date),
          warning: false,
        },
        tp_insurer: {
          value: proposal_data?.tp_existing_policy_no,
          warning: false,
        },
        tp_policy: { value: proposal_data?.tp_policy, warning: false },
        tp_policy_start: {
          value: vehicle_data?.tp_policy_start,
          warning: false,
        },
      };

      const manipulated_nominee_details: TNomineeDetails = {
        ...CarSlice.getInitialState().NOMINEE_DETAILS,
        fullName: { value: proposal_data?.nominee_name, warning: false },
        dob: {
          value: FORMAT_DATE_DD_MM_YYYY(proposal_data?.nominee_dob),
          warning: false,
        },
        relationship: {
          value: proposal_data?.nominee_relationship,
          warning: false,
        },
      };

      const manipulated_address_details: TAddressDetails = {
        ...CarSlice.getInitialState().ADDRESS_DETAILS,
        pincode: {
          value: !isEmpty(response?.CKYC_DETAILS?.data?.correspondencePincode)
            ? response?.CKYC_DETAILS?.data?.correspondencePincode
            : isEmpty(form_data.pincode)
            ? proposal_data?.address_p_pincode ||
              CKYC_DETAILS?.kyc_details?.permanent_address?.pin_code ||
              CKYC_DETAILS?.kycDetails?.pinCode
            : form_data.pincode || CKYC_DETAILS?.data?.permanentPincode,
          warning: false,
        },
        city: proposal_data?.address_p_city,
        state: proposal_data?.address_p_state,
        addressLineOne: {
          value:
            proposal_data?.address_p_house ||
            CKYC_DETAILS?.kycDetails?.address1 ||
            CKYC_DETAILS?.data?.permanentAddress1 ||
            CKYC_DETAILS?.kyc_details?.permanent_address.address_line_1,
          warning: false,
        },
        addressLineTwo: {
          value:
            proposal_data?.address_p_area ||
            CKYC_DETAILS?.kycDetails?.address2 ||
            CKYC_DETAILS?.data?.permanentAddress2 ||
            CKYC_DETAILS?.kyc_details?.permanent_address.address_line_2,
          warning: false,
        },
        isMailingAddressSame:
          proposal_data?.address_mailing_same === "No" ? false : true,
        mailingPinCode: {
          value: proposal_data?.address_m_pincode,
          warning: false,
        },
        mailingCity: proposal_data?.address_m_city,
        mailingState: proposal_data?.address_m_state,
        mailingAddressLineOne: {
          value: proposal_data?.address_m_house,
          warning: false,
        },
        mailingAddressLineTwo: {
          value: proposal_data?.address_m_area,
          warning: false,
        },
      };

      const set_data_in_redux = (engine_no: string, chassis_no: string) => {
        setLoader(false);
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYCSlice.getInitialState(),
            CKYC_DETAILS: {
              ...response?.CKYC_DETAILS,
              VERIFY_KYC_FORM: {
                ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
                customer_type: {
                  value: form_data.vehicle_owned,
                  warning: false,
                },
              },
            },
          })
        );
        dispatch(
          CarSlice.actions.BULK_UPDATE({
            ...CarSlice.getInitialState(),
            ADD_FORM: {
              ...manipulated_data_add_form,
            },
            ADD_FORM_RESPONSE: {
              ...manipulated_data_add_form_response,
            },
            DISCOUNT_FORM: manipulated_data_discount,
            SELECTED_QUOTE_DATA: {
              ...manipulated_selected_quote_data,
            },
            OWNER_DETAILS: {
              ...manipulated_owner_details,
            },
            VEHICLE_DETAILS: {
              ...manipulated_vehicle_details,
            },
            NOMINEE_DETAILS: { ...manipulated_nominee_details },
            ADDRESS_DETAILS: { ...manipulated_address_details },
            ADDON_STATUS: {
              ...manipulated_addon_status,
            },
            ADDON_USER_VALUE: {
              ...manipulated_ADDON_USER_VALUE,
            },
            PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
              `${manipulated_selected_quote_data?.quotationDetail?.productDetails.product_code}`,
              response?.insurance?.business_type
            ),
            QUOTE_LOADER: false,
            CKYC_DETAILS: { ...response?.CKYC_DETAILS },
            CUS_TYPE: !isEmpty(cus_type) ? true : false,
          })
        );
      };

      // set_data_in_redux();
      const onSuccess = (res: any) => {
        console.log("resss", res);

        const response_fast_lane = res.response;
        const chasis_no = response_fast_lane.engine_no;
        const engine_no = response_fast_lane.chasis_no;

        set_data_in_redux(engine_no, chasis_no);
      };
      const onError = (err: any) => {
        set_data_in_redux("", "");
        if (window.location.hostname === CLIENTS.JIO) {
          setVehicledetailspro(err?.response?.data?.error === true);
        }
      };

      if (
        window.location.hostname === CLIENTS.JIO &&
        window.location.hostname === CLIENTS.AMAZE
      ) {
        set_data_in_redux("", "");
      } else {
        if (manipulated_data_add_form.business_type.toLowerCase() !== "new") {
          CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
            onSuccess,
            onError,
            manipulated_data_add_form.reg_no.value,
            "CAR"
          );
        } else {
          set_data_in_redux("", "");
        }
      }
    } catch (error) {
      // toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  const SET_TW_PREVIEW_DATA = (response: any) => {
    try {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");
      console.log("cus_type>>", cus_type);
      const kycDetails = response?.CKYC_DETAILS?.kyc_details?.permanent_address;

      const form_data = response?.insurance;
      const manipulated_data_add_form: TTWForm = {
        pincode: { value: response?.insurance?.pincode, warning: false },
        utm_medium_d: response?.utmDetails?.utm_medium_d,
        invoice_date: { value: "", warning: false },
        previous_policy_type: { value: "SAOD", warning: false },
        name: { value: "", warning: false },
        business_type: form_data?.business_type,
        claimed: form_data?.claim_made as TClaimStatus,
        make_model: {
          value: form_data?.make_model,
          warning: false,
        },
        fuel_type: {
          value: form_data?.fuel_type,
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: { value: form_data?.mobile, warning: false },
        model: { value: "", warning: false },
        prev_ncb: form_data?.previous_ncb as TNCB,
        previous_insurer: {
          value: form_data?.previous_insurer,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.registration_date),
          warning: false,
        },
        reg_no: { value: form_data?.registration_no, warning: false },
        variant_cc: {
          value: form_data?.variant_cc,
          warning: false,
        },
        rto: { value: form_data?.rto_code, warning: false },
        policy_expiry_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.policy_expiry_date),
          warning: false,
        },
      };

      const manipulated_data_add_form_response: TTWAddFormResponse = {
        ...TWSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: response?.insurance?.quote_no,
        utm_medium: response?.utmDetails?.utm_medium,
        utm_source: response?.utmDetails?.utm_source,
      };

      const manipulated_selected_quote_data: TTWProduct = {
        ...TWSlice.getInitialState().SELECTED_QUOTE_DATA,
        quotationDetail: {
          cashlessGarageLists: [],
          CompanyDetails: response?.companyDetails,
          productDetails: response?.productDetails,
          premiumDetails: response?.jsonData,
        },
      };

      const manipulated_addon_status: TTWAddonStatus = {
        zero_depreciation: {
          label: "Zero Depreciation",
          value: form_data?.depreciation_cover === 1,
        },
        pa_owner_cover: {
          label: "Personal Accident Cover",
          value: form_data?.pa_owner_driver === 1,
        },
        rsa: {
          label: "24x7 Roadside Assistance",
          value: form_data?.road_side_assistance === 1,
        },
        driver_cover: {
          label: "Driver Cover",
          value: form_data?.driver_cover === 1,
        },
      };

      const proposal_data = response?.praposal;
      const ckyc_details =
        response?.CKYC_DETAILS?.kyc_details ||
        response?.CKYC_DETAILS?.kycDetails ||
        response?.CKYC_DETAILS?.data;
      const manipulated_owner_details: TOwnerDetails = {
        ...TWSlice.getInitialState().OWNER_DETAILS,
        name: {
          value: proposal_data?.customer_name || ckyc_details?.full_name,
          warning: false,
        },
        dob: {
          value:
            FORMAT_DATE_DD_MM_YYYY(proposal_data?.customer_dob) ||
            FORMAT_DATE_DD_MM_YYYY(ckyc_details?.dob),
          warning: false,
        },
        email: {
          value: proposal_data?.customer_email || ckyc_details?.email,
          warning: false,
        },
        gender: {
          value: proposal_data?.customer_gender || ckyc_details?.gender,
          warning: false,
        },
        GST: { value: proposal_data?.customer_gst_no, warning: false },
        maritalStatus: {
          value: proposal_data?.customer_marital,
          warning: false,
        },
        mobile: {
          value: proposal_data?.customer_mobile || ckyc_details?.mobile_number,
          warning: false,
        },
      };

      const vehicle_data = response?.VEHICLE_DETAILS;
      const manipulated_vehicle_details: TVehicleDetails = {
        ...TWSlice.getInitialState().VEHICLE_DETAILS,
        bank_name: { value: vehicle_data?.bank_name, warning: false },
        chasis_no: { value: vehicle_data?.chasis_no, warning: false },
        city: { value: vehicle_data?.city, warning: false },
        engine_no: { value: vehicle_data?.engine_no, warning: false },
        is_vehicle_on_loan:
          vehicle_data?.is_vehicle_on_loan === "No" ? false : true,
        mfg_year: { value: vehicle_data?.mfg_year, warning: false },
        PAN: { value: vehicle_data?.pan_no, warning: false },
        policy_exp_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.policy_exp_date),
          warning: false,
        },
        prev_insurer: {
          value: response.insurance.previous_insurer,
          warning: false,
        },
        prev_policy_no: {
          value: proposal_data?.existing_policy_no,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.reg_date),
          warning: false,
        },
        tp_insurer: {
          value: proposal_data?.tp_existing_policy_no,
          warning: false,
        },
        tp_policy: { value: proposal_data?.tp_policy, warning: false },
        tp_policy_start: {
          value: vehicle_data?.tp_policy_start,
          warning: false,
        },
      };

      const manipulated_nominee_details: TNomineeDetails = {
        ...TWSlice.getInitialState().NOMINEE_DETAILS,
        fullName: { value: proposal_data?.nominee_name, warning: false },
        dob: {
          value: FORMAT_DATE_DD_MM_YYYY(proposal_data?.nominee_dob),
          warning: false,
        },
        relationship: {
          value: proposal_data?.nominee_relationship,
          warning: false,
        },
      };

      const manipulated_address_details: TAddressDetails = {
        ...TWSlice.getInitialState().ADDRESS_DETAILS,
        pincode: {
          value:
            proposal_data?.address_p_pincode ||
            kycDetails?.pin_code ||
            ckyc_details?.pinCode ||
            ckyc_details.permanentPincode,
          warning: false,
        },
        city: proposal_data?.address_p_city || kycDetails?.city,
        state: proposal_data?.address_p_state || kycDetails?.state,
        addressLineOne: {
          value:
            proposal_data?.address_p_house ||
            kycDetails?.address_line_1 ||
            ckyc_details?.address1 ||
            ckyc_details.correspondenceAddress1,
          warning: false,
        },
        addressLineTwo: {
          value:
            proposal_data?.address_p_area ||
            kycDetails?.address_line_2 ||
            ckyc_details?.address2 ||
            ckyc_details.correspondenceAddress2,
          warning: false,
        },
        isMailingAddressSame: proposal_data?.address_mailing_same === "Yes",
        mailingPinCode: {
          value: proposal_data?.address_m_pincode,
          warning: false,
        },
        mailingCity: proposal_data?.address_m_city,
        mailingState: proposal_data?.address_m_state,
        mailingAddressLineOne: {
          value: proposal_data?.address_m_house,
          warning: false,
        },
        mailingAddressLineTwo: {
          value: proposal_data?.address_m_area,
          warning: false,
        },
      };

      const set_data_in_redux = () => {
        setLoader(false);
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYCSlice.getInitialState(),
            VERIFY_KYC_FORM: {
              ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
              customer_type: {
                value: form_data.vehicle_owned,
                warning: false,
              },
            },
            CKYC_DETAILS: {
              ...response?.CKYC_DETAILS,
            },
          })
        );
        dispatch(
          TWSlice.actions.BULK_UPDATE({
            ...TWSlice.getInitialState(),
            ADD_FORM: {
              ...manipulated_data_add_form,
            },
            IDV_VALUE: form_data?.idv,
            ADD_FORM_RESPONSE: {
              ...manipulated_data_add_form_response,
            },
            SELECTED_QUOTE_DATA: {
              ...manipulated_selected_quote_data,
            },
            OWNER_DETAILS: {
              ...manipulated_owner_details,
            },
            VEHICLE_DETAILS: {
              ...manipulated_vehicle_details,
            },
            NOMINEE_DETAILS: { ...manipulated_nominee_details },
            ADDRESS_DETAILS: { ...manipulated_address_details },
            ADDON_STATUS: {
              ...manipulated_addon_status,
            },
            PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
              `${manipulated_selected_quote_data?.quotationDetail?.productDetails.product_code}`,
              response?.insurance?.business_type
            ),
            QUOTE_LOADER: false,
            CKYC_DETAILS: { ...response?.CKYC_DETAILS },
            CUS_TYPE: !isEmpty(cus_type) ? true : false,
          })
        );
      };

      set_data_in_redux();
    } catch (error) {
      // toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  const SET_TW_DATA = (response: any) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const redirected: boolean = params.get("redirected") === "true";
    const cus_type = params.get("cus_type");

    if (
      window.location.pathname !==
      `/proposal${TW_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}`
    ) {
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYCSlice.getInitialState(),
        })
      );
    }
    // if (
    //   window.location.pathname !==
    //     `/proposal${TW_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}` &&
    //   window.location.pathname !==
    //     `/proposal${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.OWNER_DETAILS}`
    // ) {
    //   return;
    // }

    const form_data = response.insurance;
    const manipulated_data_add_form: TTWForm = {
      pincode: { value: response?.insurance?.pincode, warning: false },
      utm_medium_d: response?.utmDetails?.utm_medium_d,
      invoice_date: { value: "", warning: false },
      previous_policy_type: { value: "SAOD", warning: false },
      name: { value: "", warning: false },
      business_type: form_data.business_type,
      claimed: form_data.claim_made as TClaimStatus,
      make_model: {
        value: form_data.make_model,
        warning: false,
      },
      fuel_type: {
        value: form_data.fuel_type,
        warning: false,
      },
      make: { value: "", warning: false },
      mobile: { value: form_data.mobile, warning: false },
      model: { value: "", warning: false },
      prev_ncb: form_data.previous_ncb as TNCB,
      previous_insurer: { value: form_data.previous_insurer, warning: false },
      reg_date: {
        value: FORMAT_DATE_DD_MM_YYYY(form_data.registration_date),
        warning: false,
      },
      reg_no: { value: form_data.registration_no, warning: false },
      variant_cc: {
        value: form_data.variant_cc,
        warning: false,
      },
      rto: { value: form_data.rto_code, warning: false },
      policy_expiry_date: {
        value: FORMAT_DATE_DD_MM_YYYY(form_data.policy_expiry_date),
        warning: false,
      },
    };

    const manipulated_addon_status: TTWAddonStatus = {
      zero_depreciation: {
        label: "Zero Depreciation",
        value: form_data.depreciation_cover === 1,
      },
      pa_owner_cover: {
        label: "Personal Accident Cover",
        value: form_data.pa_owner_driver === 1,
      },
      rsa: {
        label: "24x7 Roadside Assistance",
        value: form_data.road_side_assistance === 1,
      },
      driver_cover: {
        label: "Driver Cover",
        value: form_data.driver_cover === 1,
      },
    };

    const manipulated_selected_quote_data: TTWSingleQuoteDetail = {
      cashlessGarageLists: [],
      CompanyDetails: response.companyDetails,
      productDetails: response.productDetails,
      premiumDetails: response.jsonData,
    };

    const set_data_in_redux = (engine_no: string, chassis_no: string) => {
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYCSlice.getInitialState(),
          VERIFY_KYC_FORM: {
            ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
            customer_type: { value: form_data.vehicle_owned, warning: false },
          },
        })
      );
      let tw_slice: TTWSlice = {
        ...TWSlice.getInitialState(),
        QUOTE_LOADER: false,
        ADD_FORM: {
          ...TWSlice.getInitialState().ADD_FORM,
          ...manipulated_data_add_form,
        },
        ADD_FORM_RESPONSE: {
          ...TWSlice.getInitialState().ADD_FORM_RESPONSE,
          quote_no: response.insurance.quote_no,
          utm_medium: response?.utmDetails?.utm_medium,
          utm_source: response?.utmDetails?.utm_source,
        },
        SELECTED_QUOTE_DATA: {
          ...TWSlice.getInitialState().SELECTED_QUOTE_DATA,
          quotationDetail: manipulated_selected_quote_data,
        },
        ADDON_STATUS: {
          ...TWSlice.getInitialState().ADDON_STATUS,
          ...manipulated_addon_status,
        },
        IDV_VALUE: { value: form_data.idv, warning: false },
        OWNER_DETAILS: {
          ...TWSlice.getInitialState().OWNER_DETAILS,
          mobile: { value: form_data.mobile, warning: false },
        },
        VEHICLE_DETAILS: {
          ...TWSlice.getInitialState().VEHICLE_DETAILS,
          reg_date: {
            value: FORMAT_DATE_DD_MM_YYYY(form_data.registration_date),
            warning: false,
          },
          mfg_year: {
            value: FORMAT_DATE_DD_MM_YYYY(form_data.manufacture_date),
            warning: false,
          },
          policy_exp_date: {
            value: FORMAT_DATE_DD_MM_YYYY(form_data.policy_expiry_date),
            warning: false,
          },
          prev_insurer: {
            value: form_data.previous_insurer,
            warning: false,
          },
          tp_insurer: {
            value: form_data.tp_previous_insurer,
            warning: false,
          },
          engine_no: {
            value: engine_no,
            warning: false,
          },
          chasis_no: {
            value: chassis_no,
            warning: false,
          },
        },
        PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
          manipulated_selected_quote_data.productDetails.product_code,
          response.insurance.business_type
        ),
        CUS_TYPE: !isEmpty(cus_type) ? true : false,
      };
      if (redirected) {
        tw_slice = { ...tw_slice, REDIRECTED: redirected };
      }
      dispatch(TWSlice.actions.BULK_UPDATE({ ...tw_slice }));
    };
    const onSuccess = (res: any) => {
      setLoader(false);

      const response_fast_lane = res.response;
      const chasis_no = response_fast_lane.chasis_no;
      const engine_no = response_fast_lane.engine_no;

      set_data_in_redux(engine_no, chasis_no);
    };
    const onError = (err: any) => {
      set_data_in_redux("", "");
      setLoader(false);
      if (window.location.hostname === CLIENTS.JIO) {
        setVehicledetailspro(err?.response?.data?.error === true);
      }
    };

    if (
      window.location.hostname === CLIENTS.JIO &&
      window.location.hostname === CLIENTS.AMAZE
    ) {
      set_data_in_redux("", "");
    } else {
      if (manipulated_data_add_form.business_type.toLowerCase() !== "new") {
        CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
          onSuccess,
          onError,
          manipulated_data_add_form.reg_no.value,
          "TW"
        );
      } else {
        set_data_in_redux("", "");
      }
    }
  };

  const SET_TRAVEL_DATA = (response: any) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const redirected: boolean = params.get("redirected") === "true";
    const form_data = response.insurance;
    const company_details = response.companyDetails;
    const product_details = response.productDetails;
    const premium_details = response.proposals.premium_details;

    const manipulated_data_add_form: TTravelAddForm = {
      frequency: { value: form_data.frequency, warning: false },
      travelCountries: {
        value: form_data.countries,
        warning: false,
      },
      travelStartDate: {
        value: form_data.start_date,
        warning: false,
      },
      travelEndDate: {
        value: form_data.end_date,
        warning: false,
      },
      plan_type: {
        value: form_data.plan_type,
        warning: false,
      },
      travelDays: {
        value: form_data.days,
        warning: false,
      },
      travelName: {
        value: form_data.name,
        warning: false,
      },
      travelEmail: {
        value: form_data.email,
        warning: false,
      },
      travelMobile: { value: form_data.frequency, warning: false },
      travelAges: form_data.age,
      memberDetails: {
        adult1_age: {
          value: form_data.member_details.adult1_age,
          warning: false,
        },
        adult2_age: {
          value: form_data.member_details.adult2_age,
          warning: false,
        },
        child1_age: {
          value: form_data.member_details.child1_age,
          warning: false,
        },
        child2_age: {
          value: form_data.member_details.child2_age,
          warning: false,
        },
        child3_age: {
          value: form_data.member_details.child3_age,
          warning: false,
        },
        child4_age: {
          value: form_data.member_details.child4_age,
          warning: false,
        },
      },
      travel_ped: { value: form_data?.travel_ped, warning: false },
      sum_insured: { value: form_data?.sum_insured, warning: false },
    };

    const manipulated_data_add_form_response: any = {
      quote_no: form_data?.quote_no,
      utm_medium: form_data?.utm_medium,
      utm_source: form_data?.utm_source,
    };

    const manipulated_selected_quote_data: any = {
      logo: company_details.logo,
      companyCode: company_details.company_code,
      productID: product_details.id,
      sumInsured: response.insurance.sum_insured,
      QUOTE_LOADER: false,
      quote_details: {
        productDetails: product_details,
        premiumDetails: premium_details,
        companyDetails: company_details,
      },
    };

    let travel_slice: TTravelSlice = {
      ...TravelSlice.getInitialState(),
      ADD_FORM: {
        ...TravelSlice.getInitialState().ADD_FORM,
        ...manipulated_data_add_form,
      },
      ADD_FORM_RESPONSE: {
        ...manipulated_data_add_form_response,
      },
      SELECTED_QUOTE_DATA: {
        ...TravelSlice.getInitialState().SELECTED_QUOTE_DATA,
        ...manipulated_selected_quote_data,
      },
    };

    if (redirected) {
      travel_slice = { ...travel_slice, REDIRECTED: redirected };
    }
    dispatch(CKYCSlice.actions.BULK_UPDATE({ ...CKYCSlice.getInitialState() }));
    dispatch(
      TravelSlice.actions.BULK_UPDATE({
        ...travel_slice,
      })
    );
  };

  const GET_PROPOSAL_INFO = (params: {
    type: string;
    quote_no: string;
    product_id: string;
  }) => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;

      if (!error) {
        const response = results.response;
        console.log("params.type", params.type);

        switch (params.type) {
          case "TW":
            setLoader(false);
            SET_TW_DATA(response);
            break;
          case "CAR":
            SET_CAR_DATA(response);
            break;
          case "TM":
            setLoader(false);
            SET_TERM_DATA(response);
            break;
          case "HP":
            setLoader(false);
            SET_HEALTH_DATA(response);
            break;
          case "TI":
            setLoader(false);
            SET_TRAVEL_DATA(response);
            break;
          default:
            break;
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);

    CAR_SERVICES.GET_PROPOSAL_INFO(onError, onSuccess, params);
  };

  const SET_HEALTH_PREVIEW_DATA = (response: any) => {
    try {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");
      const adult1_details = response?.healthDetails?.adult1_details;
      const adult2_details = response?.healthDetails?.adult2_details;
      const proposer_details = response?.healthDetails?.proposer_details;
      const addressDetails = response?.healthDetails?.addressDetails;
      const childDetails = response?.healthDetails?.childDetails;
      const premiumDetails = response?.premiumDetails;
      const companyDetails = response?.companyDetails;
      const productDetails = response?.productDetails;
      const kycDetails = response?.kycDetails?.details;
      const healthInsurance = response?.healthInsurance;
      const bankDetails = response?.bank_details;

      const manipulated_data_add_form: THealthAddForm = {
        spouse_relationship: { value: "", warning: false },
        age: { value: healthInsurance?.age, warning: false },
        children: healthInsurance?.child,
        gender: { value: healthInsurance?.gender, warning: false },
        pincode: { value: healthInsurance?.pincode, warning: false },
        whom_to_insure: "One Adult",
        mobile: { value: healthInsurance?.mobile, warning: false },
        name: { value: healthInsurance?.name, warning: false },
        son_count: { value: "0", warning: false },
        daughter_count: { value: "0", warning: false },
        self_dob: {
          value: healthInsurance?.more_details[0]?.adult1Age,
          warning: false,
        },
        spouse_dob: {
          value: healthInsurance?.more_details[1]?.adult2Age || "",
          warning: false,
        },
        son_status: false,
        daughter_status: false,
        self_status: true,
        spouse_status:
          healthInsurance.adult === "2" || healthInsurance.adult === 2
            ? true
            : false,
        child_one_dob: {
          value: healthInsurance?.more_details[1]?.adult2Age
            ? healthInsurance?.more_details[2]?.childage_1
            : healthInsurance?.more_details[1]?.childage_1,
          warning: false,
        },
        child_two_dob: {
          value: healthInsurance?.more_details[1]?.adult2Age
            ? healthInsurance?.more_details[3]?.childage_2
            : healthInsurance?.more_details[2]?.childage_2,
          warning: false,
        },
        child_three_dob: {
          value: healthInsurance?.more_details[1]?.adult2Age
            ? healthInsurance?.more_details[4]?.childage_3
            : healthInsurance?.more_details[3]?.childage_3,
          warning: false,
        },
        child_four_dob: {
          value: healthInsurance?.more_details[1]?.adult2Age
            ? healthInsurance?.more_details[5]?.childage_4
            : healthInsurance?.more_details[4]?.childage_4,
          warning: false,
        },
      };
      const manipulated_data_add_form_response: THealthFormResponse = {
        ...HealthSlice.getInitialState().ADD_FORM_RESPONSE,
        sum_insured: 0,
        quote_no: healthInsurance?.quote_no,
        module: healthInsurance?.module,
        insurance_type: healthInsurance?.insurance_type,
        term: healthInsurance?.term,
        cover: healthInsurance?.cover,
        city: healthInsurance?.city,
        state: healthInsurance?.state,
        child: healthInsurance?.child,
        adult: healthInsurance?.adult,
        mobile: healthInsurance?.mobile,
        pincode: healthInsurance?.pincode,
        sort_by: "Low-High",
        utm_medium: healthInsurance?.utm_medium,
        utm_source: healthInsurance?.utm_source,
      };
      const manipulated_selected_quote_data: THealthQuote = {
        ...HealthSlice.getInitialState().SELECTED_QUOTE_DATA,
        CashlessHospitalLists: [],
        sumInsured: healthInsurance?.sum_insured,
        productDetails: {
          brochure: productDetails?.brochure,
          buy_online_code: productDetails?.buy_online_code,
          company_product_map: {
            company_code: productDetails?.companyProductMap?.company_code,
          },
          discount_calc_action: productDetails?.discount_calc_action,
          health_form_validation: "",
          id: productDetails?.id,
          is_online: productDetails?.is_online,
          pre_existing_cover: productDetails?.pre_existing_cover,
          prem_calc_action: productDetails?.prem_calc_action,
          product_code: productDetails?.product_code,
          product_type_code: productDetails?.product_type_code,
          product_desc: productDetails?.product_desc,
          product_name: productDetails?.product_name,
          proposal_method: productDetails?.proposal_method,
          rate_calc_method: productDetails?.rate_calc_method,
          rating: productDetails?.rating,
          room_cover: productDetails?.room_cover,
        },
        premiumDetails: {
          basePremium: premiumDetails?.prev_premium,
          finalPremium: premiumDetails?.premium,
          quoteId: premiumDetails?.quote_no,
          serviceTax: 0,
          sum_insured: premiumDetails?.sum_insured,
          term: premiumDetails?.term,
          transactionid: "",
          insurance_type: premiumDetails?.insurance_type,
        },
        SpecialFeatureLists: [
          { code: "", description: "", show_desc: 0, title: "" },
        ],
        CompanyDetails: {
          claim_ratio: companyDetails?.claim_ratio,
          company_code: companyDetails?.company_code,
          logo: companyDetails?.logo,
          name: companyDetails?.name,
          short_desc: companyDetails?.short_desc,
        },
        loading: true,
      };
      const manipulated_insured_members: INSURED_MEMBER_DETAILS = {
        ...HealthSlice.getInitialState().INSURED_MEMBER_DETAILS,
        NOMINEE__DETAILS: {
          full_name: {
            value: proposer_details?.nomineeName
              ? proposer_details?.nomineeName
              : adult1_details?.nomineeName
              ? adult1_details?.nomineeName
              : adult2_details?.spouseName,
            warning: false,
          },
          dob: {
            value: proposer_details?.nomineeDOB
              ? proposer_details?.nomineeDOB
              : adult1_details?.nomineeDOB
              ? adult1_details?.nomineeDOB
              : adult2_details?.spouseDOB || "",
            warning: false,
          },
          relationship: {
            value: proposer_details?.nomineeRelationShip
              ? proposer_details?.nomineeRelationShip
              : adult1_details?.nomineeRelationShip
              ? adult1_details?.nomineeRelationShip
              : adult2_details?.adult2Relationship,
            warning: false,
          },
          insured_member: true,
          prosper_details: true,
          localstate: false,
        },
        PROPOSER_DETAILS: {
          IS_PROPOSER_INSURED:
            proposer_details?.adult1Relationship === "Self" ||
            !proposer_details?.hasOwnProperty("adult1Relationship"),
          name: { value: proposer_details?.proposerName, warning: false },
          occupation: { value: proposer_details?.occupation, warning: false },
          dob: { value: proposer_details?.proposerDOB, warning: false },
          mobile: { value: proposer_details?.proposerMobile, warning: false },
          emergencyPhone: {
            value: proposer_details?.proposerEmergency,
            warning: false,
          },
          email: { value: proposer_details?.proposerEmail, warning: false },
          aadhar: { value: proposer_details?.aadhar_number, warning: false },
          gender: {
            value: proposer_details?.proposerGender || premiumDetails?.gender,
            warning: false,
          },
          maritalStatus: {
            value: proposer_details?.maritalStatus,
            warning: false,
          },
          weight: { value: proposer_details?.weight, warning: false },
          heightFeet: { value: proposer_details?.heightFeet, warning: false },
          heightInches: {
            value: proposer_details?.heightInches,
            warning: false,
          },
          proposer_name: {
            value: proposer_details?.proposerName,
            warning: false,
          },
          relationship: {
            value: proposer_details?.adult1Relationship,
            warning: false,
          },
          pan_card: { value: proposer_details?.proposerPanNo, warning: false },
          annual_income: {
            value: proposer_details?.ProposalAnnualIncome,
            warning: false,
          },
          qualification: {
            value: proposer_details?.qualification,
            warning: false,
          },
          member_income: { value: "", warning: false },
          grainful_income: { value: "", warning: false },
          abha_no: { value: "", warning: false },
        },
        ADULT_ONE_DETAILS: {
          name: { value: adult1_details?.insured_name, warning: false },
          occupation: {
            value: adult1_details?.occupation || "",
            warning: false,
          },
          dob: { value: adult1_details?.insured_dob || "", warning: false },
          mobile: {
            value: adult1_details?.proposerMobile || "",
            warning: false,
          },
          emergencyPhone: {
            value:
              adult1_details?.proposerEmergency ||
              adult1_details?.insured_emergency ||
              "",
            warning: false,
          },
          email: { value: adult1_details?.proposerEmail || "", warning: false },
          aadhar: {
            value: adult1_details?.aadhar_number || "",
            warning: false,
          },
          gender: {
            value:
              adult1_details?.proposerGender ||
              adult1_details?.insured_gender ||
              "",
            warning: false,
          },
          maritalStatus: {
            value: adult1_details?.maritalStatus || "",
            warning: false,
          },
          weight: { value: adult1_details?.weight || "", warning: false },
          heightFeet: {
            value: adult1_details?.heightFeet || "",
            warning: false,
          },
          heightInches: {
            value: adult1_details?.heightInches || "",
            warning: false,
          },
          proposer_name: {
            value: adult1_details?.proposerName || "",
            warning: false,
          },
          relationship: {
            value: adult1_details?.adult1Relationship || "",
            warning: false,
          },
          pan_card: {
            value:
              adult1_details?.proposerPanNo ||
              proposer_details?.proposerPanNo ||
              kycDetails?.kycPAN ||
              "",
            warning: false,
          },
          annual_income: {
            value:
              adult1_details?.ProposalAnnualIncome ||
              adult1_details?.member_income ||
              proposer_details.ProposalAnnualIncome ||
              "",
            warning: false,
          },
          qualification: {
            value: adult1_details?.qualification || "",
            warning: false,
          },
          member_income: {
            value: adult1_details?.member_income || "",
            warning: false,
          },
          grainful_income: {
            value: adult1_details?.grainful_income || "",
            warning: false,
          },
          abha_no: { value: adult1_details?.abha_no || "", warning: false },
        },
        ADULT_TWO_DETAILS: {
          name: { value: adult2_details?.spouseName || "", warning: false },
          dob: { value: adult2_details.spouseDOB || "", warning: false },
          relationship: {
            value: adult2_details?.adult2Relationship,
            warning: false,
          },
          weight: { value: adult2_details?.spouseWeight, warning: false },
          heightFeet: {
            value: adult2_details?.spouseHeightFeet,
            warning: false,
          },
          heightInches: {
            value: adult2_details?.spouseHeightInches,
            warning: false,
          },
          occupation: {
            value: adult2_details?.spouseOccupation,
            warning: false,
          },
          qualification: {
            value: adult2_details?.spouseQualification,
            warning: false,
          },
        },
        CHILD_ONE_DETAILS: {
          name: { value: childDetails?.child1_name, warning: false },
          dob: {
            value: `${childDetails?.child1_date}-${childDetails?.child1_month}-${childDetails?.child1_year}`,
            warning: false,
          },
          relationship: {
            value:
              childDetails?.child1_gender === "F"
                ? "daughter"
                : childDetails?.child1_gender === "M"
                ? "son"
                : "",
            warning: false,
          },
          weight: { value: childDetails?.child1Weight, warning: false },
          heightFeet: { value: childDetails?.child1HeightFeet, warning: false },
          heightInches: {
            value: childDetails?.child1HeightInches,
            warning: false,
          },
        },
        CHILD_TWO_DETAILS: {
          name: { value: childDetails?.child2_name, warning: false },
          dob: {
            value: `${childDetails?.child2_date}-${childDetails?.child2_month}-${childDetails?.child2_year}`,
            warning: false,
          },
          relationship: {
            value:
              childDetails?.child2_gender === "F"
                ? "daughter"
                : childDetails?.child2_gender === "M"
                ? "son"
                : "",
            warning: false,
          },
          weight: { value: childDetails?.child2Weight, warning: false },
          heightFeet: { value: childDetails?.child2HeightFeet, warning: false },
          heightInches: {
            value: childDetails?.child2HeightInches,
            warning: false,
          },
        },
        CHILD_THREE_DETAILS: {
          name: { value: childDetails?.child3_name, warning: false },
          dob: {
            value: `${childDetails?.child3_date}-${childDetails?.child3_month}-${childDetails?.child3_year}`,
            warning: false,
          },
          relationship: {
            value:
              childDetails?.child3_gender === "F"
                ? "daughter"
                : childDetails?.child3_gender === "M"
                ? "son"
                : "",
            warning: false,
          },
          weight: { value: childDetails?.child3Weight, warning: false },
          heightFeet: { value: childDetails?.child3HeightFeet, warning: false },
          heightInches: {
            value: childDetails?.child3HeightInches,
            warning: false,
          },
        },
        CHILD_FOUR_DETAILS: {
          name: { value: childDetails?.child4_name, warning: false },
          dob: {
            value: `${childDetails?.child4_date}-${childDetails?.child4_month}-${childDetails?.child4_year}`,
            warning: false,
          },
          relationship: {
            value:
              childDetails?.child4_gender === "F"
                ? "daughter"
                : childDetails?.child4_gender === "M"
                ? "son"
                : "",
            warning: false,
          },
          weight: { value: childDetails?.child4Weight, warning: false },
          heightFeet: { value: childDetails?.child4HeightFeet, warning: false },
          heightInches: {
            value: childDetails?.child4HeightInches,
            warning: false,
          },
        },
      };

      const manipulated_address_details: TAddressDetails = {
        ...HealthSlice.getInitialState().ADDRESS_DETAILS,
        pincode: {
          value:
            addressDetails?.address_p_pincode ||
            response?.healthDetails.addons_details.pincode ||
            kycDetails?.kycPincode ||
            kycDetails?.permanent_address?.pin_code ||
            kycDetails?.PermanentAddressPincode,
          warning: false,
        },
        city:
          addressDetails?.address_p_city ||
          kycDetails?.permCity ||
          kycDetails?.kycCity ||
          kycDetails?.permanent_address?.city ||
          kycDetails?.PermanentAddressCity,
        state:
          addressDetails?.address_p_state ||
          kycDetails?.permState ||
          kycDetails?.kycState ||
          kycDetails?.permanent_address?.state ||
          kycDetails?.PermanentAddressState,
        addressLineOne: {
          value:
            addressDetails?.address_p_house ||
            kycDetails?.PermanentAddress1 ||
            kycDetails?.permLine1 ||
            kycDetails?.kycAddress1 ||
            kycDetails?.permanent_address?.address_line_1,
          warning: false,
        },
        addressLineTwo: {
          value:
            addressDetails?.address_p_village ||
            kycDetails?.permLine2 ||
            kycDetails?.PermanentAddress2 ||
            kycDetails?.kycAddress2 ||
            kycDetails?.permanent_address?.address_line_2,
          warning: false,
        },
        isMailingAddressSame:
          addressDetails?.address_mailing_same === "No" ? false : true,
        mailingPinCode: {
          value: addressDetails?.address_m_pincode,
          warning: false,
        },
        mailingCity: addressDetails?.address_m_city,
        mailingState: addressDetails?.address_m_state,
        mailingAddressLineOne: {
          value: addressDetails?.address_m_house,
          warning: false,
        },
        mailingAddressLineTwo: {
          value: addressDetails?.address_m_village,
          warning: false,
        },
      };

      const manipulated_ckyc_details: TCKYCDetails = {
        ...CKYCSlice.getInitialState().CKYC_DETAILS,
        transactionId: "",
        CkycPTransaction: "",
        kycTranNumber: kycDetails?.ApplicationNo,
        kycNo: kycDetails?.CKYC_NUMBER,
        kycCustomerName:
          kycDetails?.fullName ||
          kycDetails?.name ||
          kycDetails?.kycCustomerName ||
          kycDetails?.full_name ||
          kycDetails?.FullName ||
          "",
        kycFirstName: kycDetails?.FirstName,
        kycMiddleName: kycDetails?.MiddleName,
        kycLastName: kycDetails?.LastName,
        kycDOB: kycDetails?.DOB || kycDetails?.dob || kycDetails?.kycDOB,
        kycMobile: kycDetails?.MobileNo,
        kycEmail: kycDetails?.EmailID || kycDetails?.email,
        kycPAN:
          kycDetails?.PAN_NUMBER ||
          response?.kycDetails?.getCkycEkycInputIO?.panNum ||
          kycDetails?.certificate_number,
        kycAadhaar: "",
        kycAddress1:
          kycDetails?.AddressLine1 ||
          kycDetails?.permLine1 ||
          kycDetails?.kycAddress1 ||
          kycDetails?.permanent_address?.address_line_1,
        kycAddress2:
          kycDetails?.AddressLine2 ||
          kycDetails?.permLine2 ||
          kycDetails?.kycAddress2 ||
          kycDetails?.permanent_address?.address_line_2,
        kycAddress3:
          kycDetails?.AddressLine3 ||
          kycDetails?.permLine3 ||
          kycDetails?.kycAddress3 ||
          kycDetails?.permanent_address?.address_line_3,
        kycCity:
          kycDetails?.CityName ||
          kycDetails?.permCity ||
          kycDetails?.kycCity ||
          kycDetails?.permanent_address?.city,
        kycDistrict:
          kycDetails?.District ||
          kycDetails?.permDist ||
          kycDetails?.kycDistrict ||
          kycDetails?.permanent_address?.district,
        kycState:
          kycDetails?.StateName ||
          kycDetails?.permState ||
          kycDetails?.kycState ||
          kycDetails?.permanent_address?.state,
        kycPincode:
          kycDetails?.PinCode ||
          kycDetails?.permPin ||
          kycDetails?.kycPincode ||
          kycDetails?.permanent_address?.pin_code,
        zone: "",
        poaStatus: "",
        poiStatus: "",
        kycStatus: "",
        kycManualURL: "",
        ncb_tag: kycDetails?.ncb_tag || "",
        correspondencePincode: "",
      };

      const manipulated_verify_ckyc_details: TVerifyCKYCForm = {
        section: "premium_details",
        ckyc_option: 1,
        customer_type: { value: "Individual", warning: false },
        dob: { value: manipulated_ckyc_details?.kycDOB, warning: false },
        doc_id: { value: "", warning: false },
        doc_type: { value: "", warning: false },
        full_name: { value: "", warning: false },
        gender: { value: kycDetails?.gender, warning: false },
        form_sixety: { value: "", warning: false },
        pincode: {
          value: manipulated_ckyc_details?.kycPincode,
          warning: false,
        },
        city: { value: manipulated_ckyc_details?.kycCity, warning: false },
        houseNo: { value: "", warning: false },
        area_town: { value: "", warning: false },
        income_source: { value: "", warning: false },
        Family_relationship: { value: "", warning: false },
        Occupation: { value: "", warning: false },
        imageFormSixetyName: { value: "", warning: false },
        passport: { value: "", warning: false },
        identity_doc: { value: "", warning: false },
        family_full_name: { value: "", warning: false },
        mobile: { value: kycDetails?.MobileNo, warning: false },
        email: { value: kycDetails?.EmailID, warning: false },
        toggle_switch: false,
        pep_remark: "",
      };

      const manipulated_bank_details: TBank_Details_AdityaBirla = {
        Bank_Account_No: {
          value: bankDetails?.Bank_Account_No,
          warning: false,
        },
        Bank_Account_Type: {
          value: bankDetails?.Bank_Account_Type,
          warning: false,
        },
        Bank_Name: { value: bankDetails?.Bank_Name, warning: false },
        IFSC_Code: { value: bankDetails?.IFSC_Code, warning: false },
        Branch_Name: { value: bankDetails?.Branch_Name, warning: false },
        MICR_Code: { value: bankDetails?.MICR_Code, warning: false },
      };

      const manipulated_niva_bank_details: TBank_Details_NIVABUPA = {
        BankAccountNumber: {
          value: bankDetails?.BankAccountNumber,
          warning: false,
        },
        BankAccountType: {
          value: bankDetails?.BankAccountType,
          warning: false,
        },
        BankName: { value: bankDetails?.BankName, warning: false },
        IFSCCode: { value: bankDetails?.IFSCCode, warning: false },
        CustomerBankBranch: {
          value: bankDetails?.CustomerBankBranch,
          warning: false,
        },
        BankCity: { value: bankDetails?.BankCity, warning: false },
      };

      const medicalQuestionData: any = {};

      // Check the company code first
      if (ECompanyCode.CARE === response.companyDetails.company_code) {
        medicalQuestionData.MEDICAL_QUESTION_DATA_CARE = response.react_medical;
      } else if (ECompanyCode.STAR === response.companyDetails.company_code) {
        // Check the product ID and assign accordingly
        if (response.productDetails.id === EProductId.STAR_COMP_PRODUCT_ID) {
          medicalQuestionData.MEDICAL_QUESTION_DATA_STAR =
            response.react_medical;
        } else if (
          response.productDetails.id === EProductId.STAR_FHO_PRODUCT_ID
        ) {
          medicalQuestionData.MEDICAL_QUESTION_DATA_STAR_FHO =
            response.react_medical;
        } else {
          medicalQuestionData.MEDICAL_QUESTION_DATA_STAR_RED_CARPET =
            response.react_medical;
        }
      } else if (ECompanyCode.ICIC === response.companyDetails.company_code) {
        medicalQuestionData.MEDICAL_QUESTION_DATA_ICIC = response.react_medical;
      } else if (
        ECompanyCode.ADITY_BIRLA === response.companyDetails.company_code
      ) {
        medicalQuestionData.BANK_DETAILS_ADITYABIRLA = manipulated_bank_details;
        medicalQuestionData.MEDICAL_QUESTION_DATA_ADITYA_BIRLA =
          response.react_medical;
      } else if (
        ECompanyCode.NIVA_BUPA === response.companyDetails.company_code
      ) {
        medicalQuestionData.BANK_DETAILS_NIVABUPA =
          manipulated_niva_bank_details;
        if (
          response.productDetails.id === EProductId.NIVA_BUPA_REASSURE_BRONZE ||
          response.productDetails.id ===
            EProductId.NIVA_BUPA_REASSURE_PLATINUM ||
          response.productDetails.id === EProductId.NIVA_BUPA_REASSURE_TITANIUM
        ) {
          medicalQuestionData.MEDICAL_QUESTION_DATA_NIVA_BUPA =
            response.react_medical;
        } else {
          if (
            response?.healthDetails?.medical_history &&
            Object.keys(response.healthDetails.medical_history)?.length > 0
          ) {
            medicalQuestionData.MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR =
              response.react_medical;
          }
        }
      }

      const set_data_in_redux = () => {
        setLoader(false);
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYCSlice.getInitialState(),
            CKYC_DETAILS: { ...manipulated_ckyc_details, ...kycDetails },
            VERIFY_KYC_FORM: { ...manipulated_verify_ckyc_details },
          })
        );

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...HealthSlice.getInitialState(),
            ADD_FORM: {
              ...manipulated_data_add_form,
            },
            ADD_FORM_RESPONSE: {
              ...manipulated_data_add_form_response,
            },
            CUS_TYPE: !isEmpty(cus_type),
            ADDRESS_DETAILS: { ...manipulated_address_details },
            SELECTED_QUOTE_DATA: { ...manipulated_selected_quote_data },
            INSURED_MEMBER_DETAILS: { ...manipulated_insured_members },
            ADDON_QUESTION_NIVA_BUPA: { ...GET_NIVA_BUPA_ADDON_DATA(response) },
            ADDON_DETAILS:
              ECompanyCode.CARE === response.companyDetails.company_code &&
              Object.keys(response.healthDetails.addons_details)?.length > 0
                ? { ...GET_CARE_ADDON_DATA(response) }
                : { ...GET_HDFC_ADDON_DATA(response) },
            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE: {
              ...GET_MANIPAL_CIGNA_PRIME_ADVANTAGE_ADDON_DATA(response),
            },
            ADDON_DETAILS_CIGNA_PRIME_PROTECT: {
              ...GET_MANIPAL_CIGNA_PRIME_PROTECT_ADDON_DATA(response),
            },
            ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL: {
              ...GET_MANIPAL_CIGNA_LIFETIME_GLOBAL_ADDON_DATA(response),
            },
            ADDON_DETAILS_CIGNA_LIFETIME_INDIA: {
              ...GET_MANIPAL_CIGNA_LIFETIME_INDIA_ADDON_DATA(response),
            },
            ADDON_DETAILS_CIGNA_PRIME_ACTIVE: {
              ...GET_MANIPAL_CIGNA_PRIME_ACTIVE_ADDON_DATA(response),
            },
            ADDON_DETAILS_ADITYA_BIRLA: {
              ...GET_ADITYA_BIRLA_ADDON_DATA(response),
            },
            ADDON_DETAILS_ICIC: {
              ...GET_ICICI_ADDON_DATA(response),
            },
            ADDON_DETAILS_GOLDEN_SHIELD_ICICI: {
              ...GET_ICICI_ADDON_GOLDEN_DATA(response),
            },
            // MEDICAL_QUESTION_DATA_ADITYA_BIRLA: {
            //   ...GET_ADITYA_BIRLA_MEDICAL_DATA(response),
            // },
            // ADDON_QUESTION_NIVA_BUPA: {
            //   ...
            // }
            isDeduction_value:
              response?.healthDetails?.addons_details?.addon?.isDeductable,
            ...medicalQuestionData,
          })
        );
        if (
          response?.healthDetails?.medical_history &&
          Object.keys(response.healthDetails.medical_history)?.length > 0
        ) {
          dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
        }
        if (
          ECompanyCode.STAR === response.companyDetails.company_code &&
          response?.healthDetails?.medical_history &&
          Object.keys(response.healthDetails.medical_history)?.length > 0
        ) {
          dispatch(
            HealthSlice.actions.UPDATE_PAST_HEALTH_INSURANCE_VALUE(
              response?.healthDetails?.medical_history?.globalMedical
                ?.medicalOtherPolicy
            )
          );
        }
        if (
          premiumDetails?.adult === 2 &&
          Object.keys(adult1_details)?.length > 0 &&
          (adult1_details?.nomineeRelationShip === "" ||
            adult1_details?.nomineeRelationShip ===
              adult2_details?.adult2Relationship)
        ) {
          dispatch(HealthSlice.actions.CHANGE_NOMINEE_STATUS("ADULT_2"));
        }
      };

      set_data_in_redux();
    } catch (error) {
      // toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  const SET_TM_PREVIEW_DATA = (response: any) => {
    try {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");
      const addons_details = response?.termDetails?.addons_details;
      const premium_details = response?.termDetails?.premium_details;
      const proposer_details = response?.termDetails?.proposer_details;
      const premiumDetails = response?.premiumDetails;
      const companyDetails = response?.companyDetails;
      const productDetails = response?.productDetails;
      const lifeResponse = response?.lifeResponse;
      const termDetails = response?.termDetails;

      const manipulated_data_add_form: TTermAddForm = {
        annualIncome: { value: lifeResponse?.annual_income, warning: false },
        smoke: premiumDetails?.smoker,
        gender: { value: premiumDetails?.gender, warning: false },
        age: {
          value: `${premiumDetails.dob_year}-${premiumDetails.dob_month}-${premiumDetails.dob_date}`,
          warning: false,
        },
        pincode: { value: lifeResponse?.pincode, warning: false },
        name: { value: "", warning: false },
        mobile: { value: lifeResponse?.mobile, warning: false },
      };

      const manipulated_data_add_form_response: TTermAddFormResponse = {
        ...TermSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: lifeResponse?.quote_no,
        module: lifeResponse?.module,
        insurance_type: lifeResponse?.insurance_type,
        sum_assured: lifeResponse?.sum_assured,
        pincode: lifeResponse?.pincode,
        annual_income: lifeResponse?.annual_income,
        name: lifeResponse?.name,
        email: lifeResponse?.email,
        mobile: lifeResponse?.mobile,
        city: lifeResponse?.city,
        state: lifeResponse?.state,
        max_sum_assured: 180000000,
        gender: premiumDetails?.gender,
        smoker: premiumDetails?.smoker,
        term: premiumDetails?.term,
        age: premiumDetails?.age,
        dob_date: premiumDetails?.dob_date,
        dob_month: premiumDetails?.dob_month,
        dob_year: premiumDetails?.dob_year,
        cover_upto: premiumDetails?.cover_upto,
        frequency: premiumDetails?.frequency,
        income: premiumDetails?.income,
        cover_pay_upto: premiumDetails?.cover_pay_upto,
        policy_covers: premiumDetails?.policy_covers,
        monthly_payout: premiumDetails?.monthly_payout,
        income_period: premiumDetails?.income_period,
        increasing_level: 0,
        utm_medium: lifeResponse?.utm_medium,
        utm_source: lifeResponse?.utm_source,
      };

      const manipulated_selected_quote_data: TSelectedQuote = {
        ...TermSlice.getInitialState().SELECTED_QUOTE_DATA,
        documentURL: "",
        transaction_id: "",
        appno: "",
        premiumDetails: {
          income_period: premiumDetails?.income_period,
          modal_factor: "",
          product_desc: "",
          term: premiumDetails?.term,
          pay_term: premiumDetails?.pay_term,
          sum_assured: premiumDetails?.sum_assured,
          wop_rider_premium: 0,
          wop_rider_gst: 0,
          wopPremium: 0,
          ci_rider_premium: 0,
          ci_rider_gst: 0,
          ciPremium: 0,
          acc_rider_premium: 0,
          acc_rider_gst: 0,
          accPremium: 0,
          income: premiumDetails?.income,
          premium: premiumDetails?.premium,
          riders: {
            TF001: {
              sum_assured: 0,
              min_sa_percentage: 0,
              max_sa_percentage: 0,
              sa_percentage: 0,
              interval: 0,
              premium: 0,
              min_sum_assured: 0,
              max_sum_assured: 0,
              description: "",
              free: 0,
              rider_pay_term: "",
              rider_term: "",
              show_sum_insured: "",
              status: "",
            },
            TF003: {
              sum_assured: 0,
              min_sa_percentage: 0,
              max_sa_percentage: 0,
              sa_percentage: 0,
              interval: 0,
              premium: 0,
              min_sum_assured: 0,
              max_sum_assured: 0,
              description: "",
              free: 0,
              rider_pay_term: "",
              rider_term: "",
              show_sum_insured: "",
              status: "",
            },
            TF002: {
              sum_assured: 0,
              min_sa_percentage: 0,
              max_sa_percentage: 0,
              sa_percentage: 0,
              interval: 0,
              premium: 0,
              min_sum_assured: 0,
              max_sum_assured: 0,
              description: "",
              free: 0,
              rider_pay_term: "",
              rider_term: "",
              show_sum_insured: "",
              status: "",
            },
            TF004: {
              sum_assured: 0,
              min_sa_percentage: 0,
              max_sa_percentage: 0,
              sa_percentage: 0,
              interval: 0,
              premium: 0,
              min_sum_assured: 0,
              max_sum_assured: 0,
              description: "",
              free: 0,
              rider_pay_term: "",
              rider_term: "",
              show_sum_insured: "",
              status: "",
            },
            TF005: {
              sum_assured: 0,
              min_sa_percentage: 0,
              max_sa_percentage: 0,
              sa_percentage: 0,
              interval: 0,
              premium: 0,
              min_sum_assured: 0,
              max_sum_assured: 0,
              description: "",
              free: 0,
              rider_pay_term: "",
              rider_term: "",
              show_sum_insured: "",
              status: "",
            },
          },
          premiumAmount: 0,
          Total_basePremium: 0,
          Total_taxAmount: 0,
          taxAmount: "",
          finalPremium: 0,
          annunalTargetPremium: 0,
          product_id: premiumDetails?.product_id,
          product_code: premiumDetails?.product_code,
          cover_pay_upto: premiumDetails?.cover_pay_upto,
          frequency: premiumDetails?.frequency,
          smoker: premiumDetails?.smoker,
          cover_upto: premiumDetails?.cover_upto,
          policy_covers: premiumDetails?.policy_covers,
        },
        companyDetails: {
          company_code: companyDetails?.company_code,
          name: companyDetails?.name,
          short_desc: companyDetails?.short_desc,
          logo: companyDetails?.logo,
          claim_ratio: companyDetails?.claim_ratio,
        },
        SpecialFeatureLists: [],
        productDetailLists: null,
        buyOnlineLink: {
          method: "",
          buy_online_link: "",
          redirection_type: "",
        },
        productDetails: {
          id: productDetails?.id,
          product_name: productDetails?.product_name,
          brochure: productDetails?.brochure,
          product_code: productDetails?.product_code,
          rate_calc_method: productDetails?.rate_calc_method,
          proposal_method: productDetails?.proposal_method,
          tax_calc_method: productDetails?.tax_calc_method,
          discount_calc_method: productDetails?.discount_calc_method,
          rating: productDetails?.rating,
          product_desc: productDetails?.product_desc,
          buy_online_code: productDetails?.buy_online_code,
          special_feature_codes: productDetails?.special_feature_codes,
          prem_calc_action: productDetails?.prem_calc_action,
          tax_calc_action: productDetails?.tax_calc_action,
          discount_calc_action: productDetails?.discount_calc_action,
          frequency: premiumDetails?.frequency,
          term_form_validation: {
            modal_factor: 0,
            min_sum_assured: 0,
            max_sum_assured: 0,
            min_age: 0,
            max_age: 0,
            min_term: 0,
            max_term: 0,
            min_p_cess_age: 0,
            max_p_cess_age: 0,
          },
          CompanyProductMaps: {
            company_code: productDetails?.companyProductMap?.company_code,
          },
        },
        sumAssured: premiumDetails?.sum_assured,
        QuoteNo: premiumDetails?.quote_no,
      };

      const manipulated_riders_benefits: TRidersBenefits = {
        ...TermSlice.getInitialState().RIDERS_BENEFITS,
        riders_benefits_response: {
          redirectionUrl: "",
          base_rate: premium_details?.base_rate,
          modal_factor: "",
          conversion_factor: 0,
          premiumAmount: premium_details?.premiumAmount,
          finalPremium: premium_details?.finalPremium,
          pay_term: premium_details?.pay_term,
          term: premium_details?.term,
          appno: "",
          quote_id: "",
          uwtype: "",
          policyPdf: premium_details?.policyPdf,
          riders: {
            TF001: {
              sum_assured: addons_details?.TF001?.sum_assured,
              min_sa_percentage: addons_details?.TF001?.min_sa_percentage,
              max_sa_percentage: addons_details?.TF001?.max_sa_percentage,
              sa_percentage: addons_details?.TF001?.sa_percentage,
              interval: addons_details?.TF001?.interval,
              premium: addons_details?.TF001?.premium,
              min_sum_assured: addons_details?.TF001?.min_sum_assured,
              max_sum_assured: addons_details?.TF001?.max_sum_assured,
              description: addons_details?.TF001?.description,
              free: addons_details?.TF001?.free,
              rider_pay_term: addons_details?.TF001?.rider_pay_term,
              rider_term: addons_details?.TF001?.rider_term,
              show_sum_insured: addons_details?.TF001?.show_sum_insured,
              status: addons_details?.TF001?.status,
            },
            TF002: {
              sum_assured: addons_details?.TF002?.sum_assured,
              min_sa_percentage: addons_details?.TF002?.min_sa_percentage,
              max_sa_percentage: addons_details?.TF002?.max_sa_percentage,
              sa_percentage: addons_details?.TF002?.sa_percentage,
              interval: addons_details?.TF002?.interval,
              premium: addons_details?.TF002?.premium,
              min_sum_assured: addons_details?.TF002?.min_sum_assured,
              max_sum_assured: addons_details?.TF002?.max_sum_assured,
              description: addons_details?.TF002?.description,
              free: addons_details?.TF002?.free,
              rider_pay_term: addons_details?.TF002?.rider_pay_term,
              rider_term: addons_details?.TF002?.rider_term,
              show_sum_insured: addons_details?.TF002?.show_sum_insured,
              status: addons_details?.TF002?.status,
            },
            TF003: {
              sum_assured: addons_details?.TF003?.sum_assured,
              min_sa_percentage: addons_details?.TF003?.min_sa_percentage,
              max_sa_percentage: addons_details?.TF003?.max_sa_percentage,
              sa_percentage: addons_details?.TF003?.sa_percentage,
              interval: addons_details?.TF003?.interval,
              premium: addons_details?.TF003?.premium,
              min_sum_assured: addons_details?.TF003?.min_sum_assured,
              max_sum_assured: addons_details?.TF003?.max_sum_assured,
              description: addons_details?.TF003?.description,
              free: addons_details?.TF003?.free,
              rider_pay_term: addons_details?.TF003?.rider_pay_term,
              rider_term: addons_details?.TF003?.rider_term,
              show_sum_insured: addons_details?.TF003?.show_sum_insured,
              status: addons_details?.TF003?.status,
            },
            TF004: {
              sum_assured: addons_details?.TF004?.sum_assured,
              min_sa_percentage: addons_details?.TF004?.min_sa_percentage,
              max_sa_percentage: addons_details?.TF004?.max_sa_percentage,
              sa_percentage: addons_details?.TF004?.sa_percentage,
              interval: addons_details?.TF004?.interval,
              premium: addons_details?.TF004?.premium,
              min_sum_assured: addons_details?.TF004?.min_sum_assured,
              max_sum_assured: addons_details?.TF004?.max_sum_assured,
              description: addons_details?.TF004?.description,
              free: addons_details?.TF004?.free,
              rider_pay_term: addons_details?.TF004?.rider_pay_term,
              rider_term: addons_details?.TF004?.rider_term,
              show_sum_insured: addons_details?.TF004?.show_sum_insured,
              status: addons_details?.TF004?.status,
            },
            TF005: {
              sum_assured: addons_details?.TF005?.sum_assured,
              min_sa_percentage: addons_details?.TF005?.min_sa_percentage,
              max_sa_percentage: addons_details?.TF005?.max_sa_percentage,
              sa_percentage: addons_details?.TF005?.sa_percentage,
              interval: addons_details?.TF005?.interval,
              premium: addons_details?.TF005?.premium,
              min_sum_assured: addons_details?.TF005?.min_sum_assured,
              max_sum_assured: addons_details?.TF005?.max_sum_assured,
              description: addons_details?.TF005?.description,
              free: addons_details?.TF005?.free,
              rider_pay_term: addons_details?.TF005?.rider_pay_term,
              rider_term: addons_details?.TF005?.rider_term,
              show_sum_insured: addons_details?.TF005?.show_sum_insured,
              status: addons_details?.TF005?.status,
            },
          },
          quote_no: "",
          sum_insured: premium_details?.sum_assured,
          frequency: premium_details?.frequency,
        },
      };

      const manipulated_data_proposerData: TTermProposerData = {
        ...PROPOSER_DATA,
        dob: {
          value: `${premiumDetails.dob_year}-${premiumDetails.dob_month}-${premiumDetails.dob_date}`,
          warning: false,
        },
        full_name: { value: lifeResponse?.name, warning: false },
        gender: { value: premiumDetails?.gender, warning: false },
        mobile: { value: lifeResponse?.mobile, warning: false },
        pincode: { value: lifeResponse?.pincode, warning: false },
        email: { value: lifeResponse?.email, warning: false },
        sum_assured: { value: lifeResponse?.sum_assured, warning: false },
      };

      const set_data_in_redux = () => {
        setLoader(false);
        dispatch(
          TermSlice.actions.BULK_UPDATE({
            ...TermSlice.getInitialState(),
            // ...TermSlice,
            ADD_FORM: {
              ...manipulated_data_add_form,
            },
            ADD_FORM_RESPONSE: {
              ...manipulated_data_add_form_response,
            },
            SELECTED_QUOTE_DATA: { ...manipulated_selected_quote_data },
            RIDERS_BENEFITS: { ...manipulated_riders_benefits },
            PROPOSER_DATA: { ...manipulated_data_proposerData },
            CUS_TYPE: !isEmpty(cus_type) ? true : false,
            QUOTE_FIELDS_DATA: {
              ...TermSlice.getInitialState().QUOTE_FIELDS_DATA,
              sumAssured: lifeResponse?.sum_assured,
              payMode: premiumDetails?.frequency,
            },
          })
        );
      };

      set_data_in_redux();
    } catch (error) {
      // toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  const GET_PREVIEW_DATA = (params: {
    type: string;
    quote_no: string;
    companyCode: string;
    product_id: string;
  }) => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;
      setLoader(false);
      if (!error) {
        const response = results.response;
        switch (params.type) {
          case "TW":
            SET_TW_PREVIEW_DATA(response);
            break;
          case "CAR":
            SET_CAR_PREVIEW_DATA(response);
            break;
          case "HP":
            SET_HEALTH_PREVIEW_DATA(response);
            break;
          case "TM":
            SET_TM_PREVIEW_DATA(response);
            break;
          default:
            break;
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);

    CAR_SERVICES.GET_PREVIEW_DATA(onError, onSuccess, params);
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timer); // Clean up the timeout if the component unmounts
  }, []);

  //   useEffect(() => {
  //   if (vehicledetailspro) {
  //     //  window.location.href = '/';
  //     <VehicleDetailsAreIncorrect/>
  //   }
  // }, [vehicledetailspro]);

  return (
    <>
      {loader ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            height: "100vh",
            width: "100%",
          }}>
          <CircularProgress />
          <p>Please wait...</p>
        </Box>
      ) : isVisible ? (
        <BrowserRouter basename="/proposal">
          <CommonRoutes />
          {vehicledetailspro ? (
            <VehicleDetailsAreIncorrect />
          ) : (
            <>
              <CarRoutes />
              <TWRoutes />
            </>
          )}
          <HIRoutes />
          <TermRoutes />
          <HealthRoutes />
          <TravelRoutes />
        </BrowserRouter>
      ) : null}
    </>
  );
}

export default App;
