import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const HealthURLs = {
  ADD_HEALTH_FORM: `${PHP_DOMAIN}/api/health-insurances/add.json`,

  // quotation production api
  GET_QUOTATION_BY_QUOTE_ID: `${PHP_DOMAIN}/api/HealthInsurances/get-quotes.json`,

  // quotation dev api
  //GET_QUOTATION_BY_QUOTE_ID: `${PHP_DOMAIN}/api/health-insurances/get-quotes.json`,
  UPDATE_HEALTH_TENURE: `${PHP_DOMAIN}/api/health-insurances/updateTenure.json`,
  UPDATE_DETAIL: `${PHP_DOMAIN}/api/health-insurances/update-record.json`,
  UPDATE_SUMINSURED: `${PHP_DOMAIN}/api/health-insurances/update-sum-insured.json`,
  SEND_EMAIL_QUOTATION: `${PHP_DOMAIN}/api/health-insurances/send-email-quote-page.json`,
  GET_NETWORK_HOSPITAL_LIST: `${PHP_DOMAIN}/api/cashless-hospital-details/get-lists.json`,
  COMPARE_QUOTATION: `${PHP_DOMAIN}/api/health-insurances/compare-product.json`,
  QUOTE_BY_ACTION: `${PHP_DOMAIN}/api/buy-action-details/add.json`,
  GET_PREIUM_BY_ADDON: `${PHP_DOMAIN}/api/religare-healths/get-addons-premium.json`,
  GET_MARITAL_DATA: `${PHP_DOMAIN}/api/product-marital-status-maps/get-list.json`,
  GET_OCCUPATION_DATA: `${PHP_DOMAIN}/api/product-occupation-maps/get-list.json`,
  GET_RELATION_DATA: `${PHP_DOMAIN}/api/product-nominee-relationship-maps/get-adult-relationship-list.json`,
  NOMINEE_RELATION_API: `${PHP_DOMAIN}/api/product-nominee-relationship-maps/get-lists.json`,
  KYC_SUBMIT: `${PHP_DOMAIN}/api/ckyc-ekyc-details/go-digit.json`,
  MANUAL_KYC_SUBMIT: `${PHP_DOMAIN}/api/ckyc-ekyc-details/go-digit-upload-doc.json`,
  KYC_SUBMIT_IIFCO: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iffco.json`,
  MANUAL_KYC_SUBMIT_IIFCO: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iffco-upload-doc.json`,
  saveQuotesForPickUpWhereYouLeft: `${PHP_DOMAIN}/api/lowest-premium-details/add.json`,
  Capture_Record: `${PHP_DOMAIN}/api/health-proposals/captureRecord.json`,
  MAKE_PAYMENT: `${PHP_DOMAIN}/api/health-proposals/create-proposal.json`, //api/health-proposals/create-proposal.json
  MEDICAL_DATA: `${PHP_DOMAIN}/api/health-proposals/add.json`,
  GET_ADDON_PREMIUM_CARE_SUPREME: `${PHP_DOMAIN}/api/religare-healths/getAddonSupremePremium.json`,
  GET_ADDON_PREMIUM_CARE_SENIOR: `${PHP_DOMAIN}/api/religare-healths/getSeniorAddonsPremium.json`,
  GET_ADDON_PREMIUM_CARE_HEART: `${PHP_DOMAIN}/api/religare-healths/getHeartAddonsPremium.json`,
  CIS_URL: `${PHP_DOMAIN}/api/hdfc-optimas/cisDownload.json`,
  LOAD_QUOTATION_PWYL_HEALTH: (page: string) =>
    `${PHP_DOMAIN}/api/${page}-insurances/get-detail-by-quote.json`,

  SAVE_NIVA_BUPA_ADDON: `${PHP_DOMAIN}/api/MaxBupas/getAddonsPremium.json`,
  SAVE_NIVA_BUPA_ADDON_SENIOR: `${PHP_DOMAIN}/api/MaxBupas/getSeniorAddOnPremium.json`,
  //icic addons

  GET_PREIUM_BY_ADDON_ICIC: `${PHP_DOMAIN}/api/IciciV2Healths/getAddonHealthAdvantagePremium.json`,
  GET_PREIUM_BY_ADDON_RSA: `${PHP_DOMAIN}/api/royal-sundaram-v2-healths/getLifelineAddonsPremium.json`,
  GET_PREIUM_BY_ADDON_ADITYA_BIRLA: `${PHP_DOMAIN}/api/AdityaBirlas/getAddonActiveOnePremium.json`,
  GET_PREIUM_BY_ADDON_HDFC: `${PHP_DOMAIN}/api/hdfc-optimas/get-restore-addons-premium.json`,
  GET_PREIUM_BY_ADDON_HDFC_SECURE: `${PHP_DOMAIN}/api/hdfc-optimas/get-addons-premium-secure.json`,
  GET_PREIUM_BY_ADDON_GOLDEN_SHIELD_ICIC: `${PHP_DOMAIN}/api/icici-healths/getShieldAddOnPremium.json`,
  GET_PREIUM_BY_ADDON_GODIGIT: `${PHP_DOMAIN}/api/godigit-healths/getAddOnsPremium.json`,
  GET_PREIUM_BY_ADDON_BAJAJ: `${PHP_DOMAIN}/api/BajajHealths/getAddonHealthGaurdPremium.json`,
  GET_PREIUM_BY_ADDON_KOTAK: `${PHP_DOMAIN}/api/kotak-healths/getAddOnsPremium.json`,
  GET_PREIUM_BY_ADDON_CIGNA_PRIME_ADVANTAGE: `${PHP_DOMAIN}/api/cigna-hts/cignaPrimeAdvantageAddons.json`,
  GET_PREIUM_BY_ADDON_CIGNA_PRIME_ACTIVE: `${PHP_DOMAIN}/api/cigna-hts/cignaPrimeActiveAddons.json`,
  GET_PREIUM_BY_ADDON_CIGNA_PRIME_PROTECT: `${PHP_DOMAIN}/api/cigna-hts/cignaPrimeProtectAddons.json`,
  GET_PREIUM_BY_ADDON_CIGNA_LIFETIME_INDIA: `${PHP_DOMAIN}/api/cigna-hts/cignaLifeTimeIndiaAddons.json`,
  GET_PREIUM_BY_ADDON_CIGNA_LIFETIME_GLOBAL: `${PHP_DOMAIN}/api/cigna-hts/cignaLifeTimeGlobalAddons.json`,
  GENERATE_OTP: `${PHP_DOMAIN}/api/royal-sundaram-v2-healths/generate-otp-service.json`,
  VERIFY_OTP: `${PHP_DOMAIN}/api/royal-sundaram-v2-healths/validate-otp-service.json`,
  PAYMENT_LINK: `${NODE_DOMAIN}/api/v1/email/payment-link`,
  GET_MANDATORY_CARE_SUPREME_ADDON: `${PHP_DOMAIN}/api/religare-healths/getMandatorySupremeAddon.json`,
  GET_BANK_DETAILS_BY_IFSC_CODE: `${PHP_DOMAIN}/api/ckyc-ekyc-details/nivaBankDetail.json`,
  GET_SECTION_DATA: `${PHP_DOMAIN}/api/health-insurances/getSectionData.json`,
  GET_CIGNA_BANK_DETAILS: `https://ifsc.razorpay.com`,
  GET_FINAL_PREMIUM:`${PHP_DOMAIN}/api/health-insurances/getFinalPremium.json`
};
