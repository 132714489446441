import { useState } from "react";
import { useAppSelector } from "../../../../Store/hooks";
import { Box, Button, Modal, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../Component/Loader/Loader";
import CustomButton from "../../../../Component/CustomButton/CustomButton";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import {
  isEmpty,
  uploadImage,
} from "../../../../SupportingFiles/HelpingFunction";
import { TCignaManualCKYCForm } from "../../../../types/CKYC/TCKYC";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import Footer from "../../../../Component/Footer/Footer";
import SearchDropdown from "../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";
import { toast } from "react-toastify";
const CIGNAManualKyc = ({
  isLoading,
  showCKYCDetailsPopup,
  setShowCKYCDetailsPopup,
  showManualPopup,
  setShowManualPopup,
  updateMasterState,
  formDetails,
  validateForm,
  CONTINUE_TO_PROPOSAL,
}: {
  isLoading: boolean;
  showCKYCDetailsPopup: boolean;
  setShowCKYCDetailsPopup: Function;
  showManualPopup: boolean;
  setShowManualPopup: Function;
  updateMasterState: Function;
  formDetails: TCignaManualCKYCForm;
  validateForm: Function;
  CONTINUE_TO_PROPOSAL: Function;
}) => {
  const navigate = useNavigate();
  const { POI } = useAppSelector((state) => state.CommonDropdownData);
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);

  const [imageName, setImageName] = useState<{
    identityDocument: string;
    addDocument: string;
    formsixty: string;
    customerPhoto: string;
  }>({
    identityDocument: "",
    addDocument: "",
    formsixty: "",
    customerPhoto: "",
  });
  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <Modal open={showCKYCDetailsPopup} className="modalWrapper">
          <Box className="modalContent xl-width kycPopups">
            <Grid container spacing={2}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-2">CKYC Details</h4>
                <p className="grey_p">
                  We fetch these details as per record, please verify
                </p>
                <Link
                  to={""}
                  className="close-button"
                  onClick={() => {
                    setShowCKYCDetailsPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12} className="">
                {/* <span className="policy_number_box my-4">
                  {CKYC_DETAILS.kycNo}
                </span> */}
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={6}>
                <h6>
                  Full Name:
                  <span>{CKYC_DETAILS.kycCustomerName}</span>
                </h6>
              </Grid>
              <Grid xs={6}>
                <h6>
                  DOB:
                  <span>{CKYC_DETAILS.kycDOB}</span>
                </h6>
              </Grid>
              {/* <Grid xs={12}>
                <h6>
                  Address:
                  <span>{`${CKYC_DETAILS.kycAddress1}, ${CKYC_DETAILS.kycAddress2}, ${CKYC_DETAILS.kycAddress3}, ${CKYC_DETAILS.kycCity}, ${CKYC_DETAILS.kycState}, ${CKYC_DETAILS.kycPincode}`}</span>
                </h6>
              </Grid> */}
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} marginTop={"24px"}>
              {/* <Grid xs={6} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  onClickFunction={() => {
                    setShowCKYCDetailsPopup(false);
                  }}
                  text_name={
                    <>
                      <SentimentDissatisfiedOutlinedIcon className="mr-2" />{" "}
                      It's not me
                    </>
                  }
                  class_name="redMdBtn"
                />
              </Grid> */}
              <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  text_name={"Continue"}
                  class_name="regularPrimaryBtn"
                  onClickFunction={() => {
                    CONTINUE_TO_PROPOSAL();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={showManualPopup} className="modalWrapper ">
          <Box className="modalContent xl-width kycPopups">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-5">CKYC Details</h4>
                <Link
                  to="#"
                  className="close-button"
                  onClick={() => {
                    setShowManualPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <p>
                  Sorry,we are not getting any information from insurer side,
                  please click below Button to upload or verify with other
                  Document
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn my-4">
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={6} className="ctaBtn">
                <CustomButton
                  text_name={"Verify with other Document"}
                  class_name="mediumSecondaryBtn"
                  onClickFunction={() => {
                    setShowManualPopup(false);
                  }}
                />
              </Grid>
              <Grid xs={6} style={{ textAlign: "right" }} className="ctaBtn">
                <CustomButton
                  text_name={"Manual KYC"}
                  class_name="mediumPrimaryBtn"
                  onClickFunction={() => {
                    setShowManualPopup(false);
                    navigate(
                      `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.MANUAL_CKYC}?type=HP`
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn my-4">
                <hr />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <h5 className="sectionTitle">CKYC/eKYC Details</h5>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h6 className="mb-4 question">Provide CKYC Details for Proposer</h6>
            <p className="textGrey ckyc_p">
              <span className="textBold">
                CKYC refers to Central KYC (Know Your Customer)
              </span>
              , an initiative of the Government of India. The aim of this
              initiative is to have a structure in place which allows investors
              to complete their KYC only once before interacting with various
              entities across the financial sector.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Proposer Full Name"}
              value={formDetails.kycCusName.value}
              attrName={"kycCusName"}
              value_update={updateMasterState}
              warn_status={formDetails.kycCusName.warning}
              validation_type="NAME"
              error_message={
                isEmpty(formDetails?.kycCusName.value)
                  ? "Enter Full Name"
                  : "Enter Valid Full Name"
              }
            />
          </Grid>
          <Grid xs={6}>
            <DatePicker
              class_name="inputField"
              title={"Proposer DOB"}
              value={formDetails.dobForKyc.value}
              attrName={"dobForKyc"}
              value_update={updateMasterState}
              error_message="Select DOB"
              warn_status={formDetails.dobForKyc.warning}
              date_validation_type="YEARS"
              min_date={100}
              max_date={18}
              default_date={subYears(new Date(), 18)}
            />
          </Grid>

          <Grid xs={6}>
            <SearchDropdown
              class_name="inputField"
              title="Document For Identification"
              value={formDetails.idenDocNameForKyc.value}
              attrName={"idenDocNameForKyc"}
              value_update={updateMasterState}
              data={POI}
              warn_status={formDetails.idenDocNameForKyc.warning}
              error_message="Select Document For Identification"
            />
          </Grid>
          <Grid xs={6}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Identity Document"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.identityDocument}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse{" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e?.target?.files[0];
                    const fileSizeInMB = file?.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        identityDocument: file?.name,
                      }));
                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState("identityDocument", base64String);
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.identityDocument.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={6}>
            <SearchDropdown
              class_name="inputField"
              title="Document For Address"
              value={formDetails.docAddNameForKyc.value}
              attrName={"docAddNameForKyc"}
              value_update={updateMasterState}
              data={POI}
              warn_status={formDetails.docAddNameForKyc.warning}
              error_message="Select Document For Verification"
            />
          </Grid>
          <Grid xs={6}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Address Document"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.addDocument}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e?.target?.files[0];
                    const fileSizeInMB = file?.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        addDocument: file?.name,
                      }));
                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState("addDocument", base64String);
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.addDocument.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>

          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Form 60 ID"}
              value={formDetails.formsixtyNoForKyc.value}
              attrName={"formsixtyNoForKyc"}
              value_update={updateMasterState}
              warn_status={formDetails.formsixtyNoForKyc.warning}
              validation_type="ALPHANUMERIC"
              max_length={50}
              error_message="Enter Document ID Number"
            />
          </Grid>

          <Grid xs={6}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Form 60"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.formsixty}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse{" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    const fileSizeInMB = file?.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        formsixty: file?.name,
                      }));

                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState("formsixty", base64String);
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.formsixty.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>

          <Grid xs={6}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Passport Size Photo"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.customerPhoto}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse{" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    const fileSizeInMB = file?.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        customerPhoto: file?.name,
                      }));
                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState("customerPhoto", base64String);
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.customerPhoto.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>
        </Grid>

        <Footer
          loader={isLoading}
          attrName={"pageStatus"}
          forward={validateForm}
          backward={() => {
            navigate(-1);
          }}
        />
      </Box>
    </>
  );
};

export default CIGNAManualKyc;
