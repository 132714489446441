import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../Services/Enum/EHome";
import { TBajajMedicalQuestion } from "../../../../types/Health/HealthQuotation/TBajajMedicalQuestion";
import { FIND_LABEL_OF_VALUE } from "../../../../SupportingFiles/HelpingFunction";
import { ForShowOnPreviewPage } from "../../../../Container/Health/CIGNA/MedicalDropdownValue";

const MedicalDetailPreviewBoxBajaj = () => {
  const { MEDICAL_QUESTION_DATA_BAJAJ, SELECTED_QUOTE_DATA,ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Health
  );
  const navigate = useNavigate();
  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TBajajMedicalQuestion>(MEDICAL_QUESTION_DATA_BAJAJ);
  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;
  let founds = false;

  // Check if any main question's member has selection_status set to true
  Object.values(medicalQuestionData).forEach((main_data: any) => {
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.member_data
    ) {
      Object.values(main_data.main_question.member_data).forEach(
        (member_data: any) => {
          if (member_data && member_data.selection_status) {
            founds = true;
            return; // Break out of the loop
          }
        }
      );
    }
  });

  // Check if any sub-question's member has selection_status set to true and the sub-question's status is true
  Object.values(medicalQuestionData).forEach((main_data: any) => {
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.sub_question
    ) {
      Object.values(main_data.main_question.sub_question).forEach(
        (sub_question_data: any) => {
          if (
            sub_question_data &&
            sub_question_data.status &&
            sub_question_data.member_data
          ) {
            Object.values(sub_question_data.member_data).forEach(
              (member_data: any) => {
                if (member_data && member_data.selection_status) {
                  found = true;
                  return; // Break out of the loop
                }
              }
            );
          }
        }
      );
    }
  });

  const [param, setParam] = React.useState("");
  React.useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");
    setParam(cus_type || "");
  }, []);


  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found || founds ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.MEDICAL_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${param === "user" ? "&cus_type=user" : ""}`
              );
            }}
            className="previewBoxEdit">
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.BAJAJ ? (
        <Grid xs={12}>
          {Object.values(medicalQuestionData).map((mainData: any) =>
            mainData.main_question.status ? (
              <div key={mainData.main_question.id}>
                {Object.values(mainData.main_question.sub_question || {}).some(
                  (memberData: any) => memberData.status
                ) && (
                  <>
                    <h6 className="question">{mainData.main_question.title}</h6>
                    <p className="mb-4">{mainData.main_question.description}</p>
                  </>
                )}
                <ul className="member_list">
                  {Object.values(mainData.main_question.sub_question || {}).map(
                    (subQuestionData: any) =>
                      subQuestionData.status &&
                      Object.values(subQuestionData.member_data || {}).some(
                        (memberData: any) => memberData.selection_status
                      ) && (
                        <>
                          <Grid xs={12}>
                            <h6 className="question">
                              {subQuestionData.title}
                            </h6>
                          </Grid>
                          <ul className="member_list">
                            {Object.values(
                              subQuestionData.member_data || {}
                            ).map(
                              (memberData: any) =>
                                memberData.selection_status && (
                                  <li key={memberData.id}>
                                    <div
                                      className={`${memberData.image_class} illness`}
                                    />
                                    <p className="member_name">
                                      {memberData.name}
                                    </p>
                                    {memberData.field_data &&
                                      Object.values(memberData.field_data).map(
                                        (fieldData: any) => {
                                          const fieldValue = [
                                            "Month",   
                                            "Year", 
                                          ].includes(fieldData.title)
                                            ? fieldData.value.value
                                            : FIND_LABEL_OF_VALUE(
                                                ForShowOnPreviewPage,
                                                fieldData.value.value
                                              );
                                          return (
                                            <p key={fieldData.id}>
                                              {`${fieldData.title}: ${fieldValue}`}
                                            </p>
                                          );
                                        }
                                      )}
                                  </li>
                                )
                            )}
                          </ul>
                        </>
                      )
                  )}
                </ul>
              </div>
            ) : null
          )}

          {Object.values(medicalQuestionData).map((mainData: any) =>
            mainData.main_question.status ? (
              <div key={mainData.main_question.id}>
                {Object.values(mainData.main_question.member_data || {}).some(
                  (memberData: any) => memberData.selection_status
                ) && (
                  <>
                    <h6 className="question">{mainData.main_question.title}</h6>
                    <p className="mb-4">{mainData.main_question.description}</p>
                  </>
                )}
                <ul className="member_list">
                  {Object.values(mainData.main_question.member_data || {}).map(
                    (memberData: any) =>
                      memberData.selection_status && (
                        <li key={memberData.id}>
                          <div
                            className={`${memberData.image_class} illness`}></div>
                          <p className="member_name">{memberData.name}</p>
                          <p className="member_name">
                            {memberData.field_data &&
                              Object.values(memberData.field_data).map(
                                (fieldData: any) => (
                                  <p key={fieldData.id}>
                                    {/* {`${fieldData.title}: ${
                                      Array.isArray(fieldData.value.value)
                                        ? getLabelsByValues(
                                            fieldData.value.value
                                          )
                                        : getLabelByValue(fieldData.value.value)
                                    }`} */}
                                    cfnb
                                  </p>
                                )
                              )}
                          </p>
                        </li>
                      )
                  )}
                </ul>
              </div>
            ) : null
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MedicalDetailPreviewBoxBajaj;
