import { useState } from 'react';
import { TBank_Details_HDFC } from "../../../types/Health/THealthSlice";
import { useAppDispatch, useAppSelector } from '../../../Store/hooks';
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { isEmpty, validateIFSC } from '../../../SupportingFiles/HelpingFunction';
import { HealthSlice } from '../../../Store/Slice_Reducer/Health/HealthSlice';
import BankDetailsHDFC from '../../../Page/Desktop/Health/HDFC/BankDetails/BankDetails';
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from '../../../Router/Path/HealthRoutes';
import { HEALTH_PROPOSAL_SERVICES } from '../../../Services/HealthServices/HealthPropsalServices';

const HDFCBankDetailsContainer = () => {
  const { Health } = useAppSelector((state) => state);
  const navigate = useNavigate();
  const {
    BANK_DETAILS_HDFC,
    ADD_FORM_RESPONSE,
    ADD_FORM,
    PAGE_STATUS
  } = useAppSelector((state) => state.Health);
  const [disbaledButton, setDisbaledButton] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [Bank_Details_HDFC, SET_Bank_Details_HDFC] = useState<TBank_Details_HDFC>({
    ...BANK_DETAILS_HDFC,
  });

  const updateBankDetailHDFC = (attrName: keyof TBank_Details_HDFC, v: string) => {
    console.log(v, "prabh", Bank_Details_HDFC);
    SET_Bank_Details_HDFC((prevState) => ({
      ...prevState,
      [attrName]: {
        value: v,
        warning: false,
      }
    }));
  };

  const getCapturedRecordData = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param = {
      Name_In_Bank_Account: Bank_Details_HDFC.Name_In_Bank_Account.value,
      Bank_Account_No: Bank_Details_HDFC.Bank_Account_No.value,
      Bank_Name: Bank_Details_HDFC.Bank_Name.value,
      IFSC_Code: Bank_Details_HDFC.IFSC_Code.value,
      Cheque_No: Bank_Details_HDFC.Cheque_No.value,
      Cheque_Date: Bank_Details_HDFC.Cheque_Date.value,
      Cheque_Amount_For: Bank_Details_HDFC.Cheque_Amount_For.value,
      MICR_Code: Bank_Details_HDFC.MICR_Code.value,
      Branch_Name: Bank_Details_HDFC.Branch_Name.value,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "bank_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  }

  const validateForm = () => {
    let bank_details_hdfc = { ...Bank_Details_HDFC };
    bank_details_hdfc = {
      ...bank_details_hdfc,
      Name_In_Bank_Account: {
        ...bank_details_hdfc.Name_In_Bank_Account,
        warning: isEmpty(`${bank_details_hdfc.Name_In_Bank_Account.value} `),
      },
      Bank_Account_No: {
        ...bank_details_hdfc.Bank_Account_No,
        warning: isEmpty(`${bank_details_hdfc.Bank_Account_No.value} `),
      },
      Bank_Name: {
        ...bank_details_hdfc.Bank_Name,
        warning: isEmpty(`${bank_details_hdfc.Bank_Name.value} `),
      },
      IFSC_Code: {
        ...bank_details_hdfc.IFSC_Code,
        warning: !validateIFSC(`${bank_details_hdfc.IFSC_Code.value} `),
      },
    };

    let hasError = false;
    if (
      bank_details_hdfc.Bank_Account_No.warning ||
      bank_details_hdfc.Name_In_Bank_Account.warning ||
      bank_details_hdfc.Bank_Name.warning ||
      bank_details_hdfc.IFSC_Code.warning
    ) {
      hasError = true;
    }
    SET_Bank_Details_HDFC({ ...bank_details_hdfc });

    if (!hasError) {
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          BANK_DETAILS_HDFC: {
            DispatchFlag: bank_details_hdfc.DispatchFlag,
            Name_In_Bank_Account: bank_details_hdfc.Name_In_Bank_Account,
            Bank_Account_No: bank_details_hdfc.Bank_Account_No,
            Bank_Name: bank_details_hdfc.Bank_Name,
            IFSC_Code: bank_details_hdfc.IFSC_Code,
            Cheque_No: bank_details_hdfc.Cheque_No,
            Cheque_Date: bank_details_hdfc.Cheque_Date,
            Cheque_Amount_For: bank_details_hdfc.Cheque_Amount_For,
            MICR_Code: bank_details_hdfc.MICR_Code,
            Branch_Name: bank_details_hdfc.Branch_Name,
          },
          PAGE_STATUS: false,
        })
      );
      getCapturedRecordData()
      if (PAGE_STATUS) {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}`
        );
      } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}?type=HP&companyCode=${Health?.SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${Health?.ADD_FORM_RESPONSE.quote_no}&product_id=${Health?.SELECTED_QUOTE_DATA?.productDetails.id}`
      )
    }
    }
  }

  return isMobile ?
    null : (
      <BankDetailsHDFC
        disbaledButton={disbaledButton}
        validateForm={validateForm}
        Bank_Details_HDFC={Bank_Details_HDFC}
        updateBankDetailHDFC={updateBankDetailHDFC}
      />
    )
}

export default HDFCBankDetailsContainer