import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DTO_CIGNA_VERIFY_MANUAL_KYC } from "../../../DTO/HealthDTO/CignaVerifyManualKyc";
import CIGNAManualKyc from "../../../Page/Desktop/CKYC/Cigna/CIGNAManualKyc";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { CKYCSlice } from "../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../Store/Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TCignaManualCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import MCIGNAManualKyc from "../../../Page/Mobile/CKYC/Cigna/MCIGNAManualKyc";
const CignaManualKYCContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // slice_reducer
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const { ADD_FORM_RESPONSE,SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  const { CKYC, Health } = useAppSelector((state) => state);

  // useState
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [formDetails, setFormDetails] = useState<TCignaManualCKYCForm>({
    kycCusName: { value: "", warning: false },
    dobForKyc: { value: "", warning: false },
    idenDocNameForKyc: { value: "", warning: false },
    identityDocument: { value: "", warning: false },
    docAddNameForKyc: { value: "", warning: false },
    addDocument: { value: "", warning: false },
    formsixty: { value: "", warning: false },
    formsixtyNoForKyc: { value: "", warning: false },
    customerPhoto: { value: "", warning: false },
    customer_type: { value: "Individual", warning: false },
  });
  const isMobile = useIsMobile();

  //   documnet for verification
  useEffect(() => {
    GET_DROPDOWN_MASTER("POI");
  }, []);
  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response;
        const manupulated_data: TDropdown[] = Object.entries(response).map(
          ([key, value]) => ({
            label: `${value}`,
            value: `${key}`,
          })
        );

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => { };
    const Health_params = {
      company_code: "G015",
      list_for: TYPE,
    };
    CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, Health_params);
  };

  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "kycCusName") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "dobForKyc") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "idenDocNameForKyc") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "identityDocument") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "docAddNameForKyc") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "addDocument") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "customerPhoto") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  const validateForm = () => {
    let data: TCignaManualCKYCForm = { ...formDetails };
    data = {
      ...data,
      kycCusName: {
        ...data.kycCusName,
        warning: !validateFullName(data.kycCusName.value),
      },
      dobForKyc: {
        ...data.dobForKyc,
        warning: isEmpty(data.dobForKyc.value),
      },
      idenDocNameForKyc: {
        ...data.idenDocNameForKyc,
        warning: isEmpty(data.idenDocNameForKyc.value),
      },
      customerPhoto: {
        ...data.customerPhoto,
        warning: isEmpty(data.customerPhoto.value),
      },
      formsixtyNoForKyc: {
        ...data.formsixtyNoForKyc,
        warning: isEmpty(data.formsixtyNoForKyc.value),
      },
    };
    setFormDetails(data);
    let hasError =
      data.kycCusName.warning ||
      data.idenDocNameForKyc.warning ||
      data.identityDocument.warning ||
      data.customer_type.warning ||
      data.dobForKyc.warning ||
      data.formsixtyNoForKyc.warning;
    if (!hasError) {
      VERIFY_CKYC_DETAILS(data);
    }
  };

  const VERIFY_CKYC_DETAILS = (data: any) => {
    const onSuccess = (res: any) => {
      setIsLoading(false);
      const results = res.results;
      const error = results.error;
      if (!error) {
        const message = results.message;
        const response = results.response;
        if (message === "No record found") {
          const kycManualURL = response.kycManualURL;
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              MANUAL_KYC_THIRD_PARTY_URL: kycManualURL,
            })
          );
          setShowManualPopup(true);
        } else {
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        setShowManualPopup(true);
      }
    };

    const onError = (err: any) => { };

    let dto: DTO_CIGNA_VERIFY_MANUAL_KYC = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      customer_type: data.customer_type.value,
      kycCusName: data.kycCusName.value,
      dobForKyc: data.dobForKyc.value,
      idenDocNameForKyc: data.idenDocNameForKyc.value,
      identityDocument: data.identityDocument.value,
      docAddNameForKyc: data.docAddNameForKyc.value,
      addDocument: data.addDocument.value,
      formsixty: data.formsixty.value,
      formsixtyNoForKyc: data.formsixtyNoForKyc.value,
      customerPhoto: data.customerPhoto.value,
      section: "premium_details",
    };
    setIsLoading(true);
    CKYCServcies.VERIFY_CIGNA_MANUAL_CKYC(onSuccess, onError, dto);
  };

  const CONTINUE_TO_PROPOSAL = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const param = params.get("cus_type");
    dispatch(
      HealthSlice.actions.BULK_UPLOAD({
        ...Health,
        INSURED_MEMBER_DETAILS: {
          ...Health.INSURED_MEMBER_DETAILS,
          PROPOSER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
            name: {
              value: CKYC.CKYC_DETAILS.kycCustomerName,
              warning: false,
            },
            dob: {
              value: CKYC.CKYC_DETAILS.kycDOB,
              warning: false,
            },
            mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
            emergencyPhone: { value: "", warning: false },
            email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
            aadhar: { value: "", warning: false },
            gender: { value: VERIFY_KYC_FORM?.gender?.value ? VERIFY_KYC_FORM?.gender?.value : "", warning: false },
            maritalStatus: { value: "", warning: false },
            weight: { value: "", warning: false },
            heightFeet: { value: "", warning: false },
            heightInches: { value: "", warning: false },
            occupation: { value: "", warning: false },
            relationship: { value: "", warning: false },
            proposer_name: { value: "", warning: false },
          },
        },
        ADDRESS_DETAILS: {
          ...Health.ADDRESS_DETAILS,
          pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
          city: CKYC.CKYC_DETAILS.kycCity,
          state: CKYC.CKYC_DETAILS.kycState,
          addressLineOne: {
            value: CKYC.CKYC_DETAILS.kycAddress1,
            warning: false,
          },
          addressLineTwo: {
            value: CKYC.CKYC_DETAILS.kycAddress2,
            warning: false,
          },
          isMailingAddressSame: true,
          mailingPinCode: { value: "", warning: false },
          mailingCity: "",
          mailingState: "",
          mailingAddressLineOne: { value: "", warning: false },
          mailingAddressLineTwo: { value: "", warning: false },
        },
      })
    );
    navigate(
      // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
      `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${param === "user" ? "&cus_type=user" : ""}`
    );
  };

  return (
    <>
      {isMobile ? <MCIGNAManualKyc
        isLoading={isLoading}
        showCKYCDetailsPopup={showCKYCDetailsPopup}
        setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
        showManualPopup={showManualPopup}
        setShowManualPopup={setShowManualPopup}
        updateMasterState={updateMasterState}
        formDetails={formDetails}
        validateForm={validateForm}
        CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
      /> : (
        <CIGNAManualKyc
          isLoading={isLoading}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          setShowManualPopup={setShowManualPopup}
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
        />
      )}
    </>
  );
};

export default CignaManualKYCContainer;
