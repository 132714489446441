import { PayloadAction } from "@reduxjs/toolkit";
import {
  TTravelAddForm,
  TTravelSlice,
} from "../../../types/Travel/TTravelSlice";

function BULK_UPDATE(state: TTravelSlice, action: PayloadAction<TTravelSlice>) {
  const data: TTravelSlice = { ...state, ...action.payload };
  return data;
}

function SET_ADD_FORM_DATA(
  state: TTravelSlice,
  action: PayloadAction<TTravelAddForm>
) {
  return {
    ...state,
    ADD_FORM: { ...action.payload },
  };
}

export const TRAVEL_REDUCERS = {
  BULK_UPDATE,
  SET_ADD_FORM_DATA,
};

export type TRAVEL_REDUCERS = typeof TRAVEL_REDUCERS;
