import { useAppSelector } from "../../../../Store/hooks";
import { TAdityaBirlaMedicalQuestion } from "../../../../types/Health/HealthQuotation/TAdityaBirlaMedicalQuestion";

//Disease or disorder of eye, ear, nose or throat (except any sight related problems corrected by prescription lenses)?
const disorder_of_eye = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medicalThroatDiseases:
      data.MQ02.main_question.status === true ? "Yes" : "NO",

    medicalThroat:
      data.MQ02.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    throat_last_consultation_date:
      data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    throat_surgery_name:
      data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    throat_treatment:
      data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    throat_disability:
      data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    throat_hospitalization:
      data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    throat_other_information:
      data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    medicalThroatDiseases_name:
      data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    throat_diagnosis_date:
      data?.MQ02?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medicalThroat:
      data.MQ02.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_throat_last_consultation_date:
      data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_throat_name_Surgery:
      data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_throat_details_treatment:
      data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_throat_disability:
      data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_throat_period_hospitalization:
      data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_throat_any_other_information:
      data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_throat_disease_name:
      data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_throat_diagnosis_date:
      data?.MQ02?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medicalThroat:
      data.MQ02.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_throat_last_consultation_date:
      data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_throat_name_Surgery:
      data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_throat_details_treatment:
      data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_throat_disability:
      data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_throat_period_hospitalization:
      data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_throat_any_other_information:
      data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_throat_disease_name:
      data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_throat_diagnosis_date:
      data?.MQ02?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medicalThroat:
      data.MQ02.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_throat_last_consultation_date:
      data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_throat_name_Surgery:
      data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_throat_details_treatment:
      data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_throat_disability:
      data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_throat_period_hospitalization:
      data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_throat_any_other_information:
      data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_throat_disease_name:
      data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_throat_diagnosis_date:
      data?.MQ02?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medicalThroat:
      data.MQ02.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_throat_last_consultation_date:
      data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_throat_name_Surgery:
      data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_throat_details_treatment:
      data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_throat_disability:
      data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_throat_period_hospitalization:
      data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_throat_any_other_information:
      data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_throat_disease_name:
      data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_throat_diagnosis_date:
      data?.MQ02?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medicalThroat:
      data.MQ02.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_throat_last_consultation_date:
      data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_throat_name_Surgery:
      data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_throat_details_treatment:
      data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_throat_disability:
      data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_throat_period_hospitalization:
      data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_throat_any_other_information:
      data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_throat_disease_name:
      data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_throat_diagnosis_date:
      data?.MQ02?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

//Any other disease / health adversity / injury/ condition / treatment not mentioned above
const any_other_disease = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    injury_diseases: data.MQ03.main_question.status === true ? "Yes" : "NO",
    medical_injury:
      data.MQ03.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    injury_last_consultation_date:
      data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    injury_surgery_name:
      data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    injury_treatment:
      data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    injury_disability:
      data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    injury_hospitalization:
      data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    injury_other_information:
      data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    injury_diseases_name:
      data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    injury_diagnosis_date:
      data?.MQ03?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_injury_diseases:
      data.MQ03.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_injury_last_consultation_date:
      data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_injury_name_Surgery:
      data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_injury_treatment:
      data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_injury_disability:
      data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_injury_period_hospitalization:
      data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_injury_any_other_information:
      data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_injury_diseases_name:
      data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_injury_diagnosis_date:
      data?.MQ03?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_injury_diseases:
      data.MQ03.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_injury_last_consultation_date:
      data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_injury_name_Surgery:
      data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_injury_treatment:
      data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_injury_disability:
      data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_injury_period_hospitalization:
      data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_injury_any_other_information:
      data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_injury_diseases_name:
      data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_injury_diagnosis_date:
      data?.MQ03?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_injury_diseases:
      data.MQ03.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_injury_last_consultation_date:
      data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_injury_name_Surgery:
      data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_injury_treatment:
      data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_injury_disability:
      data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_injury_period_hospitalization:
      data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_injury_any_other_information:
      data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_injury_diseases_name:
      data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_injury_diagnosis_date:
      data?.MQ03?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_injury_diseases:
      data.MQ03.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_injury_last_consultation_date:
      data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_injury_name_Surgery:
      data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_injury_treatment:
      data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_injury_disability:
      data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_injury_period_hospitalization:
      data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_injury_any_other_information:
      data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_injury_diseases_name:
      data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_injury_diagnosis_date:
      data?.MQ03?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_injury_diseases:
      data.MQ03.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_injury_last_consultation_date:
      data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_injury_name_Surgery:
      data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_injury_treatment:
      data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_injury_disability:
      data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_injury_period_hospitalization:
      data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_injury_any_other_information:
      data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_injury_diseases_name:
      data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_injury_diagnosis_date:
      data?.MQ03?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

//Has any of the Proposed to be Insured been hospitalized/ recommended to take investigations/medication or has been under any prolonged treatment/ undergone surgery for any illness/injury other than for childbirth/minor injuries?
const hospitalized = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medication_diseases: data.MQ04.main_question.status === true ? "Yes" : "NO",

    medical_diseases:
      data.MQ04.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    medication_last_consultation_date:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    medication_surgery_name:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    medication_treatment:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    medication_disability:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    medication_hospitalization:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    medication_other_information:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    medication_diseases_name:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    medication_diagnosis_date:
      data?.MQ04?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medication_diseases:
      data.MQ04.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_medication_last_consultation_date:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_medication_name_Surgery:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_medication_treatment:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_medication_disability:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_medication_period_hospitalization:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_medication_any_other_information:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_medication_diseases_name:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_medication_diagnosis_date:
      data?.MQ04?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medication_diseases:
      data.MQ04.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_medication_last_consultation_date:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_medication_name_Surgery:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_medication_treatment:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_medication_disability:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_medication_period_hospitalization:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_medication_any_other_information:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medication_diseases_name:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_medication_diagnosis_date:
      data?.MQ04?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medication_diseases:
      data.MQ04.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_medication_last_consultation_date:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_medication_name_Surgery:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_medication_treatment:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_medication_disability:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_medication_period_hospitalization:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_medication_any_other_information:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medication_diseases_name:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_medication_diagnosis_date:
      data?.MQ04?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medication_diseases:
      data.MQ04.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_medication_last_consultation_date:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_medication_name_Surgery:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_medication_treatment:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_medication_disability:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_medication_period_hospitalization:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_medication_any_other_information:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medication_diseases_name:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_medication_diagnosis_date:
      data?.MQ04?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medication_diseases:
      data.MQ04.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_medication_last_consultation_date:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_medication_name_Surgery:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_medication_treatment:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_medication_disability:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_medication_period_hospitalization:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_medication_any_other_information:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medication_diseases_name:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_medication_diagnosis_date:
      data?.MQ04?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

//Have you ever been diagnosed with /advised / taken treatment or observation is suggested or undergone any investigation or consulted a doctor or undergone or advised surgery or hospitalized for any one or more from the following?  If YES then please mention Details in the additional information section below
const diagnosed = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medicalCancerDiseases:
      data.MQ05.main_question.status === true ? "Yes" : "NO",

    medicalCancer:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ1"].status === true
        ? "Yes"
        : "No",
    cancer_tumor_Diseases:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one
        .selection_status === true
        ? "Yes"
        : "No",
    cancer_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    cancer_surgery_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    cancer_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    cancer_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    cancer_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    cancer_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    cancer_diseases_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    cancer_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    spouse_medicalCancer:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ1"].member_data?.adult_two
        .selection_status === true
        ? "Yes"
        : "No",
    spouse_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_cancer_surgery_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_cancer_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_cancer_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    spouse_cancer_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    spouse_cancer_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_cancer_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    spouse_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    child1_medicalCancer:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ1"].member_data?.child_one
        .selection_status === true
        ? "Yes"
        : "No",
    child1_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_cancer_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_cancer_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_cancer_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    child1_cancer_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    child1_cancer_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_cancer_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    child1_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    child2_medicalCancer:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ1"].member_data?.child_two
        .selection_status === true
        ? "Yes"
        : "No",
    child2_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_cancer_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_cancer_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_cancer_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    child2_cancer_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    child2_cancer_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_cancer_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    child2_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    child3_medicalCancer:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ1"].member_data
        ?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_cancer_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_cancer_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_cancer_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    child3_cancer_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    child3_cancer_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_cancer_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    child3_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    child4_medicalCancer:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ1"].member_data?.child_four
        .selection_status === true
        ? "Yes"
        : "No",
    child4_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_cancer_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_cancer_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_cancer_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    child4_cancer_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    child4_cancer_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_cancer_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    child4_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F8"]?.value?.value,
  };
};

// Any heart disease or disorder, chest pain or discomfort, irregular heartbeats, palpitations or heart murmur
const heart_disease = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medicalHeartDiseases:
      data.MQ05.main_question.status === true ? "Yes" : "NO",

    medicalHeart:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ2"]?.member_data?.adult_one
        .selection_status === true
        ? "Yes"
        : "No",
    heart_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    heart_surgery_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    heart_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    heart_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    heart_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    heart_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    heart_diseases_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    heart_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    spouse_medicalHeart:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ2"].member_data?.adult_two
        .selection_status === true
        ? "Yes"
        : "No",
    spouse_heart_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    spouse_heart_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    spouse_heart_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    spouse_heart_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    spouse_heart_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    spouse_heart_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    spouse_heart_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    spouse_heart_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    child1_medicalHeart:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ2"].member_data?.child_one
        .selection_status === true
        ? "Yes"
        : "No",
    child1_heart_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child1_heart_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child1_heart_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child1_heart_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    child1_heart_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    child1_heart_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child1_heart_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    child1_heart_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    child2_medicalHeart:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ2"].member_data?.child_two
        .selection_status === true
        ? "Yes"
        : "No",
    child2_heart_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child2_heart_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child2_heart_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child2_heart_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    child2_heart_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    child2_heart_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child2_heart_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    child2_heart_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    child3_medicalHeart:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ2"].member_data
        ?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_heart_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child3_heart_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child3_heart_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child3_heart_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    child3_heart_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    child3_heart_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child3_heart_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    child3_heart_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F8"]?.value?.value,

    child4_medicalHeart:
      data.MQ05.main_question?.sub_question?.["MQ1-SQ2"].member_data?.child_four
        .selection_status === true
        ? "Yes"
        : "No",
    child4_heart_last_consultation_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    child4_heart_name_Surgery:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    child4_heart_details_treatment:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F3"]?.value?.value,
    child4_heart_disability:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F4"]?.value?.value,
    child4_heart_period_hospitalization:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F5"]?.value?.value,
    child4_heart_any_other_information:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F6"]?.value?.value,
    child4_heart_disease_name:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F7"]?.value?.value,
    child4_heart_diagnosis_date:
      data?.MQ05?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F8"]?.value?.value,
  };
};

// Has any of the Proposed to be Insured have been suffering/suffered from Covid-19 disease? If   yes, confirm if any complications arise due to covid-19.
const covid_disease = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medical_covid_diseases:
      data.MQ06.main_question.status === true ? "Yes" : "NO",

    medical_covid:
      data.MQ06.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    covid_last_consultation_date:
      data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    covid_surgery_name:
      data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    covid_treatment:
      data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    covid_disability:
      data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    covid_hospitalization:
      data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    covid_other_information:
      data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    covid_disease_name:
      data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    covid_diagnosis_date:
      data?.MQ06?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medical_covid_diseases:
      data.MQ06.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_covid_last_consultation_date:
      data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_covid_name_Surgery:
      data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_covid_treatment:
      data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_covid_disability:
      data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_covid_period_hospitalization:
      data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_covid_any_other_information:
      data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_medical_covid:
      data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_covid_diagnosis_date:
      data?.MQ06?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medical_covid_diseases:
      data.MQ06.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_covid_last_consultation_date:
      data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_covid_name_Surgery:
      data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_covid_treatment:
      data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_covid_disability:
      data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_covid_period_hospitalization:
      data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_covid_any_other_information:
      data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medical_covid:
      data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_covid_diagnosis_date:
      data?.MQ06?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medical_covid_diseases:
      data.MQ06.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_covid_last_consultation_date:
      data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_covid_name_Surgery:
      data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_covid_treatment:
      data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_covid_disability:
      data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_covid_period_hospitalization:
      data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_covid_any_other_information:
      data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medical_covid:
      data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_covid_diagnosis_date:
      data?.MQ06?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medical_covid_diseases:
      data.MQ06.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_covid_last_consultation_date:
      data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_covid_name_Surgery:
      data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_covid_treatment:
      data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_covid_disability:
      data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_covid_period_hospitalization:
      data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_covid_any_other_information:
      data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medical_covid:
      data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_covid_diagnosis_date:
      data?.MQ06?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medical_covid_diseases:
      data.MQ06.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_covid_last_consultation_date:
      data?.MQ06?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_covid_name_Surgery:
      data?.MQ06?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_covid_treatment:
      data?.MQ06?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_covid_disability:
      data?.MQ06?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_covid_period_hospitalization:
      data?.MQ06?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_covid_any_other_information:
      data?.MQ06?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medical_covid:
      data?.MQ06?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_covid_diagnosis_date:
      data?.MQ06?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Do you consume any of the following substances?(if yes, please mention the quantity)
const alcohol = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medicalAlcoholDiseases:
      data.MQ07.main_question.status === true ? "Yes" : "NO",

    medicalAlcohol:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"].status === true
        ? "Yes"
        : "No",
    alcohol:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one
        .selection_status === true
        ? "Yes"
        : "No",
    alcohol_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // alcoholQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
    //     ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    // medicalAlcohol: data.MQ07.main_question.member_data?.adult_one.selection_status === true  ? "Yes": "No",
    // medicalAlcohol: data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[ "MQ1-SQ1-F1" ]?.value?.value,
    // alcohol_week:data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[ "MQ1-SQ1-F2"]?.value?.value,
    // alcoholQuantity: data?.MQ07?.main_question?.member_data?.adult_one?.field_data?.[  "MQ1-SQ1-F3"]?.value?.value,

    spouse_medicalAlcoholDiseases:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"].status === true
        ? "Yes"
        : "No",
    spouse_medicalAlcohol:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two
        .selection_status === true
        ? "Yes"
        : "No",
    spouse_alcohol_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // spouse_alcoholQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
    //     ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    // spouse_medicalAlcoholDiseases: data.MQ07.main_question.member_data?.adult_two.selection_status ===true ? "Yes": "No",
    // spouse_medicalAlcohol: data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.["MQ1-SQ1-F1" ]?.value?.value,
    // spouse_alcohol_week:data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[ "MQ1-SQ1-F2"]?.value?.value,
    // spouse_alcoholQuantity: data?.MQ07?.main_question?.member_data?.adult_two?.field_data?.[ "MQ1-SQ1-F3"]?.value?.value,

    child1_medicalAlcoholDiseases:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"].status === true
        ? "Yes"
        : "No",
    child1_medicalAlcohol:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_one
        .selection_status === true
        ? "Yes"
        : "No",
    child1_alcohol_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child1_alcoholQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
    //     ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    // child1_medicalAlcoholDiseases:data.MQ07.main_question.member_data?.child_one.selection_status ===true  ? "Yes": "No",
    // child1_medicalAlcohol:data?.MQ07?.main_question?.member_data?.child_one?.field_data?.[ "MQ1-SQ1-F1" ]?.value?.value,
    // child1_alcohol_week: data?.MQ07?.main_question?.member_data?.child_one?.field_data?.[ "MQ1-SQ1-F2" ]?.value?.value,
    // child1_alcoholQuantity: data?.MQ07?.main_question?.member_data?.child_one?.field_data?.[ "MQ1-SQ1-F3"]?.value?.value,

    child2_medicalAlcoholDiseases:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"].status === true
        ? "Yes"
        : "No",
    child2_medicalAlcohol:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_two
        .selection_status === true
        ? "Yes"
        : "No",
    child2_alcohol_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child2_alcoholQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
    //     ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    // child2_medicalAlcoholDiseases:data.MQ07.main_question.member_data?.child_two.selection_status === true? "Yes" : "No",
    // child2_medicalAlcohol: data?.MQ07?.main_question?.member_data?.child_two?.field_data?.[ "MQ1-SQ1-F1"]?.value?.value,
    // child2_alcohol_week:data?.MQ07?.main_question?.member_data?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
    // child2_alcoholQuantity:data?.MQ07?.main_question?.member_data?.child_two?.field_data?.["MQ1-SQ1-F3"]?.value?.value,

    child3_medicalAlcoholDiseases:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"].status === true
        ? "Yes"
        : "No",
    child3_medicalAlcohol:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_alcohol_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child3_alcoholQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
    //     ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    // child3_medicalAlcoholDiseases:data.MQ07.main_question.member_data?.child_three.selection_status ===true? "Yes": "No",
    // child3_medicalAlcohol:data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[ "MQ1-SQ1-F1" ]?.value?.value,
    // child3_alcohol_week:data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[ "MQ1-SQ1-F2"]?.value?.value,
    // child3_alcoholQuantity: data?.MQ07?.main_question?.member_data?.child_three?.field_data?.[  "MQ1-SQ1-F3"]?.value?.value,

    child4_medicalAlcoholDiseases:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"].status === true
        ? "Yes"
        : "No",
    child4_medicalAlcohol:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_alcohol_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child4_alcoholQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
    //     ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    // child4_medicalAlcoholDiseases:  data.MQ07.main_question.member_data?.child_four.selection_status === true? "Yes" : "No",
    // child4_medicalAlcohol: data?.MQ07?.main_question?.member_data?.child_four?.field_data?.[ "MQ1-SQ1-F1"]?.value?.value,
    // child4_alcohol_week:data?.MQ07?.main_question?.member_data?.child_four?.field_data?.[ "MQ1-SQ1-F2"]?.value?.value,
    // child4_alcoholQuantity: data?.MQ07?.main_question?.member_data?.child_four?.field_data?.[ "MQ1-SQ1-F3" ]?.value?.value,

    // Smoking (Number of Cigarette/bidi sticks)  per Week

    medicalSmoking:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ2"].status === true
        ? "Yes"
        : "No",

    smoking:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ2"]?.member_data?.adult_one
        .selection_status === true
        ? "Yes"
        : "No",
    smoking_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // smokingQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
    //     ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    spouse_medicalSmoking:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ2"].status === true
        ? "Yes"
        : "No",
    // spouse_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ2"]?.member_data ?.adult_two.selection_status === true? "Yes": "No",
    spouse_smoking_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // spouse_smokingQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
    //     ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    child1_medicalSmoking:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ2"].status === true
        ? "Yes"
        : "No",
    // child1_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ2"]?.member_data ?.child_one.selection_status === true? "Yes": "No",
    child1_smoking_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child1_smokingQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
    //     ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    child2_medicalSmoking:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ2"].status === true
        ? "Yes"
        : "No",
    // child2_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ2"]?.member_data ?.child_two.selection_status === true? "Yes": "No",
    child2_smoking_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child2_smokingQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
    //     ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    child3_medicalSmoking:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ2"].status === true
        ? "Yes"
        : "No",
    // child3_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ2"]?.member_data ?.child_three.selection_status === true? "Yes": "No",
    child3_smoking_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child3_smokingQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
    //     ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    child4_medicalSmoking:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ2"].status === true
        ? "Yes"
        : "No",
    // child4_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ2"]?.member_data ?.child_four.selection_status === true? "Yes": "No",
    child4_smoking_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child4_smokingQuantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ2"]?.member_data
    //     ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    // Pan Masala/Gutkha (Number of small Pouches)  per Week

    medical_pan_masala_disease:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ3"].status === true
        ? "Yes"
        : "No",
    // medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ3"]?.member_data ?.adult_one.selection_status === true? "Yes": "No",
    pan_masala_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
        ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // pan_masala_Quantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
    //     ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    spouse_medical_pan_masala_disease:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ3"].status === true
        ? "Yes"
        : "No",
    // spouse_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ3"]?.member_data ?.adult_two.selection_status === true? "Yes": "No",
    spouse_pan_masala_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
        ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // spouse_pan_masala_Quantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
    //     ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    child1_medical_pan_masala_disease:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ3"].status === true
        ? "Yes"
        : "No",
    // child1_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ3"]?.member_data ?.child_one.selection_status === true? "Yes": "No",
    child1_pan_masala_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
        ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child1_pan_masala_Quantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
    //     ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    child2_medical_pan_masala_disease:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ3"].status === true
        ? "Yes"
        : "No",
    // child2_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ3"]?.member_data ?.child_two.selection_status === true? "Yes": "No",
    child2_pan_masala_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
        ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child2_pan_masala_Quantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
    //     ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    child3_medical_pan_masala_disease:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ3"].status === true
        ? "Yes"
        : "No",
    // child3_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ3"]?.member_data ?.child_three.selection_status === true? "Yes": "No",
    child3_pan_masala_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
        ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child3_pan_masala_Quantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
    //     ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    child4_medical_pan_masala_disease:
      data.MQ07.main_question?.sub_question?.["MQ1-SQ3"].status === true
        ? "Yes"
        : "No",
    // child4_medicalAlcohol: data.MQ07.main_question?.sub_question?.["MQ1-SQ3"]?.member_data ?.child_four.selection_status === true? "Yes": "No",
    child4_pan_masala_week:
      data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
        ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
    // child4_pan_masala_Quantity:
    //   data?.MQ07?.main_question?.sub_question?.["MQ1-SQ3"]?.member_data
    //     ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value,

    // Any Other substance (Name & Quantity)  per Week

    other_substance: data?.MQ08?.main_question.status === true ? "Yes" : "No",

    other_substance_disease:
      data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    other_substance_name:
      data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    other_substance_quantity:
      data?.MQ08?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,

    spouse_other_substance:
      data.MQ08.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_other_substance_name:
      data?.MQ08?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_other_substance_quantity:
      data?.MQ08?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,

    child1_other_substance:
      data.MQ08.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_other_substance_name:
      data?.MQ08?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_other_substance_quantity:
      data?.MQ08?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,

    child2_other_substance:
      data.MQ08.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_other_substance_name:
      data?.MQ08?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_other_substance_quantity:
      data?.MQ08?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,

    child3_other_substance:
      data.MQ08.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_other_substance_name:
      data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_other_substance_quantity:
      data?.MQ08?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,

    child4_other_substance:
      data.MQ08.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_other_substance_name:
      data?.MQ08?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_other_substance_quantity:
      data?.MQ08?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
  };
};

// Hypertension / High Blood Pressure(BP)/ High Cholesterol/Any other Lipid disorders
const hypertension = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medical_hypertension_diseases:
      data.MQ09.main_question.status === true ? "Yes" : "NO",

    medical_hypertension:
      data.MQ09.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    hypertension_last_consultation_date:
      data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    hypertension_surgery_name:
      data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    hypertension_treatment:
      data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    hypertension_disability:
      data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    hypertension_hospitalization:
      data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    hypertension_other_information:
      data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    hypertension_disease_name:
      data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    hypertension_diagnosis_date:
      data?.MQ09?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medicalHypertension:
      data.MQ09.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_hypertension_last_consultation_date:
      data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_hypertension_name_Surgery:
      data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_hypertension_details_treatment:
      data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_hypertension_disability:
      data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_hypertension_period_hospitalization:
      data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_hypertension_any_other_information:
      data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_hypertension_disease_name:
      data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_hypertension_diagnosis_date:
      data?.MQ09?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medicalHypertension:
      data.MQ09.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_hypertension_last_consultation_date:
      data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_hypertension_name_Surgery:
      data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_hypertension_details_treatment:
      data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_hypertension_disability:
      data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_hypertension_period_hospitalization:
      data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_hypertension_any_other_information:
      data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_hypertension_disease_name:
      data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_hypertension_diagnosis_date:
      data?.MQ09?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medicalHypertension:
      data.MQ09.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_hypertension_last_consultation_date:
      data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_hypertension_name_Surgery:
      data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_hypertension_details_treatment:
      data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_hypertension_disability:
      data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_hypertension_period_hospitalization:
      data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_hypertension_any_other_information:
      data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_hypertension_disease_name:
      data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_hypertension_diagnosis_date:
      data?.MQ09?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medicalHypertension:
      data.MQ09.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_hypertension_last_consultation_date:
      data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_hypertension_name_Surgery:
      data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_hypertension_details_treatment:
      data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_hypertension_disability:
      data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_hypertension_period_hospitalization:
      data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_hypertension_any_other_information:
      data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_hypertension_disease_name:
      data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_hypertension_diagnosis_date:
      data?.MQ09?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medicalHypertension:
      data.MQ09.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_hypertension_last_consultation_date:
      data?.MQ09?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_hypertension_name_Surgery:
      data?.MQ09?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_hypertension_details_treatment:
      data?.MQ09?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_hypertension_disability:
      data?.MQ09?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_hypertension_period_hospitalization:
      data?.MQ09?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_hypertension_any_other_information:
      data?.MQ09?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_hypertension_disease_name:
      data?.MQ09?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_hypertension_diagnosis_date:
      data?.MQ09?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Asthma / Tuberculosis (TB) / COPD/ Pleural effusion / Bronchitis / Emphysema or any other disease of Lungs, Pleura and airway or Respiratory disease?        medical_hypertension_diseases: data.MQ09.main_question.status === true ? "Yes" : "NO",
const asthma = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    asthmaDiseases: data.MQ10.main_question.status === true ? "Yes" : "NO",
    medicalAsthma:
      data.MQ10.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    asthma_last_consultation_date:
      data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    asthma_surgery_name:
      data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    asthma_treatment:
      data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    asthma_disability:
      data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    asthma_hospitalized:
      data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    asthma_other_information:
      data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    asthma_disease_name:
      data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    asthma_diagnosis_date:
      data?.MQ10?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_asthmaDiseases:
      data.MQ10.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_asthma_last_consultation_date:
      data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_asthma_surgery_name:
      data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_asthma_details_treatment:
      data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_asthma_disability:
      data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_asthma_hospitalized:
      data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_asthma_other_information:
      data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_medicalAsthma:
      data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_asthma_diagnosis_date:
      data?.MQ10?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_asthmaDiseases:
      data.MQ10.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_asthma_last_consultation_date:
      data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_asthma_surgery_name:
      data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_asthma_details_treatment:
      data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_asthma_disability:
      data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_asthma_hospitalized:
      data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_asthma_other_information:
      data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medicalAsthma:
      data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_asthma_diagnosis_date:
      data?.MQ10?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_asthmaDiseases:
      data.MQ10.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_asthma_last_consultation_date:
      data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_asthma_surgery_name:
      data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_asthma_details_treatment:
      data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_asthma_disability:
      data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_asthma_hospitalized:
      data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_asthma_other_information:
      data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medicalAsthma:
      data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_asthma_diagnosis_date:
      data?.MQ10?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_asthmaDiseases:
      data.MQ10.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_asthma_last_consultation_date:
      data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_asthma_surgery_name:
      data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_asthma_details_treatment:
      data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_asthma_disability:
      data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_asthma_hospitalized:
      data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_asthma_other_information:
      data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medicalAsthma:
      data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_asthma_diagnosis_date:
      data?.MQ10?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_asthmaDiseases:
      data.MQ10.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_asthma_last_consultation_date:
      data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_asthma_surgery_name:
      data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_asthma_details_treatment:
      data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_asthma_disability:
      data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_asthma_hospitalized:
      data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_asthma_other_information:
      data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medicalAsthma:
      data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_asthma_diagnosis_date:
      data?.MQ10?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Thyroid disease/ Cushing's disease/ Parathyroid Disease/ Addison's disease / Pituitary tumor/ disease or any other disorder of Endocrine system?
const throid = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    thyroidDiseases: data.MQ11.main_question.status === true ? "Yes" : "No",
    thyroidMedical:
      data.MQ11.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    thyroid_last_consultation_date:
      data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    thyroid_surgery_name:
      data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    thyroid_treatment:
      data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    thyroid_disability:
      data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    thyroid_hospitalized:
      data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    thyroid_other_information:
      data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    thyroid_disease_name:
      data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    thyroid_diagnosis_date:
      data?.MQ11?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_thyroidDiseases:
      data.MQ11.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_thyroid_last_consultation_date:
      data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_thyroid_surgery_name:
      data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_thyroid_treatment:
      data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_thyroid_disability:
      data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_thyroid_hospitalized:
      data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_thyroid_other_information:
      data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_thyroidMedical:
      data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_thyroid_diagnosis_date:
      data?.MQ11?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_thyroidDiseases:
      data.MQ11.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_thyroid_last_consultation_date:
      data?.MQ11?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_thyroid_surgery_name:
      data?.MQ11?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_thyroid_treatment:
      data?.MQ11?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_thyroid_disability:
      data?.MQ11?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_thyroid_hospitalized:
      data?.MQ11?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_thyroid_other_information:
      data?.MQ11?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_thyroidMedical:
      data?.MQ11?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_thyroid_diagnosis_date:
      data?.MQ11?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_thyroidDiseases:
      data.MQ11.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_thyroid_last_consultation_date:
      data?.MQ11?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_thyroid_surgery_name:
      data?.MQ11?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_thyroid_treatment:
      data?.MQ11?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_thyroid_disability:
      data?.MQ11?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_thyroid_hospitalized:
      data?.MQ11?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_thyroid_other_information:
      data?.MQ11?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_thyroidMedical:
      data?.MQ11?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_thyroid_diagnosis_date:
      data?.MQ11?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_thyroidDiseases:
      data.MQ11.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_thyroid_last_consultation_date:
      data?.MQ11?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_thyroid_surgery_name:
      data?.MQ11?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_thyroid_treatment:
      data?.MQ11?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_thyroid_disability:
      data?.MQ11?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_thyroid_hospitalized:
      data?.MQ11?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_thyroid_other_information:
      data?.MQ11?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_thyroidMedical:
      data?.MQ11?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_thyroid_diagnosis_date:
      data?.MQ11?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_thyroidDiseases:
      data.MQ11.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_thyroid_last_consultation_date:
      data?.MQ11?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_thyroid_surgery_name:
      data?.MQ11?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_thyroid_treatment:
      data?.MQ11?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_thyroid_disability:
      data?.MQ11?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_thyroid_hospitalized:
      data?.MQ11?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_thyroid_other_information:
      data?.MQ11?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_thyroidMedical:
      data?.MQ11?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_thyroid_diagnosis_date:
      data?.MQ11?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Diabetes Mellitus / High Blood Sugar / Diabetes on Insulin or medication
const diabetes_mellitus = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medicalDiabetesDiseases:
      data.MQ12.main_question?.status === true ? "Yes" : "No",
    medicalDiabetes:
      data.MQ12.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    diabetes_last_consultation_date:
      data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    diabetes_surgery_name:
      data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    diabetes_treatment:
      data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    diabetes_disability:
      data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    diabetes_hospitalization:
      data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    diabetes_other_information:
      data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    diabetes_disease_name:
      data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    diabetes_diagnosis_date:
      data?.MQ12?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medicalDiabetesDiseases:
      data.MQ12.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_diabetes_last_consultation_date:
      data?.MQ12?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_diabetes_name_Surgery:
      data?.MQ12?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_diabetes_treatment:
      data?.MQ12?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_diabetes_disability:
      data?.MQ12?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_diabetes_period_hospitalization:
      data?.MQ12?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_diabetes_any_other_information:
      data?.MQ12?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_diabetes_disease_name:
      data?.MQ12?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_diabetes_diagnosis_date:
      data?.MQ12?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medicalDiabetesDiseases:
      data.MQ12.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_diabetes_last_consultation_date:
      data?.MQ12?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_diabetes_name_Surgery:
      data?.MQ12?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_diabetes_treatment:
      data?.MQ12?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_diabetes_disability:
      data?.MQ12?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_diabetes_period_hospitalization:
      data?.MQ12?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_diabetes_any_other_information:
      data?.MQ12?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medicalDiabetes:
      data?.MQ12?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_diabetes_diagnosis_date:
      data?.MQ12?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medicalDiabetesDiseases:
      data.MQ12.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_diabetes_last_consultation_date:
      data?.MQ12?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_diabetes_name_Surgery:
      data?.MQ12?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_diabetes_treatment:
      data?.MQ12?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_diabetes_disability:
      data?.MQ12?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_diabetes_period_hospitalization:
      data?.MQ12?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_diabetes_any_other_information:
      data?.MQ12?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medicalDiabetes:
      data?.MQ12?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_diabetes_diagnosis_date:
      data?.MQ12?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medicalDiabetesDiseases:
      data.MQ12.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_diabetes_last_consultation_date:
      data?.MQ12?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_diabetes_name_Surgery:
      data?.MQ12?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_diabetes_treatment:
      data?.MQ12?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_diabetes_disability:
      data?.MQ12?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_diabetes_period_hospitalization:
      data?.MQ12?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_diabetes_any_other_information:
      data?.MQ12?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medicalDiabetes:
      data?.MQ12?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_diabetes_diagnosis_date:
      data?.MQ12?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medicalDiabetesDiseases:
      data.MQ12.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_diabetes_last_consultation_date:
      data?.MQ12?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_diabetes_name_Surgery:
      data?.MQ12?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_diabetes_treatment:
      data?.MQ12?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_diabetes_disability:
      data?.MQ12?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_diabetes_period_hospitalization:
      data?.MQ12?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_diabetes_any_other_information:
      data?.MQ12?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medicalDiabetes:
      data?.MQ12?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_diabetes_diagnosis_date:
      data?.MQ12?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Motor Neuron Disease/ Muscular dystrophies/ Myasthenia Gravis/ Demyelinating disease or any other disease of Neuromuscular system (muscles and/or nervous system)
const neuron_disease = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medical_neuron_disease:
      data.MQ13.main_question?.status === true ? "Yes" : "No",
    medical_neuron:
      data.MQ13.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    neuron_last_consultation_date:
      data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    neuron_surgery_name:
      data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    neuron_treatment:
      data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    neuron_disability:
      data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    neuron_hospitalization:
      data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    neuron_other_information:
      data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    neuron_disease_name:
      data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    neuron_diagnosis_date:
      data?.MQ13?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medical_neuron_disease:
      data.MQ13.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_neuron_last_consultation_date:
      data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_neuron_surgery_name:
      data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_neuron_treatment:
      data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_neuron_disability:
      data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_neuron_hospitalization:
      data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_neuron_other_information:
      data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_medical_neuron:
      data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_neuron_diagnosis_date:
      data?.MQ13?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medical_neuron_disease:
      data.MQ13.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_neuron_last_consultation_date:
      data?.MQ13?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_neuron_surgery_name:
      data?.MQ13?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_neuron_treatment:
      data?.MQ13?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_neuron_disability:
      data?.MQ13?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_neuron_hospitalization:
      data?.MQ13?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_neuron_other_information:
      data?.MQ13?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medical_neuron:
      data?.MQ13?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_neuron_diagnosis_date:
      data?.MQ13?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medical_neuron_disease:
      data.MQ13.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_neuron_last_consultation_date:
      data?.MQ13?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_neuron_surgery_name:
      data?.MQ13?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_neuron_treatment:
      data?.MQ13?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_neuron_disability:
      data?.MQ13?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_neuron_hospitalization:
      data?.MQ13?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_neuron_other_information:
      data?.MQ13?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medical_neuron:
      data?.MQ13?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_neuron_diagnosis_date:
      data?.MQ13?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medical_neuron_disease:
      data.MQ13.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_neuron_last_consultation_date:
      data?.MQ13?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_neuron_surgery_name:
      data?.MQ13?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_neuron_treatment:
      data?.MQ13?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_neuron_disability:
      data?.MQ13?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_neuron_hospitalization:
      data?.MQ13?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_neuron_other_information:
      data?.MQ13?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medical_neuron:
      data?.MQ13?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_neuron_diagnosis_date:
      data?.MQ13?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medical_neuron_disease:
      data.MQ13.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_neuron_last_consultation_date:
      data?.MQ13?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_neuron_surgery_name:
      data?.MQ13?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_neuron_treatment:
      data?.MQ13?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_neuron_disability:
      data?.MQ13?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_neuron_hospitalization:
      data?.MQ13?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_neuron_other_information:
      data?.MQ13?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medical_neuron:
      data?.MQ13?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_neuron_diagnosis_date:
      data?.MQ13?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Stroke/Paralysis/Transient Ischemic Attack/Multiple Sclerosis/Epilepsy/Mental-Psychiatric illness/ Parkinsonism/Alzheimer's/Depression /Dementia or any other disease of Brain and Nervous System?
const stroke = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medicalStrokeDiseases:
      data.MQ14.main_question.status === true ? "Yes" : "No",
    medicalStroke:
      data.MQ14.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    stroke_last_consultation_date:
      data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    stroke_surgery_name:
      data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    stroke_treatment:
      data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    stroke_disability:
      data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    stroke_hospitalization:
      data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    stroke_other_information:
      data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    stroke_disease_name:
      data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    stroke_diagnosis_date:
      data?.MQ14?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medicalStrokeDiseases:
      data.MQ14.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_stroke_last_consultation_date:
      data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_stroke_surgery_name:
      data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_stroke_treatment:
      data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_stroke_disability:
      data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_stroke_hospitalization:
      data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_stroke_other_information:
      data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_medicalStroke:
      data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_stroke_diagnosis_date:
      data?.MQ14?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medicalStrokeDiseases:
      data.MQ14.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_stroke_last_consultation_date:
      data?.MQ14?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_stroke_surgery_name:
      data?.MQ14?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_stroke_treatment:
      data?.MQ14?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_stroke_disability:
      data?.MQ14?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_stroke_hospitalization:
      data?.MQ14?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_stroke_other_information:
      data?.MQ14?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medicalStroke:
      data?.MQ14?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_stroke_diagnosis_date:
      data?.MQ14?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medicalStrokeDiseases:
      data.MQ14.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_stroke_last_consultation_date:
      data?.MQ14?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_stroke_surgery_name:
      data?.MQ14?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_stroke_treatment:
      data?.MQ14?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_stroke_disability:
      data?.MQ14?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_stroke_hospitalization:
      data?.MQ14?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_stroke_other_information:
      data?.MQ14?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medicalStroke:
      data?.MQ14?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_stroke_diagnosis_date:
      data?.MQ14?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medicalStrokeDiseases:
      data.MQ14.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_stroke_last_consultation_date:
      data?.MQ14?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_stroke_surgery_name:
      data?.MQ14?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_stroke_treatment:
      data?.MQ14?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_stroke_disability:
      data?.MQ14?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_stroke_hospitalization:
      data?.MQ14?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_stroke_other_information:
      data?.MQ14?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medicalStroke:
      data?.MQ14?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_stroke_diagnosis_date:
      data?.MQ14?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medicalStrokeDiseases:
      data.MQ14.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_stroke_last_consultation_date:
      data?.MQ14?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_stroke_surgery_name:
      data?.MQ14?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_stroke_treatment:
      data?.MQ14?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_stroke_disability:
      data?.MQ14?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_stroke_hospitalization:
      data?.MQ14?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_stroke_other_information:
      data?.MQ14?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medicalStroke:
      data?.MQ14?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_stroke_diagnosis_date:
      data?.MQ14?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Cirrhosis / Hepatitis / Wilson's disease / Pancreatitis / Liver disease / Crohn's disease / Ulcerative Colitis /Inflammatory Bowel Diseases/ Piles or any other disease of Mouth, Esophagus, Liver, Gall bladder, Stomach or Intestines or any other part of Digestive System?
const cirrhosis = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medical_digestive_disease:
      data.MQ15.main_question.status === true ? "Yes" : "No",

    medicaldigestive:
      data.MQ15.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    digestive_last_consultation_date:
      data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    digestive_surgery_name:
      data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    digestive_treatment:
      data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    digestive_disability:
      data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    digestive_hospitalization:
      data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    digestive_other_information:
      data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    digestive_disease_name:
      data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    digestive_diagnosis_date:
      data?.MQ15?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medical_digestive_disease:
      data.MQ15.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_digestive_last_consultation_date:
      data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_digestive_surgery_name:
      data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_digestive_treatment:
      data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_digestive_disability:
      data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_digestive_hospitalization:
      data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_digestive_other_information:
      data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_medicalDigestive:
      data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_digestive_diagnosis_date:
      data?.MQ15?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medical_digestive_disease:
      data.MQ15.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_digestive_last_consultation_date:
      data?.MQ15?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_digestive_surgery_name:
      data?.MQ15?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_digestive_treatment:
      data?.MQ15?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_digestive_disability:
      data?.MQ15?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_digestive_hospitalization:
      data?.MQ15?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_digestive_other_information:
      data?.MQ15?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medicalDigestive:
      data?.MQ15?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_digestive_diagnosis_date:
      data?.MQ15?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medical_digestive_disease:
      data.MQ15.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_digestive_last_consultation_date:
      data?.MQ15?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_digestive_surgery_name:
      data?.MQ15?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_digestive_treatment:
      data?.MQ15?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_digestive_disability:
      data?.MQ15?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_digestive_hospitalization:
      data?.MQ15?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_digestive_other_information:
      data?.MQ15?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medicalDigestive:
      data?.MQ15?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_digestive_diagnosis_date:
      data?.MQ15?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medical_digestive_disease:
      data.MQ15.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_digestive_last_consultation_date:
      data?.MQ15?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_digestive_surgery_name:
      data?.MQ15?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_digestive_treatment:
      data?.MQ15?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_digestive_disability:
      data?.MQ15?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_digestive_hospitalization:
      data?.MQ15?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_digestive_other_information:
      data?.MQ15?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medicalDigestive:
      data?.MQ15?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_digestive_diagnosis_date:
      data?.MQ15?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medical_digestive_disease:
      data.MQ15.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_digestive_last_consultation_date:
      data?.MQ15?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_digestive_surgery_name:
      data?.MQ15?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_digestive_treatment:
      data?.MQ15?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_digestive_disability:
      data?.MQ15?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_digestive_hospitalization:
      data?.MQ15?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_digestive_other_information:
      data?.MQ15?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medicalDigestive:
      data?.MQ15?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_digestive_diagnosis_date:
      data?.MQ15?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Kidney Stones/ Renal Failure/ Dialysis/ Chronic Kidney Disease/ Prostate Disease or any other disease of Kidney, Urinary Tract or reproductive organs?
const kidney = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medicalKidneyDiseases:
      data.MQ16.main_question.status === true ? "Yes" : "No",
    medicalKidney:
      data.MQ16.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    kidney_last_consultation_date:
      data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    kidney_surgery_name:
      data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    kidney_treatment:
      data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    kidney_disability:
      data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    kidney_hospitalization:
      data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    kidney_other_information:
      data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    medicalKidney_diseases_name:
      data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    kidney_diagnosis_date:
      data?.MQ16?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medicalKidneyDiseases:
      data.MQ16.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_kidney_last_consultation_date:
      data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_kidney_surgery_name:
      data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_kidney_treatment:
      data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_kidney_disability:
      data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_kidney_hospitalization:
      data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_kidney_other_information:
      data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_medicalKidney:
      data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_kidney_diagnosis_date:
      data?.MQ16?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medicalKidneyDiseases:
      data.MQ16.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_kidney_last_consultation_date:
      data?.MQ16?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_kidney_surgery_name:
      data?.MQ16?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_kidney_treatment:
      data?.MQ16?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_kidney_disability:
      data?.MQ16?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_kidney_hospitalization:
      data?.MQ16?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_kidney_other_information:
      data?.MQ16?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medicalKidney:
      data?.MQ16?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_kidney_diagnosis_date:
      data?.MQ16?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medicalKidneyDiseases:
      data.MQ16.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_kidney_last_consultation_date:
      data?.MQ16?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_kidney_surgery_name:
      data?.MQ16?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_kidney_treatment:
      data?.MQ16?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_kidney_disability:
      data?.MQ16?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_kidney_hospitalization:
      data?.MQ16?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_kidney_other_information:
      data?.MQ16?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medicalKidney:
      data?.MQ16?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_kidney_diagnosis_date:
      data?.MQ16?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medicalKidneyDiseases:
      data.MQ16.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_kidney_last_consultation_date:
      data?.MQ16?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_kidney_surgery_name:
      data?.MQ16?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_kidney_treatment:
      data?.MQ16?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_kidney_disability:
      data?.MQ16?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_kidney_hospitalization:
      data?.MQ16?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_kidney_other_information:
      data?.MQ16?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medicalKidney:
      data?.MQ16?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_kidney_diagnosis_date:
      data?.MQ16?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medicalKidneyDiseases:
      data.MQ16.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_kidney_last_consultation_date:
      data?.MQ16?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_kidney_surgery_name:
      data?.MQ16?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_kidney_treatment:
      data?.MQ16?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_kidney_disability:
      data?.MQ16?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_kidney_hospitalization:
      data?.MQ16?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_kidney_other_information:
      data?.MQ16?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medicalKidney:
      data?.MQ16?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_kidney_diagnosis_date:
      data?.MQ16?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Do you have Previous/ Current policy or proposal applied for life, health, hospital daily cash or critical illness or Cancer or personal accident insurance?
const previous_policy = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    previous_current_policy:
      data.MQ17.main_question.status === true ? "Yes" : "No",
    previouse_policy:
      data.MQ17.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    health_policy_portability:
      data?.MQ17?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    insurer_name:
      data?.MQ17?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    claim_previous_policy:
      data?.MQ17?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,

    spouse_previous_current_policy:
      data.MQ17.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_health_policy_portability:
      data?.MQ17?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_insurer_name:
      data?.MQ17?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_claim_previous_policy:
      data?.MQ17?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,

    child1_previous_current_policy:
      data.MQ17.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_health_policy_portability:
      data?.MQ17?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_insurer_name:
      data?.MQ17?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_claim_previous_policy:
      data?.MQ17?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,

    child2_previous_current_policy:
      data.MQ17.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_health_policy_portability:
      data?.MQ17?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_insurer_name:
      data?.MQ17?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_claim_previous_policy:
      data?.MQ17?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,

    child3_previous_current_policy:
      data.MQ17.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_health_policy_portability:
      data?.MQ17?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_insurer_name:
      data?.MQ17?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_claim_previous_policy:
      data?.MQ17?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,

    child4_previous_current_policy:
      data.MQ17.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_health_policy_portability:
      data?.MQ17?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_insurer_name:
      data?.MQ17?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_claim_previous_policy:
      data?.MQ17?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
  };
};

// Was any proposal/policy declined/ deferred/ withdrawn / accepted with modified terms/ cancelled, if “Yes” please provide details in additional information
const additional_info = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    medical_disease_name:
      data.MQ18.main_question.status === true ? "Yes" : "No",
    medical_disease:
      data.MQ18.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    medical_last_consultation_date:
      data?.MQ18?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    medical_surgery_name:
      data?.MQ18?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    medical_treatment:
      data?.MQ18?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    medical_disability:
      data?.MQ18?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    medical_hospitalization:
      data?.MQ18?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    medical_other_information:
      data?.MQ18?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    medical_diseases_name:
      data?.MQ18?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    medical_diagnosis_date:
      data?.MQ18?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medical_disease_name:
      data.MQ18.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_medical_last_consultation_date:
      data?.MQ18?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_medical_surgery_name:
      data?.MQ18?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_medical_treatment:
      data?.MQ18?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_medical_disability:
      data?.MQ18?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_medical_hospitalization:
      data?.MQ18?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_medical_other_information:
      data?.MQ18?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_medical_disease:
      data?.MQ18?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_medical_diagnosis_date:
      data?.MQ18?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medical_disease_name:
      data.MQ18.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_medical_last_consultation_date:
      data?.MQ18?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_medical_surgery_name:
      data?.MQ18?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_medical_treatment:
      data?.MQ18?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_medical_disability:
      data?.MQ18?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_medical_hospitalization:
      data?.MQ18?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_medical_other_information:
      data?.MQ18?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_medical_disease:
      data?.MQ18?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_medical_diagnosis_date:
      data?.MQ18?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medical_disease_name:
      data.MQ18.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_medical_last_consultation_date:
      data?.MQ18?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_medical_surgery_name:
      data?.MQ18?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_medical_treatment:
      data?.MQ18?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_medical_disability:
      data?.MQ18?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_medical_hospitalization:
      data?.MQ18?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_medical_other_information:
      data?.MQ18?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_medical_disease:
      data?.MQ18?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_medical_diagnosis_date:
      data?.MQ18?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medical_disease_name:
      data.MQ18.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_medical_last_consultation_date:
      data?.MQ18?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_medical_surgery_name:
      data?.MQ18?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_medical_treatment:
      data?.MQ18?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_medical_disability:
      data?.MQ18?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_medical_hospitalization:
      data?.MQ18?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_medical_other_information:
      data?.MQ18?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_medical_disease:
      data?.MQ18?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_medical_diagnosis_date:
      data?.MQ18?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medical_disease_name:
      data.MQ18.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_medical_last_consultation_date:
      data?.MQ18?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_medical_surgery_name:
      data?.MQ18?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_medical_treatment:
      data?.MQ18?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_medical_disability:
      data?.MQ18?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_medical_hospitalization:
      data?.MQ18?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_medical_other_information:
      data?.MQ18?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_medical_disease:
      data?.MQ18?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_medical_diagnosis_date:
      data?.MQ18?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};

// Disease of the musculoskeletal system / Or thopedic disorders/Degeneration, Fracture or dislocation of bones or joints/ avascular necrosis of joints or any other disorder related to it?
const musculoskeletal = (data: TAdityaBirlaMedicalQuestion) => {
  return {
    musculoskeletal_diseases:
      data.MQ19.main_question.status === true ? "Yes" : "No",
    medical_musculoskeletal:
      data.MQ19.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    musculoskeletal_last_consultation_date:
      data?.MQ19?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    musculoskeletal_surgery_name:
      data?.MQ19?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    musculoskeletal_treatment:
      data?.MQ19?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    musculoskeletal_disability:
      data?.MQ19?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    musculoskeletal_hospitalization:
      data?.MQ19?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    musculoskeletal_other_information:
      data?.MQ19?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    musculoskeletal_diseases_name:
      data?.MQ19?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    musculoskeletal_diagnosis_date:
      data?.MQ19?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_musculoskeletal_diseases:
      data.MQ19.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_musculoskeletal_last_consultation_date:
      data?.MQ19?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_musculoskeletal_surgery_name:
      data?.MQ19?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_musculoskeletal_treatment:
      data?.MQ19?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_musculoskeletal_disability:
      data?.MQ19?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_musculoskeletal_hospitalization:
      data?.MQ19?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_musculoskeletal_other_information:
      data?.MQ19?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_musculoskeletal_diseases_name:
      data?.MQ19?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_musculoskeletal_diagnosis_date:
      data?.MQ19?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_musculoskeletal_diseases:
      data.MQ19.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_musculoskeletal_last_consultation_date:
      data?.MQ19?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_musculoskeletal_surgery_name:
      data?.MQ19?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_musculoskeletal_treatment:
      data?.MQ19?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_musculoskeletal_disability:
      data?.MQ19?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_musculoskeletal_hospitalization:
      data?.MQ19?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_musculoskeletal_other_information:
      data?.MQ19?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_musculoskeletal_diseases_name:
      data?.MQ19?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_musculoskeletal_diagnosis_date:
      data?.MQ19?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_musculoskeletal_diseases:
      data.MQ19.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_musculoskeletal_last_consultation_date:
      data?.MQ19?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_musculoskeletal_surgery_name:
      data?.MQ19?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_musculoskeletal_treatment:
      data?.MQ19?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_musculoskeletal_disability:
      data?.MQ19?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_musculoskeletal_hospitalization:
      data?.MQ19?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_musculoskeletal_other_information:
      data?.MQ19?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_musculoskeletal_diseases_name:
      data?.MQ19?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_musculoskeletal_diagnosis_date:
      data?.MQ19?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_musculoskeletal_diseases:
      data.MQ19.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_musculoskeletal_last_consultation_date:
      data?.MQ19?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_musculoskeletal_surgery_name:
      data?.MQ19?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_musculoskeletal_treatment:
      data?.MQ19?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_musculoskeletal_disability:
      data?.MQ19?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_musculoskeletal_hospitalization:
      data?.MQ19?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_musculoskeletal_other_information:
      data?.MQ19?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_musculoskeletal_diseases_name:
      data?.MQ19?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_musculoskeletal_diagnosis_date:
      data?.MQ19?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_musculoskeletal_diseases:
      data.MQ19.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_musculoskeletal_last_consultation_date:
      data?.MQ19?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_musculoskeletal_surgery_name:
      data?.MQ19?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_musculoskeletal_treatment:
      data?.MQ19?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_musculoskeletal_disability:
      data?.MQ19?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_musculoskeletal_hospitalization:
      data?.MQ19?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_musculoskeletal_other_information:
      data?.MQ19?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_musculoskeletal_diseases_name:
      data?.MQ19?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_musculoskeletal_diagnosis_date:
      data?.MQ19?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
  };
};
export const ADITYA_BIRLA_MEDICAL_REQUEST = (
  data: TAdityaBirlaMedicalQuestion,
  ADD_FORM_RESPONSE: any,
  SELECTED_QUOTE_DATA: any
) => {
  console.log("ADD_FORM_RESPONSE", ADD_FORM_RESPONSE);
  console.log("SELECTED_QUOTE_DATA", SELECTED_QUOTE_DATA);

  return {
    quote_no: ADD_FORM_RESPONSE.quote_no,
    company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
    product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
    medical_info: "Yes",
    medicalHIVDiseases: data.MQ01.main_question.status === true ? "Yes" : "NO",
    medicalHIV:
      data.MQ01.main_question.member_data?.adult_one.selection_status === true
        ? "Yes"
        : "No",
    hiv_last_consultation_date:
      data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    hiv_surgery_name:
      data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    hiv_treatment:
      data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    hiv_disability:
      data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    hiv_hospitalization:
      data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    hiv_other_information:
      data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    hiv_disease_name:
      data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    hiv_diagnosis_date:
      data?.MQ01?.main_question?.member_data?.adult_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    spouse_medicalHIV:
      data.MQ01.main_question.member_data?.adult_two.selection_status === true
        ? "Yes"
        : "No",
    spouse_hiv_last_consultation_date:
      data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    spouse_hiv_name_Surgery:
      data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    spouse_hiv_details_treatment:
      data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    spouse_hiv_disability:
      data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    spouse_hiv_period_hospitalization:
      data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    spouse_hiv_any_other_information:
      data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    spouse_hiv_disease_name:
      data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    spouse_hiv_diagnosis_date:
      data?.MQ01?.main_question?.member_data?.adult_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child1_medicalHIV:
      data.MQ01.main_question.member_data?.child_one.selection_status === true
        ? "Yes"
        : "No",
    child1_hiv_last_consultation_date:
      data?.MQ01?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child1_hiv_surgery_name:
      data?.MQ01?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child1_hiv_treatment:
      data?.MQ01?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child1_hiv_disability:
      data?.MQ01?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child1_hiv_hospitalization:
      data?.MQ01?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child1_hiv_other_information:
      data?.MQ01?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child1_hiv_disease_name:
      data?.MQ01?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child1_hiv_diagnosis_date:
      data?.MQ01?.main_question?.member_data?.child_one?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child2_medicalHIV:
      data.MQ01.main_question.member_data?.child_two.selection_status === true
        ? "Yes"
        : "No",
    child2_hiv_last_consultation_date:
      data?.MQ01?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child2_hiv_surgery_name:
      data?.MQ01?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child2_hiv_treatment:
      data?.MQ01?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child2_hiv_disability:
      data?.MQ01?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child2_hiv_hospitalization:
      data?.MQ01?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child2_hiv_other_information:
      data?.MQ01?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child2_hiv_disease_name:
      data?.MQ01?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child2_hiv_diagnosis_date:
      data?.MQ01?.main_question?.member_data?.child_two?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child3_medicalHIV:
      data.MQ01.main_question.member_data?.child_three.selection_status === true
        ? "Yes"
        : "No",
    child3_hiv_last_consultation_date:
      data?.MQ01?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child3_hiv_surgery_name:
      data?.MQ01?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child3_hiv_treatment:
      data?.MQ01?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child3_hiv_disability:
      data?.MQ01?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child3_hiv_hospitalization:
      data?.MQ01?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child3_hiv_other_information:
      data?.MQ01?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child3_hiv_disease_name:
      data?.MQ01?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child3_hiv_diagnosis_date:
      data?.MQ01?.main_question?.member_data?.child_three?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,

    child4_medicalHIV:
      data.MQ01.main_question.member_data?.child_four.selection_status === true
        ? "Yes"
        : "No",
    child4_hiv_last_consultation_date:
      data?.MQ01?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F1"
      ]?.value?.value,
    child4_hiv_surgery_name:
      data?.MQ01?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F2"
      ]?.value?.value,
    child4_hiv_treatment:
      data?.MQ01?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F3"
      ]?.value?.value,
    child4_hiv_disability:
      data?.MQ01?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F4"
      ]?.value?.value,
    child4_hiv_hospitalization:
      data?.MQ01?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F5"
      ]?.value?.value,
    child4_hiv_other_information:
      data?.MQ01?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F6"
      ]?.value?.value,
    child4_hiv_disease_name:
      data?.MQ01?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F7"
      ]?.value?.value,
    child4_hiv_diagnosis_date:
      data?.MQ01?.main_question?.member_data?.child_four?.field_data?.[
        "MQ1-SQ1-F8"
      ]?.value?.value,
    ...disorder_of_eye(data),
    ...any_other_disease(data),
    ...hospitalized(data),
    ...diagnosed(data),
    ...heart_disease(data),
    ...covid_disease(data),
    ...alcohol(data),
    ...hypertension(data),
    ...asthma(data),
    ...throid(data),
    ...diabetes_mellitus(data),
    ...neuron_disease(data),
    ...stroke(data),
    ...cirrhosis(data),
    ...kidney(data),
    ...previous_policy(data),
    ...additional_info(data),
    ...musculoskeletal(data),
    react_medical : data
  };
};
// 