import React, { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import AddonDetailGoldenShieldICICI from "../../../../Page/Desktop/Health/ICIC/AddonDetailsICIC/AddonDetailGoldenShieldICICI";
import { TGOLDEN_SHIELDICICIAddon } from "../../../../types/Health/ProposalDeatail/TICICAddon/TICICAddon";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { useNavigate } from "react-router-dom";
import MAddonDetailGoldenShieldICICI from "../../../../Page/Mobile/Health/ICIC/AddonDetailsICIC/MAddonDetailGoldenShieldICICI";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";

const AddonDetailsContainerGoldenShieldIcici = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { Health,CKYC } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADDON_DETAILS_GOLDEN_SHIELD_ICICI,
  } = useAppSelector((state) => state.Health);
  const { CKYC_DETAILS, VERIFY_KYC_FORM } = useAppSelector(
    (state) => state.CKYC
  );

  console.log("pacdhdzgsdhfcdyt", PAGE_STATUS);
  const [addonStatus, setAddonStatus] = useState<TGOLDEN_SHIELDICICIAddon>(
    ADDON_DETAILS_GOLDEN_SHIELD_ICICI
  );
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    updatePreiumApi();
    dispatch(
      HealthSlice.actions.ADDON_DETAILS_DATA_GOLDEN_SHIELD_ICIC(addonStatus)
    );
  }, [addonStatus]);
  const updateMasterState = (attrName: any, value: any) => {
    setAddonStatus((prevState) => ({
      ...prevState,
      [attrName]: value,
    }));
    if (attrName === "Modification_base_co_payment") {
      setAddonStatus((prev) => ({
        ...prev,
        Voluntary_deduction: false,
        Modification_base_co_payment: value,
        VoluntaryCopaymentPercentage: 20,
      }));
    } else if (attrName === "Voluntary_deduction") {
      setAddonStatus((prev) => ({
        ...prev,
        Voluntary_deduction: value,
        Modification_base_co_payment: false,
        VoluntaryCopaymentPercentage: 20,
      }));
    } else if (attrName === "Modification_base_co_payment" && value === false) {
      setAddonStatus((prev) => ({
        ...prev,
        VoluntaryCopaymentPercentage: 40,
      }));
    } else if (attrName === "Voluntary_deduction" && value === false) {
      setAddonStatus((prev) => ({
        ...prev,
        VoluntaryCopaymentPercentage: 40,
      }));
    }
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_GOLDEN_SHIELD_ICICI: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      adult: ADD_FORM.spouse_status === true ? "2" : "1",
      child: ADD_FORM.children,
      gender: VERIFY_KYC_FORM.gender.value,
      term: SELECTED_QUOTE_DATA.premiumDetails.term,
      section: "add_ons_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      addon: {
        AddOn1: addonStatus.Claim_protector === true ? 1 : 0, //Claim Protector
        AddOn2: addonStatus.Modification_base_co_payment === true ? 1 : 0, //Modification of base co-payment
        VoluntaryCopaymentPercentage:
          addonStatus.Voluntary_deduction ||
          addonStatus.Modification_base_co_payment
            ? addonStatus.VoluntaryCopaymentPercentage
            : 0,
        AddOn3: addonStatus.Voluntary_deduction === true ? 1 : 0, //Voluntary Deductible
        AddOn4: addonStatus.Care_management_program === true ? 1 : 0, // Care Management Program  (Mandatory 1 selectbale)
        AddOn5: addonStatus.Care_management_plus_program === true ? 1 : 0, //Care Management Plus Program
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonGoldenShieldIcic(
      onSuccess,
      onError,
      param
    );
  };

  
  const FinalPremium = ()=>{
    const onSuccess=(res:any)=>{
console.log("res",res);

    }

    const onError =(err:any)=>{
      console.log("err",err);
    }

    let param:any={
       quote_no: ADD_FORM_RESPONSE.quote_no,
       final_premium : SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium
    }
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  }


  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");
    
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
        if (PAGE_STATUS) {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
        // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
      );
    }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      adult: ADD_FORM.spouse_status === true ? "2" : "1",
      child: ADD_FORM.children,
      gender: VERIFY_KYC_FORM.gender.value,
      term: SELECTED_QUOTE_DATA.premiumDetails.term,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      section: "add_ons_details",
      AddOn1: addonStatus.Claim_protector === true ? 1 : 0, //Claim Protector
      AddOn2: addonStatus.Modification_base_co_payment === true ? 1 : 0, //Modification of base co-payment
      VoluntaryCopaymentPercentage: addonStatus.VoluntaryCopaymentPercentage,
      AddOn3: addonStatus.Voluntary_deduction === true ? 1 : 0, //Voluntary Deductible
      AddOn4: addonStatus.Care_management_program === true ? 1 : 0, // Care Management Program  (Mandatory 1 selectbale)
      AddOn5: addonStatus.Care_management_plus_program === true ? 1 : 0, //Care Management Plus Program
    };
    // if (PAGE_STATUS) {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
    //   );
    // } else {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
    //     // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
    //   );
    // }
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };


  useEffect(() => {
    FinalPremium()
}, [SELECTED_QUOTE_DATA]);

      useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {isMobile ? (
        <MAddonDetailGoldenShieldICICI
          addonStatus={addonStatus}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          isLoading={loader}
          updateMasterState={updateMasterState}
        />
      ) : (
        <AddonDetailGoldenShieldICICI
          addonStatus={addonStatus}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          isLoading={loader}
          updateMasterState={updateMasterState}
        />
      )}
    </>
  );
};

export default AddonDetailsContainerGoldenShieldIcici;
