import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChildFourDetails from "../../../../Page/Desktop/Health/CARE/ChildFourDetails/ChildFourDetails";
import MChildFourDetails from "../../../../Page/Mobile/Health/CARE/MChildFourDetails/MChildFourDetails";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../Services/Enum/EHome";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import {
  isEmpty,
  validateFullName,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TOtherMemberDetails } from "../../../../types/Health/ProposalDeatail/TProposalDetail";

export default function ChildFourDetailsContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);
  const [memberFields, setMemberFields] = useState<TOtherMemberDetails>(
    INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS
  );
  const { Health } = useAppSelector((state) => state);

  useEffect(() => {
    getRealtionData();
  }, []);

  useEffect(() => {
    setMemberFields({...INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS})
}, [INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value]);

  const getRealtionData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      let data: TDropdown[] = res?.results?.response?.map(
        (maritalData: any) => ({
          label: maritalData.title,
          value: maritalData.code,
        })
      );
      dispatch(
        HealthSlice.actions.HEALTH_DROPDOWNDATA({
          key: "CHILD_RELATIONSHIP",
          value: data,
        })
      );
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getRelationData(onSuccess, onError, param);
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    setMemberFields((prev) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "name" ? !validateFullName(value) : isEmpty(value),
      },
    }));
  };

  const SAVE_CHILD_DATA = (data: TOtherMemberDetails) => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };
    let param = {
      child4Gender: data.relationship.value === "daughter" ? "F" : "M",
      child4Name: data.name.value,
      child4DOB: data.dob.value,
      child4Weight: data.weight.value,
      child4HeightFeet: data.heightFeet.value,
      child4HeightInches: data.heightInches.value,
      section: "child4_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let formValue = { ...memberFields };
    formValue = {
      ...formValue,
      dob: { ...formValue.dob, warning: isEmpty(formValue.dob.value) },
      heightFeet: {
        ...formValue.heightFeet,
        warning: isEmpty(formValue.heightFeet.value),
      },
      heightInches: {
        ...formValue.heightInches,
        warning: isEmpty(formValue.heightInches.value),
      },
      name: {
        ...formValue.name,
        warning: !validateFullName(formValue.name.value),
      },
      relationship: {
        ...formValue.relationship,
        warning: isEmpty(formValue.relationship.value),
      },
      weight: { ...formValue.weight, warning: isEmpty(formValue.weight.value) },
    };
    setMemberFields({ ...formValue });

    if (
      !formValue.dob.warning &&
      !formValue.heightFeet.warning &&
      !formValue.heightInches.warning &&
      !formValue.name.warning &&
      !formValue.relationship.warning &&
      !formValue.weight.warning
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");
      
      SAVE_CHILD_DATA(formValue);
      if (PAGE_STATUS) {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
        );
      } else {
        if (
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.HDFC_HEALTH ||
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA ||
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.ADITY_BIRLA ||
          SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.CIGNA
        ) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
          );
        }
      }
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          INSURED_MEMBER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS,
            CHILD_FOUR_DETAILS: { ...formValue },
          },
          PAGE_STATUS: false,
        })
      );
    }
  };
  return isMobile ? (
    <MChildFourDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
    />
  ) : (
    <ChildFourDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
    />
  );
}
