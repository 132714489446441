import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import React from "react";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../Component/Footer/Footer";
import MedicalQuestion from "../../../../../Component/MedicalQuestion/MedicalQuestion";
import MemberRadioButtonNew from "../../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import { useAppSelector } from "../../../../../Store/hooks";
import { calculateAge } from "../../../../../SupportingFiles/HelpingFunction";
import MonthYearPicker from "../../../../../Component/FieldTypes/MonthYearPicker/MonthYearPicker";

function MedicalDetails({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
}: {
  medicalQuestionData: any;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
}) {
  const { INSURED_MEMBER_DETAILS } = useAppSelector((state) => state.Health);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        {Object.values(medicalQuestionData).map(
          (data: any, indexMainQuestion: number) => (
            <div key={indexMainQuestion}>
              <MedicalQuestion
                key={indexMainQuestion}
                main_class="medicalQuestion borderBottom"
                icon_class={data?.main_question?.icon_class}
                question_name={data?.main_question?.title}
                sub_details={data?.main_question?.description}
                input_id_first={data?.main_question?.id}
                input_id_second={data?.main_question?.id}
                input_name="diagnosed"
                toggle_button_status={data?.main_question?.status}
                attrName={["question", data?.main_question?.id]}
                value_update={updateMasterState}
                data={data}
                updateMasterState={updateMasterState}
                select_disease={
                  <Collapse
                    in={data?.main_question?.status}
                    className="medicalQuestion-SubSection"
                  >
                    <Box className="pr-2 mb-3">
                      {data?.main_question?.sub_question && (
                        <Box>
                          {/* <h5>High or low blood pressure</h5> */}
                          {data?.main_question?.sub_question &&
                            Object.values(
                              data?.main_question?.sub_question
                            )?.map(
                              (
                                dataSubQuestion: any,
                                indexSubQuestion: number
                              ) => (
                                <>
                                  <MedicalQuestion
                                    key={indexSubQuestion}
                                    main_class="medicalQuestion borderBottom"
                                    icon_class={"tobaco"}
                                    question_name={dataSubQuestion?.title}
                                    sub_details={
                                      <p>{dataSubQuestion?.description}</p>
                                    }
                                    input_id_first={dataSubQuestion?.id}
                                    input_id_second={dataSubQuestion?.id}
                                    input_name="blood pressure"
                                    toggle_button_status={
                                      dataSubQuestion?.status
                                    }
                                    attrName={[
                                      "subQuestion",
                                      data?.main_question?.id,
                                      dataSubQuestion?.id,
                                    ]}
                                    value_update={updateMasterState}
                                    data={data}
                                    dataSubQuestion={dataSubQuestion}
                                    updateMasterState={updateMasterState}
                                    select_disease={
                                      <Collapse
                                        in={dataSubQuestion?.status}
                                        className="medicalQuestion-SubSection"
                                      >
                                        <Grid container spacing={2}>
                                          {Object.values(
                                            dataSubQuestion?.member_data
                                          )?.map(
                                            (
                                              memberDataValue: any,
                                              memberIndex: any
                                            ) => (
                                              <>
                                                {memberDataValue.name !== "" ? (
                                                  <Grid xs={6} md={3} lg={4}>
                                                    <form>
                                                      {" "}
                                                      <MemberRadioButtonNew
                                                        class_name={
                                                          memberIndex === 0 ||
                                                            memberIndex === 1
                                                            ? "adult"
                                                            : "child"
                                                        }
                                                        checked={
                                                          memberDataValue?.selection_status
                                                        }
                                                        label_name={
                                                          memberDataValue.name
                                                        }
                                                        attrName={[
                                                          "memberData",
                                                          data.main_question.id,
                                                          dataSubQuestion.id,
                                                          memberDataValue.keyName,
                                                        ]}
                                                        value_update={
                                                          updateMasterState
                                                        }
                                                      />
                                                      {memberDataValue?.selection_status &&
                                                        memberDataValue.keyName && (
                                                          <>
                                                            {Object.values(
                                                              memberDataValue.field_data
                                                            ).map(
                                                              (
                                                                field_data_data: any
                                                              ) => {
                                                                let datePickerRendered =
                                                                  false; // Flag to track DatePicker rendering
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      field_data_data.id
                                                                    }
                                                                  >
                                                                    {field_data_data.field_type ===
                                                                      "DATEPICKER" &&
                                                                      !datePickerRendered ? (
                                                                        <MonthYearPicker
                                                                        class_name="inputField mb-5"
                                                                        date_validation_type="YEARS"
                                                                        error_message={
                                                                          "Select existing since"
                                                                        }
                                                                      title={
                                                                          field_data_data?.title
                                                                        }
                                                                        value={
                                                                          field_data_data
                                                                            ?.value
                                                                            ?.value
                                                                        }
                                                                        attrName={[
                                                                          "mainMemberDataField",
                                                                          data
                                                                            ?.main_question
                                                                            ?.id,
                                                                          dataSubQuestion?.id,
                                                                          field_data_data.id,
                                                                          memberDataValue.keyName,
                                                                        ]}
                                                                        value_update={
                                                                          updateMasterState
                                                                        }
                                                                        min_date={
                                                                          memberDataValue.keyName ===
                                                                            "adult_one"
                                                                            ? INSURED_MEMBER_DETAILS
                                                                              .ADULT_ONE_DETAILS
                                                                              .dob
                                                                              .value
                                                                              ? calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                              )
                                                                              : calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                              )
                                                                            : memberDataValue.keyName ===
                                                                              "adult_two"
                                                                              ? calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                              )
                                                                              : memberDataValue.keyName ===
                                                                                "child_one"
                                                                                ? calculateAge(
                                                                                  `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                )
                                                                                : memberDataValue.keyName ===
                                                                                  "child_two"
                                                                                  ? calculateAge(
                                                                                    `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                  )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_three"
                                                                                    ? calculateAge(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                    )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_four"
                                                                                      ? calculateAge(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                      )
                                                                                      : 25
                                                                        }
                                                                        max_date={
                                                                          0
                                                                        }
                                                                        default_date={subDays(
                                                                          new Date(),
                                                                          memberDataValue.keyName ===
                                                                            "adult_one"
                                                                            ? INSURED_MEMBER_DETAILS
                                                                              .ADULT_ONE_DETAILS
                                                                              .dob
                                                                              .value
                                                                              ? calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                              )
                                                                              : calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                              )
                                                                            : memberDataValue.keyName ===
                                                                              "adult_two"
                                                                              ? calculateAge(
                                                                                `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                              )
                                                                              : memberDataValue.keyName ===
                                                                                "child_one"
                                                                                ? calculateAge(
                                                                                  `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                )
                                                                                : memberDataValue.keyName ===
                                                                                  "child_two"
                                                                                  ? calculateAge(
                                                                                    `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                  )
                                                                                  : memberDataValue.keyName ===
                                                                                    "child_three"
                                                                                    ? calculateAge(
                                                                                      `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                    )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_four"
                                                                                      ? calculateAge(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                      )
                                                                                      : 25
                                                                        )}
                                                                        warn_status={
                                                                          field_data_data
                                                                            ?.value
                                                                            ?.warning
                                                                        }
                                                                      />
                                                                      // <DatePicker
                                                                      //   class_name="inputField mb-5"
                                                                      //   title={
                                                                      //     field_data_data?.title
                                                                      //   }
                                                                      //   value={
                                                                      //     field_data_data
                                                                      //       ?.value
                                                                      //       ?.value
                                                                      //   }
                                                                      //   attrName={[
                                                                      //     "mainMemberDataField",
                                                                      //     data
                                                                      //       ?.main_question
                                                                      //       ?.id,
                                                                      //     dataSubQuestion?.id,
                                                                      //     field_data_data.id,
                                                                      //     memberDataValue.keyName,
                                                                      //   ]}
                                                                      //   value_update={
                                                                      //     updateMasterState
                                                                      //   }
                                                                      //   min_date={
                                                                      //     memberDataValue.keyName ===
                                                                      //       "adult_one"
                                                                      //       ? INSURED_MEMBER_DETAILS
                                                                      //         .ADULT_ONE_DETAILS
                                                                      //         .dob
                                                                      //         .value
                                                                      //         ? calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                      //         )
                                                                      //         : calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                      //         )
                                                                      //       : memberDataValue.keyName ===
                                                                      //         "adult_two"
                                                                      //         ? calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                      //         )
                                                                      //         : memberDataValue.keyName ===
                                                                      //           "child_one"
                                                                      //           ? calculateAgeInDays(
                                                                      //             `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                      //           )
                                                                      //           : memberDataValue.keyName ===
                                                                      //             "child_two"
                                                                      //             ? calculateAgeInDays(
                                                                      //               `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                      //             )
                                                                      //             : memberDataValue.keyName ===
                                                                      //               "child_three"
                                                                      //               ? calculateAgeInDays(
                                                                      //                 `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                      //               )
                                                                      //               : memberDataValue.keyName ===
                                                                      //                 "child_four"
                                                                      //                 ? calculateAgeInDays(
                                                                      //                   `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                      //                 )
                                                                      //                 : 25
                                                                      //   }
                                                                      //   max_date={
                                                                      //     0
                                                                      //   }
                                                                      //   default_date={subDays(
                                                                      //     new Date(),
                                                                      //     memberDataValue.keyName ===
                                                                      //       "adult_one"
                                                                      //       ? INSURED_MEMBER_DETAILS
                                                                      //         .ADULT_ONE_DETAILS
                                                                      //         .dob
                                                                      //         .value
                                                                      //         ? calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                      //         )
                                                                      //         : calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                      //         )
                                                                      //       : memberDataValue.keyName ===
                                                                      //         "adult_two"
                                                                      //         ? calculateAgeInDays(
                                                                      //           `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                      //         )
                                                                      //         : memberDataValue.keyName ===
                                                                      //           "child_one"
                                                                      //           ? calculateAgeInDays(
                                                                      //             `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                      //           )
                                                                      //           : memberDataValue.keyName ===
                                                                      //             "child_two"
                                                                      //             ? calculateAgeInDays(
                                                                      //               `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                      //             )
                                                                      //             : memberDataValue.keyName ===
                                                                      //               "child_three"
                                                                      //               ? calculateAgeInDays(
                                                                      //                 `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                      //               )
                                                                      //               : memberDataValue.keyName ===
                                                                      //                 "child_four"
                                                                      //                 ? calculateAgeInDays(
                                                                      //                   `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                      //                 )
                                                                      //                 : 25
                                                                      //   )}
                                                                      //   warn_status={
                                                                      //     field_data_data
                                                                      //       ?.value
                                                                      //       ?.warning
                                                                      //   }
                                                                      //   date_validation_type="DAYS"
                                                                      //   error_message={
                                                                      //     "Select existing since"
                                                                      //   }
                                                                      // />
                                                                    ) : (
                                                                      field_data_data.field_type ===
                                                                      "TEXTFIELD" && (
                                                                        <RKTextField
                                                                          class_name="mb-5"
                                                                          title={
                                                                            field_data_data?.title
                                                                          }
                                                                          attrName={[
                                                                            "mainMemberDataField",
                                                                            data
                                                                              ?.main_question
                                                                              ?.id,
                                                                            dataSubQuestion?.id,
                                                                            field_data_data.id,
                                                                            memberDataValue.keyName,
                                                                            "desc",
                                                                          ]}
                                                                          value={
                                                                            field_data_data
                                                                              ?.value
                                                                              ?.value
                                                                          }
                                                                          value_update={
                                                                            updateMasterState
                                                                          }
                                                                          warn_status={
                                                                            field_data_data
                                                                              ?.value
                                                                              ?.warning
                                                                          }
                                                                          error_message={
                                                                            "Enter description"
                                                                          }
                                                                        />
                                                                      )
                                                                    )}
                                                                    {field_data_data.field_type ===
                                                                      "DATEPICKER" &&
                                                                      !datePickerRendered &&
                                                                      (datePickerRendered =
                                                                        true)}
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            )}
                                                          </>
                                                        )}
                                                    </form>
                                                  </Grid>
                                                ) : null}
                                              </>
                                            )
                                          )}
                                        </Grid>
                                      </Collapse>
                                    }
                                  />
                                </>
                              )
                            )}
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                }
              />
            </div>
          )
        )}
      </>
      <Footer
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
}

export default MedicalDetails;
