import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router";
import "../../Buttons/Buttons.scss";
import "../../../../../Component/Footer/Footer.scss";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { pageSlice } from "../../../../../Store/Slice_Reducer/HI/PageSlice/PageSlice";
import { HI_PATH } from "../../../../../Router/Path/HIPath";
import CustomButton from "../../../../CustomButton/CustomButton";

const Footer = ({
  continue_function,
  loader = false,
}: {
  continue_function?: Function;
  loader?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  return (
    <Box className="proposalFooter">
      <Grid container spacing={3}>
        <Grid xs={8} xsOffset={3} className="footerInner" alignItems="center">
          {
            <Link
              className="backStep"
              onClick={() => {
                dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
                navigate(-1);
              }}
            >
              <ArrowBackIcon />{" "}
              {window.location.pathname.includes(HI_PATH.HOUSE_HOLD_BREAKUP)
                ? "Back to Quotes"
                : "Back"}
            </Link>
          }

          <CustomButton
            text_name={
              window.location.pathname.includes(HI_PATH.REVIEW_DETAILS)
                ? "Make Payment"
                : PAGE_STATUS
                ? "Update"
                : "Continue"
            }
            class_name="regularPrimaryBtn"
            onClickFunction={() => {
              if (continue_function) continue_function();
            }}
            loading={loader}
            disabled={loader}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
