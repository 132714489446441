import { Box, Modal, CircularProgress, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../Store/hooks";
import CustomButton from "../CustomButton/CustomButton";
import { COLORS } from "../../SupportingFiles/colors";
import CustomCheckbox from "../FieldTypes/CustomCheckbox/CustomCheckbox";
import { useState } from "react";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";

function HealthRedirectPopup({
  open,
  setOpen,
  form,
  paymentFormData,
}: {
  open: boolean;
  setOpen: Function;
  form: any;
  paymentFormData: any;
}) {
  const [isChecked, setIschecked] = useState(false);
  const { CIS_DATA, PAYMENT_DATA } = useAppSelector((state) => state.Health);
  const pdfUrl =
    paymentFormData?.cisData?.document_url !== ""
      ? paymentFormData?.cisData?.document_url
      : CIS_DATA.document_url;

  const handleViewClick = () => {
    window.open(CIS_DATA.document_url, "_blank");
    // setIschecked(true);
  };

  // console.log("paymentFormData", typeof pdfUrl);
  const handleChangeCheckBox = (attrName: any, value: any) => {
    setIschecked(value);
  };
  return (
    <Modal open={open} className="modalWrapper">
      <Box className="modalContent xsWidth kycPopups">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Link
              className="close-button"
              onClick={() => {
                setIschecked(false);
                setOpen(false);
              }}
            />
          </Grid>
        </Grid>
        {isEmpty(CIS_DATA.document_url)  ? (
          <Grid container spacing={2} paddingTop={"50px"}>
            <Grid xs={12}>
              <p
                style={{
                  textAlign: "center",
                  marginBottom: "24px",
                  fontSize: "18px",
                }}
              >
                CIS Document failed to fetch, please try again
              </p>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} paddingTop={"50px"}>
              <Grid xs={12}>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "24px",
                    fontSize: "18px",
                  }}
                >
                  You can{" "}
                  <Link
                    sx={{
                      color: COLORS.primary,
                      fontWeight: 700,
                      cursor: "pointer",
                    }}
                    onClick={handleViewClick}
                  >
                    View
                  </Link>{" "}
                  and see our CIS Documents
                </p>
                <hr />
              </Grid>
              <Grid xs={12}>
                <CustomCheckbox
                  label={"I declare that i have read CIS documents."}
                  value_update={handleChangeCheckBox}
                  value={isChecked}
                  defaultChecked={false}
                  attrName={"checkbox"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12} className=""></Grid>
            </Grid>
            <Grid container columnSpacing={3} marginTop={"24px"}>
              <Grid xs={12} style={{ textAlign: "center" }}>
                <CustomButton
                  text_name={"MAKE PAYMENT"}
                  class_name="regularPrimaryBtn"
                  onClickFunction={() => {
                    setOpen(false);
                    form.submit();
                  }}
                  disabled={!isChecked}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default HealthRedirectPopup;
