import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Store/hooks";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import Footer from "../../../../Component/Footer/Footer";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import { TADDON_DETAILS_CIGNA_PRIME_PROTECT } from "../../../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";

function AddonDetailsPrimeProtect({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TADDON_DETAILS_CIGNA_PRIME_PROTECT;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Health
  );

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Critical Illness"
            toggle_status={addonStatus.critical_illness}
            value_update={updateMasterState}
            attrName={"critical_illness"}
            desc="Critical illness give a lump sum amount equal to Sum Insured in case of first diagnosis of the covered critical illnesses. It protects the insured against financial loss in the event of a terminal illness."
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Pro Health-Cumulative Bonus Booster"
            toggle_status={addonStatus.cumulative}
            value_update={updateMasterState}
            attrName={"cumulative"}
            desc="Additional Sum Insured of 25% will be added as cumulative bonus at the time of renewal in case there is no claim in the expiring policy"
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Deductible"
            toggle_status={addonStatus.reduction}
            value_update={updateMasterState}
            attrName={"reduction"}
            desc="Aggregate amount in a policy year, the insured is required to pay out-of-pocket before the insurance company begins covering the remaining expenses of a claim."
            sub_details={
              <Box maxWidth={"200px"}>
                <SelectDropdown
                  title="Sum Insured"
                  value={addonStatus.healthAddSumInsured}
                  value_update={updateMasterState}
                  attrName={"healthAddSumInsured"}
                  data={[
                    { value: "10000", label: "10000" },
                    { value: "25000", label: "25000" },
                  ]}
                />
              </Box>
            }
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Non-Medical Items"
            toggle_status={addonStatus.non_medical}
            value_update={updateMasterState}
            attrName={"non_medical"}
            desc="All the Non-Medical Items (as per List I of Annexure III) shall be covered up to the Sum Insured which in normal course are not covered."
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Personal Accident Cover"
            toggle_status={addonStatus.personal_accident}
            value_update={updateMasterState}
            attrName={"personal_accident"}
            desc="Lump sum benefit equal to 2 times of the base sum insured subject to a maximum of Rs50 lacs, payable in case of Accidental Death or Permanent Total Disablement."
          />
        </Grid>

        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="PRIME ENHANCE PLUS/Prime Freedom"
            toggle_status={addonStatus.prime_enhance}
            value_update={updateMasterState}
            attrName={"prime_enhance"}
            desc=""
          />
        </Grid>
        {/* <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="PRIME FREEDOM"
            toggle_status={addonStatus.prime_freedom}
            value_update={updateMasterState}
            attrName={"prime_freedom"}
            desc=""
          />
        </Grid> */}
        {
          (Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 300000 &&
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <= 500000) && (
            <Grid xs={12}>
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="PRIME ASSURE"
                toggle_status={addonStatus.prime_assure}
                value_update={updateMasterState}
                attrName="prime_assure"
                desc=""
              />
            </Grid>
          )
        }
        {
          (Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 750000 &&
            Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <= 10000000) &&
          (ADD_FORM.gender.value === "F" ||
            ADD_FORM.spouse_relationship.value === "Spouse") &&
          (
            <Grid xs={12}>
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="INFERTILITY TREATMENT"
                toggle_status={addonStatus.infertility_treatement}
                value_update={updateMasterState}
                attrName={"infertility_treatement"}
                desc=""
              />
            </Grid>)}
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Room Rent"
              toggle_status={addonStatus.room_rent}
              value_update={updateMasterState}
              attrName={"room_rent"}
              desc=""
            />
          </Grid>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Supreme Bonus"
              toggle_status={addonStatus.supreme_bonus}
              value_update={updateMasterState}
              attrName={"supreme_bonus"}
              desc=""
            />
          </Grid>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Surplus Benifit"
              toggle_status={addonStatus.surplus_benifit}
              value_update={updateMasterState}
              attrName={"surplus_benifit"}
              desc=""
            />
          </Grid>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Premium Management"
              toggle_status={addonStatus.premium_management}
              value_update={updateMasterState}
              attrName={"premium_management"}
              desc=""
            />
          </Grid> 
          {ADD_FORM.gender.value === "F" ||
            ADD_FORM.spouse_relationship.value === "Spouse" ?
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Women Care"
              toggle_status={addonStatus.woman_care}
              value_update={updateMasterState}
              attrName={"woman_care"}
              desc=""
            />
          </Grid> 
          : null } 
        <Footer
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          forward={() => {
            validate();
          }}
          value={0}
          backward={() => {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
            );
          }}
        />
      </Box>
    </>
  );
}

export default AddonDetailsPrimeProtect;
