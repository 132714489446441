import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BajajCKYC from "../../../Page/Desktop/CKYC/Bajaj/BajajCKYC";
import MBajajCKYC from "../../../Page/Mobile/CKYC/Bajaj/MBajajCKYC";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { CAR_SERVICES } from "../../../Services/Car/CarServices";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWAddFormServices";
import { CKYCSlice } from "../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../Store/Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { PaymentGatewaySlice } from "../../../Store/Slice_Reducer/PaymentGateway/PaymentGatewaySlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { CAR_CREATE_PROPOSAL_REQUEST } from "../../../SupportingFiles/CarHelper";
import {
  isEmpty,
  validateFullName,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TW_CREATE_PROPOSAL_REQUEST } from "../../../SupportingFiles/TWHelper";
import { TVerifyCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import {
  pushDataLayer,
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";
import { CLIENTS } from "../../../URLCollection/Clients";
import { HEALTH_CREATE_PROPOSAL_REQUEST } from "../../../SupportingFiles/HealthHelper";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";

function BajajCKYCContainer() {
  const [loader, setLoader] = useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const REDUX_HEAD = useAppSelector((state) => state);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const { TW, CKYC, Car, Health, CommonDropdownData } = useAppSelector(
    (state) => state
  );
  const { utm_medium, utm_source } = useAppSelector(
    (state) => state.TW.ADD_FORM_RESPONSE
  );
  const utm = {
    utm_medium,
    utm_source,
  };
  const { DISCOUNT_FORM, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Car
  );
  const [formDetails, setFormDetails] =
    useState<TVerifyCKYCForm>(VERIFY_KYC_FORM);
  const [pageStatus, setPageStatus] = useState<string>("");

  useEffect(() => {
    if (pageStatus === "HP") {
      setFormDetails((prev) => ({
        ...prev,
        full_name: {
          value: Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.name.value,
          warning: false,
        },
        dob: {
          value: Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob.value,
          warning: false,
        },
      }));
    }
  }, [pageStatus, Health.INSURED_MEMBER_DETAILS]);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    if (type) {
      setPageStatus(type);
    }
  }, []);

  useEffect(() => {
    if (DISCOUNT_FORM.vehicle_owned === "Organization") {
      setFormDetails((prev) => ({
        ...prev,
        customer_type: { value: "Organization", warning: false },
      }));
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYC,
          VERIFY_KYC_FORM: {
            ...formDetails,
            customer_type: { value: "Organization", warning: false },
          },
        })
      );
    }
  }, [DISCOUNT_FORM]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "customer_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
        dob: { value: "", warning: false },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  useEffect(() => {
    if (!isEmpty(pageStatus)) {
      GET_DROPDOWN_MASTER("POV");
    }
  }, [pageStatus]);

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response;
        const manupulated_data: TDropdown[] = Object.entries(response).map(
          ([key, value]) => ({
            label: `${value}`,
            value: `${key}`,
          })
        );

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: "POV",
            value: [
              { label: "CKYC Number", value: "Z" },
              { label: "PAN", value: "C" },
            ],
          })
        );
      }
    };
    const onError = (error: any) => {};

    switch (pageStatus) {
      case "CAR":
        const car_params = {
          company_code: `${REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, car_params);

        CKYCSlice.actions.BULK_UPDATE({
          ...CKYC,
          VERIFY_KYC_FORM: {
            ...CKYC.VERIFY_KYC_FORM,
            full_name: { ...Car.OWNER_DETAILS.name },
            dob: { ...Car.OWNER_DETAILS.dob },
          },
        });

        setFormDetails((prev) => ({
          ...prev,
          full_name: { ...Car.OWNER_DETAILS.name },
          dob: { ...Car.OWNER_DETAILS.dob },
        }));
        break;
      case "TW":
        const tw_params = {
          company_code: `${REDUX_HEAD.TW.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, tw_params);

        setFormDetails((prev) => ({
          ...prev,
          full_name: { ...TW.OWNER_DETAILS.name },
          dob: { ...TW.OWNER_DETAILS.dob },
        }));

        break;
      case "TERM":
        break;
      case "HP":
        const health_params = {
          company_code: `${REDUX_HEAD.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, health_params);
        break;
      default:
        break;
    }
  };
  // useEffect(()=>{
  //   dispatch(
  //     CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
  //       key: "POV",
  //       value: [
  //         { label: "CKYC Number", value: "Z" },
  //         { label: "PAN", value: "C" },
  //       ],
  //     })
  //   );
  // },[])

  const validateForm = () => {
    let data: TVerifyCKYCForm = { ...formDetails };

    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: { ...data.dob, warning: isEmpty(data.dob.value) },
      doc_type: { ...data.doc_type, warning: isEmpty(data.doc_type.value) },
      doc_id: { ...data.doc_id, warning: isEmpty(data.doc_id.value) },
      customer_type: {
        ...data.customer_type,
        warning: isEmpty(data.customer_type.value),
      },
      gender: { ...data.gender, warning: isEmpty(data.gender.value) },
    };

    setFormDetails(data);

    let hasError =
      data.full_name.warning ||
      data.doc_type.warning ||
      data.doc_id.warning ||
      data.customer_type.warning ||
      data.dob.warning;

    if (data.customer_type.value !== "Organization") {
      hasError = hasError || data.gender.warning;
    }

    if (!hasError) {
      if (window.location.hostname === CLIENTS.JIO) {
        pushMotorDataLayer(
          "jioinsure_flow_details",
          "ckyc",
          "proceed",
          pageStatus === "TW" ? "two wheeler" : pageStatus?.toLocaleLowerCase(),
          Car.REDIRECTED ? "JFS app" : "Insure website"
        );
        const eventProps = {
          action: "ckyc",
          click: "continue",
          type:
            pageStatus === "TW"
              ? "two wheeler"
              : pageStatus?.toLocaleLowerCase(),
          EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow_details", eventProps);
      }
      VERIFY_CKYC_DETAILS(data);
    }
  };

  const VERIFY_CKYC_DETAILS = (data: TVerifyCKYCForm) => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;
      if (error) {
        if (window.location.hostname === CLIENTS.JIO) {
          pushMotorDataLayer(
            "jioinsure_flow_details",
            "ckyc details failure",
            `${message}`,
            pageStatus === "TW"
              ? "two wheeler"
              : pageStatus?.toLocaleLowerCase(),
            Car.REDIRECTED ? "JFS app" : "Insure website"
          );
          const eventProps = {
            action: "ckyc",
            status: "ckyc details failure",
            message: `${message}`,
            type:
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),
            EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
          };
          sendEventToNativeApp("jioinsure_flow_details", eventProps);
        }
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            VERIFY_KYC_FORM: { ...data },
          })
        );
        setShowManualPopup(true);
      } else {
        if (window.location.hostname === CLIENTS.JIO) {
          pushMotorDataLayer(
            "jioinsure_flow_details",
            "ckyc details success",
            "na",
            pageStatus === "TW"
              ? "two wheeler"
              : pageStatus?.toLocaleLowerCase(),

            Car.REDIRECTED ? "JFS app" : "Insure website"
          );
          const eventProps = {
            action: "ckyc",
            status: "ckyc details failure",
            message: "na",
            type:
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),
            EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
          };
          sendEventToNativeApp("jioinsure_flow_details", eventProps);
        }

        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            VERIFY_KYC_FORM: { ...data },
            CKYC_DETAILS: response,
          })
        );
        // if (
        //   message === "No record found" ||
        //   response.kycStatus !== "KYC_SUCCESS"
        // ) {
        //   if(response.poiStatus ==="NOT_FOUND"){
        //     toast.error("Sorry, we are not getting any information from insurer side, your pan is not valid")
        //   }else{
        //     setShowManualPopup(true);
        //   }
        // } else {
        //   setShowCKYCDetailsPopup(true);
        // }
        if (message === "No record found" || message === "POI failed") {
          toast.error(
            "Sorry, we are not getting any information from insurer side, your pan is not valid"
          );
          dispatch(
            CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
              key: "POV",
              value: [
                { label: "CKYC Number", value: "Z" },
                { label: "PAN", value: "C" },
              ],
            })
          );
        } else if (message === "POI success") {
          toast.error(
            "Sorry, we are not getting any information from insurer side, your pan is not valid"
          );
          dispatch(
            CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
              key: "POV",
              value: [
                { label: "Driving License", value: "D" },
                { label: "GSTIN", value: "G" },
                { label: "NREGA Job card", value: "F" },
                { label: "Passport", value: "A" },
                { label: "UID", value: "E" },
                { label: "Voter ID", value: "B" },
              ],
            })
          );
        } else if (message === "POA failed") {
          setShowManualPopup(true);
        } else {
          setShowCKYCDetailsPopup(true);
        }
      }
    };

    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    setLoader(true);
    switch (pageStatus) {
      case "TW":
        let tw_dto = {
          quote_no: TW.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type.value,
          docIDNoForKyc: data.doc_id.value,
          genderForKyc: data.gender.value,
          insuranceType: "TW",
          transactionId: `${REDUX_HEAD.TW.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.pTransactionId}`,
          section: "premium_details",
          insurance_type: pageStatus,
        };
        CKYCServcies.VERIFY_BAJAJ_CKYC(onSuccess, onError, tw_dto);
        break;
      case "CAR":
        let car_dto = {
          quote_no: Car.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type.value,
          docIDNoForKyc: data.doc_id.value,
          genderForKyc: data.gender.value,
          insuranceType: "CAR",
          transactionId: `${REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.pTransactionId}`,
          section: "premium_details",
          insurance_type: pageStatus,
        };
        CKYCServcies.VERIFY_BAJAJ_CKYC(onSuccess, onError, car_dto);
        break;
      case "HP":
        let health_dto = {
          quote_no: Health.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type.value,
          docIDNoForKyc: data.doc_id.value,
          genderForKyc: data.gender.value,
          insuranceType: pageStatus,
          section: "premium_details",
          product_id: Health.SELECTED_QUOTE_DATA.productDetails.id,
          transactionId: `${REDUX_HEAD.Health.SELECTED_QUOTE_DATA.premiumDetails?.transactionid}`,
        };
        CKYCServcies.VERIFY_BAJAJ_HEALTH_CKYC(onSuccess, onError, health_dto);
        break;
      default:
        break;
    }
  };

  const CREATE_PROPOSAL = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      setTimeout(() => {
        setLoader(false);
      }, 3000);

      if (error) {
        const message = results.message;
        toast.error(`${message}`);
      } else {
        const response = results.response;

        const paymentRequest = response["paymentRequest"];
        const actionURL = paymentRequest["actionURL"];
        const actionMethod = paymentRequest["actionMethod"];

        let payment_gateway_url = "";

        if (actionMethod === "POST") {
          let form = document.createElement("form");
          form.action = actionURL;
          form.method = actionMethod;

          Object.entries(paymentRequest).forEach(([key, value]) => {
            if (key !== "actionURL" && key !== "actionMethod") {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = key;
              input.value = `${value}`;
              form.appendChild(input);
            }
          });

          document.body.appendChild(form);
          form.submit();
        } else {
          payment_gateway_url = actionURL;
          window.location.href = actionURL;
        }

        dispatch(
          PaymentGatewaySlice.actions.UPDATE_DATA({
            METHOD: actionMethod,
            PAYMENT_URL: payment_gateway_url,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    setLoader(true);

    switch (pageStatus) {
      case "TW":
        let param = TW_CREATE_PROPOSAL_REQUEST(TW, CKYC, utm);
        TW_ADD_FORM_SERVICES.PAYMENT(onSuccess, onError, param);
        break;
      case "CAR":
        let car_param = CAR_CREATE_PROPOSAL_REQUEST(
          Car,
          CKYC,
          ADD_FORM_RESPONSE
        );
        CAR_SERVICES.CREATE_PROPOSAL(onSuccess, onError, car_param);
        break;
      case "HP":
        let params: any = HEALTH_CREATE_PROPOSAL_REQUEST(
          Health,
          CKYC,
          CommonDropdownData
        );
        HEALTH_PROPOSAL_SERVICES.makePayment(onSuccess, onError, {
          ...params,
        });
        break;
      default:
        break;
    }
  };

  const CONTINUE_TO_PROPOSAL = () => {
    switch (pageStatus) {
      case "TW":
        CREATE_PROPOSAL();
        break;
      case "CAR":
        CREATE_PROPOSAL();
        break;
      case "HP":
        CREATE_PROPOSAL();
        // const onSuccess = (res: any) => {
        //   const results = res.results;
        //   const error = results.error;

        //   dispatch(HealthSlice.actions.PAYMENT_DATA(results.response));

        //   if (error) {
        //     const message = results.message;
        //     toast.error(`${message}`);
        //   } else {
        //     const response = results.response;

        //     const paymentRequest = response["paymentRequest"];
        //     const actionURL = paymentRequest["actionURL"];
        //     const actionMethod = paymentRequest["actionMethod"];

        //     let payment_gateway_url = "";

        //     if (actionMethod === "POST") {
        //       let form = document.createElement("form");
        //       form.action = actionURL;
        //       form.method = actionMethod;

        //       Object.entries(paymentRequest).forEach(([key, value]) => {
        //         if (key !== "actionURL" && key !== "actionMethod") {
        //           const input = document.createElement("input");
        //           input.type = "hidden";
        //           input.name = key;
        //           input.value = `${value}`;
        //           form.appendChild(input);
        //         }
        //       });

        //       document.body.appendChild(form);
        //       setLoader(false);
        //       form.submit();
        //     } else if (`${actionMethod.toLowerCase()}` === "get") {
        //       window.location.href = actionURL;
        //     } else {
        //       const response = results.response;
        //       if (actionMethod !== "POST") {
        //         const message = response.err_description;
        //         toast.error(`${message}`);
        //         return;
        //       } else {
        //         payment_gateway_url = actionURL;
        //         window.location.href = actionURL;
        //       }
        //     }
        //   }
        // };
        // const onError = () => {
        //   setLoader(false);
        // };
        // setLoader(true);
        // let params: any = HEALTH_CREATE_PROPOSAL_REQUEST(
        //   Health,
        //   CKYC,
        //   CommonDropdownData
        // );
        // HEALTH_PROPOSAL_SERVICES.makePayment(onSuccess, onError, {
        //   ...params,
        // });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {isMobile ? (
        <MBajajCKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          loader={loader}
        />
      ) : (
        <BajajCKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          loader={loader}
        />
      )}
    </div>
  );
}

export default BajajCKYCContainer;
