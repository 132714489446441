import { Route, Routes } from "react-router-dom";
import CareCKYCContainer from "../../../../../Container/CKYC/CARE/CareCKYCContainer";
import { useAppSelector } from "../../../../../Store/hooks";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { TRAVEL_ROUTE } from "../../../../Path/TravelRoutes";
import TravelProposalContainer from "../../TravelProposalContainer";

export const CARE_ROUTES = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Travel);
  return (
    <Routes>
      <Route
        path={TRAVEL_ROUTE.PROPOSAL_PAGE}
        element={<TravelProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<CareCKYCContainer />} />
        {/* <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonDetailsContainer />}
        /> */}
        {/* {SELECTED_QUOTE_DATA.productDetails.id === 1551 ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsCareHeartContainer />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainer />}
          />
        )} */}
      </Route>
    </Routes>
  );
};
