import { ECompanyCode } from "../../Services/Enum/EHome";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import { TADITYABIRLAAddon } from "../../Type/Health/ProposalDeatail/TADITYABIRLAAddon/TADITYABIRLAAddon";
import { TNivaBupaAddonQuestion } from "../../types/Health/HealthQuotation/TNivaBupaAddonQuestion";
import { TADDON_DETAILS_CIGNA_PRIME_ACTIVE } from "../../types/Health/ProposalDeatail/TCIGNAAddon/TADDON_DETAILS_CIGNA_PRIME_ACTIVE";
import {
  TADDON_DETAILS_CIGNA_LIFETIME_GLOBAL,
  TADDON_DETAILS_CIGNA_LIFETIME_INDIA,
  TADDON_DETAILS_CIGNA_PRIME_ADVANTAGE,
  TADDON_DETAILS_CIGNA_PRIME_PROTECT,
} from "../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";
import { TICICAddon } from "../../types/Health/ProposalDeatail/TICICAddon/TICICAddon";
import { TCareAddon } from "../../types/Health/TCareAddon";

export const GET_NIVA_BUPA_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;

  const manipulated_niva_bupa_addon_details: TNivaBupaAddonQuestion = {
    MQ01: {
      main_question: {
        id: "MQ01",
        icon_class: "tobaco",
        title: "Hospital Cash",
        description:
          "Get cash benefit on per day basis of hospitalization (up to 30 days) if hospitalised for more than 48 hours",
        status: addons_details?.hospital_benefit === "Y" ? true : false,
        field_data: {
          "MQ1-SQ1-F1": {
            id: "MQ1-SQ1-F1",
            title: "HVB Value",
            field_type: "DROPDOWN",
            value: { value: addons_details?.hcb, warning: false },
          },
        },
      },
    },
    MQ02: {
      main_question: {
        id: "MQ02",
        icon_class: "tobaco",
        title: "Acute Care Opted",
        description:
          "Unlimited tele-consultations with Doctors (General Medical Practitioners, Specialists & Super Specialists). Also, covers pharmacy and diagnostics up to limits.",
        status: addons_details?.acute_care === "Y" ? true : false,
        field_data: {
          "MQ1-SQ1-F1": {
            id: "MQ1-SQ1-F1",
            title: "BEST CONSULT",
            field_type: "CHECKBOX",
            value: {
              value: addons_details.addonOption ? false : true,
              warning: false,
            },
          },
          "MQ1-SQ1-F2": {
            id: "MQ1-SQ1-F2",
            title: "BEST CARE",
            field_type: "CHECKBOX",
            value: {
              value: addons_details.addonOption ? true : false,
              warning: false,
            },
          },
        },
      },
    },
    MQ03: {
      main_question: {
        id: "MQ03",
        icon_class: "tobaco",
        title: "Safeguard Opted",
        description:
          "Get access to additional benefits like- annual increase in coverage based on inflation rate, coverage for non-payable items and no impact on booster benefit.",
        status: addons_details?.safeguard_opted === "Y" ? true : false,
      },
    },
    MQ04: {
      main_question: {
        id: "MQ04",
        icon_class: "tobaco",
        title: "Safeguard+ Opted",
        description:
          "Get access to additional benefits like - annual increase in coverage based on inflation rate, Coverage for non-payable items (as per annexure 1), and protection of Booster+ benefit from small claims up to INR 1 Lakh",
        status: addons_details?.safeguard_plus_opted === "Y" ? true : false,
      },
    },
    MQ05: {
      main_question: {
        id: "MQ05",
        icon_class: "tobaco",
        title: "Personal Accident Cover",
        description:
          "Get additional protection from Accidental Death, Permanent Total Disability and Permanent Partial Disability. Coverage Equal to 5 times of Base Sum Insured, Maximum up to INR 1 Crore.",
        status: addons_details?.personal_accident === "Y" ? true : false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "Adult 1",
            image_class: "adult",
            selection_status:
              addons_details?.adult_personal_accident === "Y" ? true : false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "Adult 2",
            image_class: "adult",
            selection_status:
              addons_details?.spouse_personal_accident === "Y" ? true : false,
          },
        },
      },
    },
    MQ06: {
      main_question: {
        id: "MQ06",
        icon_class: "tobaco",
        title: "Disease Management",
        description:
          "Get day 1 coverage for Diabetes and Hypertension. Along with up to 20% renewal discount. (*Variant under Smart Health+ Add On.)",
        status: addons_details?.disease_management === "Y" ? true : false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "Adult 1",
            image_class: "adult",
            selection_status:
              addons_details?.adult_disease_management === "Y" ? true : false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Gold",
                field_type: "CHECKBOX",
                value: {
                  value:
                    addons_details?.pop_adult_disease_management === "G"
                      ? true
                      : false,
                  warning: false,
                },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Platinum",
                field_type: "CHECKBOX",
                value: {
                  value:
                    addons_details?.pop_adult_disease_management === "P"
                      ? true
                      : false,
                  warning: false,
                },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "Adult 2",
            image_class: "adult",
            selection_status:
              addons_details?.spouse_disease_management === "Y" ? true : false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Gold",
                field_type: "CHECKBOX",
                value: {
                  value:
                    addons_details?.pop_spouse_disease_management === "G"
                      ? true
                      : false,
                  warning: false,
                },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Platinum",
                field_type: "CHECKBOX",
                value: {
                  value:
                    addons_details?.pop_spouse_disease_management === "P"
                      ? true
                      : false,
                  warning: false,
                },
              },
            },
          },
        },
      },
    },
  };

  return manipulated_niva_bupa_addon_details;
};

export const GET_HDFC_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  const manipulated_hdfc_addon: TCareAddon = {
    field_BFB: false,
    NCBSuper: addons_details?.field_NCB === "1" ? true : false,
    field_34: addons_details?.field_34 === "1" ? true : false,
    SmartSelect: false,
    AirAmbulanceCover: true,
    CareShield: true,
    GlobalCoverage: { value: addons_details?.global_coverage, warning: false },
    field_43: false,
    field_AHC: true,
    field_IC: false,
    field_OPD: addons_details?.field_OPD === 1 ? true : false,
    field_OPD_data: { value: addons_details?.field_OPD_SI, warning: false },
    field_PED_tenure: { value: "1", warning: false },
    field_WB: true,
    field_CPW: addons_details?.field_CPW === 1 ? true : false,
    field_DK: addons_details?.field_DK === 1 ? true : false,
    field_DUE: addons_details?.field_DUE === 1 ? true : false,
    recharge: addons_details?.addon?.recharge === "Yes" ? true : false,
    critical_illness:
      addons_details?.addon?.critical_illness === "Yes" ? true : false,
    ipaRiderCheck: false,
    unlimitedRiderCheck: false,
    ipaSa: { value: "1000", warning: false },
    hdcSa: { value: addons_details?.addon?.hdcSa, warning: false },
    ipa: addons_details?.addon?.ipa,
    IsUnlimitedRestore:
      addons_details?.addon?.IsUnlimitedRestore === "Yes" ? true : false,
    optima_well: addons_details?.addon?.optima_well === "Yes" ? true : false,
    overseas_travel:
      addons_details?.addon?.overseas_travel === "Yes" ? true : false,
    health_hospital_cash_benefit: false,
    health_hospital_cash_benefit_sum_insured: "",
    critical_suminsured: {
      value: addons_details?.addon?.critical_suminsured,
      warning: false,
    },
    Iscritical_Illness:
      addons_details?.addon?.critical_illness === "Yes" ? true : false,
    Iscritical_Illness_sum_insured: "100000",
    Insured_CriticalIllnessAddonCover_PlanType:
      addons_details?.addon?.Insured_CriticalIllnessAddonCover_PlanType,
    isGlobalRider:
      addons_details?.addon?.isGlobalRider === "Yes" ? true : false,
    field_CS: addons_details?.field_CS === "1" ? true : false,
    field_HomeCare: false,
    field_UAR: addons_details?.field_UAR === "0" ? false : true,
    field_COPAY: addons_details?.field_COPAY === "1" ? true : false,
    field_AHC_Care_Advatage:
      addons_details?.field_AHC_Care_Advatage === "1" ? true : false,
  };

  return manipulated_hdfc_addon;
};

export const GET_MANIPAL_CIGNA_PRIME_ADVANTAGE_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  const manipulated_manipal_cigna_addon: TADDON_DETAILS_CIGNA_PRIME_ADVANTAGE =
    {
      critical_illness:
        addons_details?.critical_illness === "Yes" ? true : false,
      outpatient: addons_details?.outpatient === "Yes" ? true : false,
      cumulative: addons_details?.cumulative === "Yes" ? true : false,
      healthAddSumInsured: addons_details?.healthAddSumInsured,
      non_medical: addons_details?.non_medical === "Yes" ? true : false,
      personal_accident:
        addons_details?.personal_accident === "Yes" ? true : false,
      prime_enhance: addons_details?.prime_enhance === "Yes" ? true : false,
      room_rent: addons_details?.room_rent === "Yes" ? true : false,
      supreme_bonus: addons_details?.supreme_bonus === "Yes" ? true : false,
      surplus_benifit: addons_details?.surplus_benifit === "Yes" ? true : false,
      premium_management:
        addons_details?.premium_management === "Yes" ? true : false,
    };

  return manipulated_manipal_cigna_addon;
};

export const GET_MANIPAL_CIGNA_PRIME_PROTECT_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  const manipulated_manipal_cigna_addon: TADDON_DETAILS_CIGNA_PRIME_PROTECT = {
    critical_illness: addons_details?.critical_illness === "Yes" ? true : false,
    cumulative: addons_details?.cumulative === "Yes" ? true : false,
    reduction: addons_details?.reduction === "Yes" ? true : false,
    healthAddSumInsured: addons_details?.healthAddSumInsured,
    non_medical: addons_details?.non_medical === "Yes" ? true : false,
    personal_accident:
      addons_details?.personal_accident === "Yes" ? true : false,
    prime_enhance: addons_details?.prime_enhance === "Yes" ? true : false,
    prime_freedom: addons_details?.prime_freedom === "Yes" ? true : false,
    prime_assure: addons_details?.prime_assure === "Yes" ? true : false,
    infertility_treatement:
      addons_details?.infertility_treatement === "Yes" ? true : false,
    room_rent: addons_details?.room_rent === "Yes" ? true : false,
    supreme_bonus: addons_details?.supreme_bonus === "Yes" ? true : false,
    surplus_benifit: addons_details?.surplus_benifit === "Yes" ? true : false,
    premium_management:
      addons_details?.premium_management === "Yes" ? true : false,
    woman_care: addons_details?.woman_care === "Yes" ? true : false,
  };

  return manipulated_manipal_cigna_addon;
};

export const GET_MANIPAL_CIGNA_LIFETIME_GLOBAL_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  const manipulated_manipal_cigna_addon: TADDON_DETAILS_CIGNA_LIFETIME_GLOBAL =
    {
      critical_illness:
        addons_details?.critical_illness === "Yes" ? true : false,
      cumulative: addons_details?.cumulative === "Yes" ? true : false,
      wwhospitalized: addons_details?.wwhospitalized === "Yes" ? true : false,
      maternity_expenses:
        addons_details?.maternity_expenses === "Yes" ? true : false,
      infertility: addons_details?.infertility === "Yes" ? true : false,
      Oocyte: addons_details?.Oocyte === "Yes" ? true : false,
      health_360_advance:
        addons_details?.health_360_advance === "Yes" ? true : false,
      health_360_opd: addons_details?.health_360_opd === "Yes" ? true : false,
      health_360_shield:
        addons_details?.health_360_shield === "Yes" ? true : false,
      global: addons_details?.global === "Yes" ? true : false, // mandatory
      global_plus: addons_details?.global_plus === "Yes" ? true : false,
      coverage_type: addons_details?.coverage_type,
      coverage_type_toggle:
        addons_details?.global_plus === "Yes" ? true : false,
      coverage_illness: addons_details?.coverage_illness,
      coverage_illness_toggle:
        addons_details?.coverage_illness_toggle === "Yes" ? true : false,
      wwh_suminsured: addons_details?.wwh_suminsured,
      global_suminsured: addons_details?.global_suminsured,
    };

  return manipulated_manipal_cigna_addon;
};

export const GET_MANIPAL_CIGNA_LIFETIME_INDIA_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  const manipulated_manipal_cigna_addon: TADDON_DETAILS_CIGNA_LIFETIME_INDIA = {
    critical_illness: addons_details?.critical_illness === "Yes" ? true : false,
    cumulative: addons_details?.cumulative === "Yes" ? true : false, // mandatory
    wwhospitalized: addons_details?.wwhospitalized === "Yes" ? true : false, // mandatory
    wwh_suminsured: addons_details?.wwh_suminsured,
    maternity_expenses:
      addons_details?.maternity_expenses === "Yes" ? true : false,
    infertility: addons_details?.infertility === "Yes" ? true : false,
    Oocyte: addons_details?.Oocyte === "Yes" ? true : false,
    health_360_advance:
      addons_details?.health_360_advance === "Yes" ? true : false,
    health_360_opd: addons_details?.health_360_opd === "Yes" ? true : false,
    health_360_shield:
      addons_details?.health_360_shield === "Yes" ? true : false,
    health_plus: addons_details?.health_plus === "Yes" ? true : false,
    woman_plus: addons_details?.woman_plus === "Yes" ? true : false,
  };

  return manipulated_manipal_cigna_addon;
};

export const GET_MANIPAL_CIGNA_PRIME_ACTIVE_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  const manipulated_manipal_cigna_addon: TADDON_DETAILS_CIGNA_PRIME_ACTIVE = {
    non_medical: addons_details?.non_medical === "Yes" ? true : false,
    health_ckeck: addons_details?.health_ckeck === "Yes" ? true : false,
    waiver: addons_details?.waiver === "Yes" ? true : false,
    world_accident: addons_details?.world_accident === "Yes" ? true : false,
    wellness: addons_details?.wellness === "Yes" ? true : false,
  };

  return manipulated_manipal_cigna_addon;
};
export const GET_ADITYA_BIRLA_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  console.log("addons_details", addons_details);

  const manipulated_aditya_birla_addon: TADITYABIRLAAddon = {
    cancer_booster: addons_details?.cancer_booster === "Yes" ? true : false,
    reduction_in_pre_existing_disease_waiting_period:
      addons_details?.reduction_in_pre_existing_disease_waiting_period === "Yes"
        ? true
        : false,
    reduction_ped_waiting_period: isEmpty(
      addons_details?.reduction_ped_waiting_period
    )
      ? ""
      : addons_details?.room_rent_type,
    room_rent_type_options:
      addons_details?.room_rent_type_options === "Yes" ? true : false,
    room_rent_type: isEmpty(addons_details?.room_rent_type)
      ? ""
      : addons_details?.room_rent_type,
    critical_illness_cover:
      addons_details?.critical_illness_cover === "Yes" ? true : false,
    critical_illness_cover_SI: isEmpty(
      addons_details?.critical_illness_cover_SI
    )
      ? ""
      : addons_details?.critical_illness_cover_SI,
    personal_accident_cover:
      addons_details?.personal_accident_cover === "Yes" ? true : false,
    personal_accident_cover_SI: isEmpty(
      addons_details?.personal_accident_cover_SI
    )
      ? ""
      : addons_details?.personal_accident_cover_SI,
    chronic_care: addons_details?.chronic_care === "Yes" ? true : false,
    chronic_management_program:
      addons_details?.chronic_management_program === "Yes" ? true : false,
    durable_equipment_cover:
      addons_details?.durable_equipment_cover === "Yes" ? true : false,
    natureOfDuty: isEmpty(addons_details?.natureOfDuty)
      ? ""
      : addons_details?.natureOfDuty,
    NonChronic: addons_details?.NonChronic === "Yes" ? true : false,
    Diabetes: addons_details?.Diabetes === "Yes" ? true : false,
    Asthama: addons_details?.Asthama === "Yes" ? true : false,
    Hypertension: addons_details?.Hypertension === "Yes" ? true : false,
    Hyperlipidaemia: addons_details?.Hyperlipidaemia === "Yes" ? true : false,
    PTCA: addons_details?.PTCA === "Yes" ? true : false,
    COPD: addons_details?.COPD === "Yes" ? true : false,
    HighBMI: addons_details?.HighBMI === "Yes" ? true : false,
  };

  return manipulated_aditya_birla_addon;
};

export const GET_CARE_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  console.log( addons_details , '       addons_details addons_detailsaddons_detailsaddons_detailsaddons_details')
  const manipulated_care_addon: TCareAddon = {
    NCBSuper:
      addons_details?.field_NCB === "1" || addons_details?.field_NCB === 1
        ? true
        : false,
    field_34:
      addons_details?.field_34 === "1" || addons_details?.field_34 === 1
        ? true
        : false,
    SmartSelect:
      addons_details?.field_SS === "1" || addons_details?.field_SS === 1
        ? true
        : false,
    AirAmbulanceCover:
      addons_details?.field_35 === "1" || addons_details?.field_35 === 1
        ? true
        : false,
    CareShield:
      addons_details?.field_CS === "1" || addons_details?.field_CS === 1
        ? true
        : false,
    GlobalCoverage: { value: addons_details?.global_coverage, warning: false },
    field_43:
      addons_details?.field_43 === "1" || addons_details?.field_43 === 1
        ? true
        : false,
    field_OPD:
      addons_details?.field_OPD === "1" || addons_details?.field_OPD === 1
        ? true
        : false,
    field_BFB:
      addons_details?.field_BFB === "1" || addons_details?.field_BFB === 1
        ? true
        : false,
    field_OPD_data: { value: addons_details?.field_OPD_SI, warning: false },
    field_CPW:
      addons_details?.field_CPW === "1" || addons_details?.field_CPW === 1
        ? true
        : false,
    field_DK:
      addons_details?.field_DK === "1" || addons_details?.field_DK === 1
        ? true
        : false,
    field_DUE:
      addons_details?.field_DUE === "1" || addons_details?.field_DUE === 1
        ? true
        : false,
    recharge: addons_details?.addon?.recharge === "Yes" ? true : false,
    critical_illness:
      addons_details?.addon?.critical_illness === "Yes" ? true : false,
    ipaRiderCheck: false,
    unlimitedRiderCheck: false,
    ipaSa: { value: "1000", warning: false },
    hdcSa: { value: addons_details?.addon?.hdcSa, warning: false },
    ipa: addons_details?.addon?.ipa,
    IsUnlimitedRestore:
      addons_details?.addon?.IsUnlimitedRestore === "Yes" ? true : false,
    optima_well: addons_details?.addon?.optima_well === "Yes" ? true : false,
    overseas_travel:
      addons_details?.addon?.overseas_travel === "Yes" ? true : false,
    health_hospital_cash_benefit: false,
    health_hospital_cash_benefit_sum_insured: "",
    critical_suminsured: {
      value: addons_details?.addon?.critical_suminsured,
      warning: false,
    },
    Iscritical_Illness:
      addons_details?.addon?.critical_illness === "Yes" ? true : false,
    Iscritical_Illness_sum_insured: "100000",
    Insured_CriticalIllnessAddonCover_PlanType:
      addons_details?.addon?.Insured_CriticalIllnessAddonCover_PlanType,
    isGlobalRider:
      addons_details?.addon?.isGlobalRider === "Yes" ? true : false,
    field_CS:
      addons_details?.field_CS === "1" || addons_details?.field_CS === 1
        ? true
        : false,
    field_HomeCare: false,
    field_UAR: true,
    field_COPAY:
      addons_details?.field_COPAY === "1" || addons_details?.field_COPAY === 1
        ? true
        : false,
    field_AHC_Care_Advatage:
      addons_details?.field_AHC_Care_Advatage === "1" ||
      addons_details?.field_AHC_Care_Advatage === 1
        ? true
        : false,
    field_IC:
      addons_details?.field_IC === "1" || addons_details?.field_IC === 1
        ? true
        : false,
    field_AHC:
      addons_details?.field_AHC === "1" || addons_details?.field_AHC === 1
        ? true
        : false,
    field_PED_tenure: {
      value: addons_details?.field_PED_TENURE,
      warning: false,
    },
    field_WB:
      addons_details?.field_WB === "1" || addons_details?.field_WB === 1
        ? true
        : false,
  };

  return manipulated_care_addon;
};

export const GET_ICICI_ADDON_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  console.log("addons_details", typeof addons_details?.AddOn80);

  const manipulated_icici_addon: any = {
    Critical_Illness: addons_details?.AddOn78 === 1 ? true : false,
    Maternity: addons_details?.AddOn79 === 1 ? true : false,
    New_Born: addons_details?.AddOn80 === 1 ? true : false,
    Vaccination: addons_details?.AddOn81 === 1 ? true : false,
    // Domestic_Air_Ambulance: addons_details?.Critical_Illness === 1 ? true : false,
    personal_accident: addons_details?.AddOn82 === 1 ? true : false,
    SI_Protector: addons_details?.AddOn83 === 1 ? true : false,
    Claim_Protector: addons_details?.AddOn84 === 1 ? true : false,
    Compassionate_Visit: addons_details?.AddOn85 === 1 ? true : false,
    Nursing_at_Home: addons_details?.AddOn86 === 1 ? true : false,
    BeFit_A: addons_details?.AddOn87 === 1 ? true : false,
    BeFit_B: addons_details?.AddOn88 === 1 ? true : false,
    BeFit_C: addons_details?.AddOn89 === 1 ? true : false,
    BeFit_D: addons_details?.AddOn90 === 1 ? true : false,
    // BeFit_E: addons_details?.Critical_Illness === "Yes" ? true : false,
    // BeFit_F: addons_details?.Critical_Illness === "Yes" ? true : false,
    // BeFit_G: addons_details?.Critical_Illness === "Yes" ? true : false,
    // BeFit_H: addons_details?.Critical_Illness === "Yes" ? true : false,
    // HighBMI: addons_details?.HighBMI === "Yes" ? true : false,
  };

  return manipulated_icici_addon;
};

export const GET_ICICI_ADDON_GOLDEN_DATA = (response: any) => {
  const addons_details = response?.healthDetails?.addons_details;
  console.log("addons_details", typeof addons_details.AddOn1);

  const manipulated_icici_golden_addon: any = {
    // golden shield

    Claim_protector: addons_details?.AddOn1 === 1 ? true : false,
    Modification_base_co_payment: addons_details?.AddOn2 === 1 ? true : false,
    Voluntary_deduction: addons_details?.AddOn3 === 1 ? true : false,
    Care_management_program: addons_details?.AddOn4 === 1 ? true : false,
    Care_management_plus_program: addons_details?.AddOn5 === 1 ? true : false,
    VoluntaryCopaymentPercentage: addons_details?.VoluntaryCopaymentPercentage,
  };

  return manipulated_icici_golden_addon;
};
