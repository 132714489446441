import { EMotorCompCode } from "../Enum/EMotorCompCode";
import { TCKYC } from "../types/CKYC/TCKYC";
import { TBusinessType } from "../types/Common/TBusinessType";
import { TDropdown } from "../types/Common/TDropdown";
import { TTWSlice } from "../types/TTWSlice";
import { TTWAddonStatus } from "../types/TwoWheeler/TTWAddonStatus";
import { TTWSingleQuoteDetail } from "../types/TwoWheeler/TTWSingleQuoteDetail";
import {
  GET_CURRENT_DATE,
  addDaysToDate,
  calculateAgeInDays,
  calculateDateDifferenceInDays,
} from "./HelpingFunction";

export const DETERMINE_NEW_POLICY_DATE = (
  plan_type: TBusinessType,
  company_code: string,
  policy_expiry_date: string
) => {
  if (plan_type === "New") {
    return GET_CURRENT_DATE();
  } else {
    if (company_code === "G002") {
      if (calculateAgeInDays(policy_expiry_date) > 0) {
        const value = `${addDaysToDate(GET_CURRENT_DATE(), 2)}`;
        return value;
      } else {
        const value = `${addDaysToDate(policy_expiry_date, 1)}`;
        return value;
      }
    } else {
      if (calculateAgeInDays(policy_expiry_date) > 0) {
        const value = ` ${addDaysToDate(GET_CURRENT_DATE(), 1)}`;
        return value;
      } else {
        const value = `${addDaysToDate(policy_expiry_date, 1)}`;
        return value;
      }
    }
  }
};

export const DETERMINE_PLAN_TYPE = (
  business_type: TBusinessType,
  reg_date: string
) => {
  let plan_type:
    | ""
    | "Third Party"
    | "Comprehensive"
    | "Bundle Policy"
    | "SAOD" = "";
  if (business_type === "New") {
    plan_type = "Bundle Policy";
  } else {
    if (calculateDateDifferenceInDays(reg_date) < 1460) {
      plan_type = "SAOD";
    } else if (
      calculateDateDifferenceInDays(reg_date) > 1460 &&
      calculateDateDifferenceInDays(reg_date) <= 1826
    ) {
      plan_type = "SAOD";
    } else {
      plan_type = "Comprehensive";
    }
  }

  return plan_type;
};

export const DETERMINE_PLAN_TYPE_OPTIONS = (
  business_type: TBusinessType,
  reg_date: string
): TDropdown[] => {
  let data: TDropdown[] = [];
  if (business_type === "New") {
    data = [{ label: "Bundle Policy", value: "Bundle Policy" }];
  } else {
    if (calculateDateDifferenceInDays(reg_date) < 1460) {
      data = [
        { label: "SAOD", value: "SAOD" },
        { label: "Third Party", value: "Third Party" },
      ];
    } else if (
      calculateDateDifferenceInDays(reg_date) > 1460 &&
      calculateDateDifferenceInDays(reg_date) <= 1826
    ) {
      data = [
        { label: "SAOD", value: "SAOD" },
        { label: "Third Party", value: "Third Party" },
        { label: "Comprehensive", value: "Comprehensive" },
      ];
    } else {
      data = [
        { label: "Third Party", value: "Third Party" },
        { label: "Comprehensive", value: "Comprehensive" },
      ];
    }
  }
  return data;
};

export const TWPremiumCalRSA = (
  quote_details: TTWSingleQuoteDetail | undefined,
  addon_status: TTWAddonStatus,
  OWNERSHIP: "Individual" | "Organization"
) => {
  if (quote_details === undefined) return 0;
  const premium_details = quote_details.premiumDetails;

  const withAddonNetPremium =
    parseFloat(`${premium_details?.withAddonNetPremium}`) || 0;
  const withoutAddonNetPremium =
    parseFloat(`${premium_details?.withoutAddonNetPremium}`) || 0;

  let premium: number = 0;

  const paOwnerDriver = parseFloat(`${premium_details?.paOwnerDriver}`) || 0;
  const depreciationCover =
    parseFloat(`${premium_details?.depreciationCover}`) || 0;
  const roadSideAssistance =
    parseFloat(`${premium_details?.roadSideAssistance}`) || 0;
  const driverCover = parseFloat(`${premium_details?.driverCover}`) || 0;

  if (addon_status.rsa.value || addon_status.zero_depreciation.value) {
    premium =
      withAddonNetPremium -
      paOwnerDriver -
      depreciationCover -
      roadSideAssistance -
      driverCover;
  } else {
    premium = withoutAddonNetPremium - paOwnerDriver - driverCover;
  }

  if (
    addon_status.pa_owner_cover.value === true ||
    premium_details?.includedPAOwnerDriver === 1
  ) {
    premium = premium + paOwnerDriver;
  }

  if (
    OWNERSHIP === "Organization" &&
    (addon_status.pa_owner_cover.value === true ||
      premium_details?.includedPAOwnerDriver === 1)
  ) {
    premium -= paOwnerDriver;
  }

  if (addon_status.zero_depreciation.value === true) {
    premium = premium + depreciationCover;
  }

  if (
    addon_status.rsa.value === true ||
    premium_details?.freeRoadSideAssistance === 1
  ) {
    premium = premium + roadSideAssistance;
  }

  if (addon_status.driver_cover.value === true) {
    premium = premium + driverCover;
  }

  let tax_amount = (premium / 100) * 18;

  let final_premium = premium + tax_amount;

  return final_premium;
};

export const TWPremiumCal = (
  quote_details: TTWSingleQuoteDetail | undefined,
  addon_status: TTWAddonStatus,
  OWNERSHIP: "Individual" | "Organization"
) => {
  if (quote_details === undefined) return 0;
  let final_premium = 0;
  if (quote_details.CompanyDetails.company_code === EMotorCompCode.RSA) {
    final_premium = TWPremiumCalRSA(quote_details, addon_status, OWNERSHIP);
  } else {
    const premium_details = quote_details.premiumDetails;

    const netPremium = parseFloat(`${premium_details?.netPremium}`) || 0;
    const paOwnerDriver = parseFloat(`${premium_details?.paOwnerDriver}`) || 0;
    const depreciationCover =
      parseFloat(`${premium_details?.depreciationCover}`) || 0;
    const roadSideAssistance =
      parseFloat(`${premium_details?.roadSideAssistance}`) || 0;
    const driverCover = parseFloat(`${premium_details?.driverCover}`) || 0;

    let premium: number =
      netPremium -
      paOwnerDriver -
      depreciationCover -
      roadSideAssistance -
      driverCover;

    if (
      addon_status.pa_owner_cover.value === true ||
      premium_details?.includedPAOwnerDriver === 1
    ) {
      premium = premium + paOwnerDriver;
    }

    if (
      OWNERSHIP === "Organization" &&
      (addon_status.pa_owner_cover.value === true ||
        premium_details?.includedPAOwnerDriver === 1)
    ) {
      premium -= paOwnerDriver;
    }

    if (addon_status.zero_depreciation.value === true) {
      premium = premium + depreciationCover;
    }

    if (
      addon_status.rsa.value === true ||
      premium_details?.freeRoadSideAssistance === 1
    ) {
      premium = premium + roadSideAssistance;
    }

    if (addon_status.driver_cover.value === true) {
      premium = premium + driverCover;
    }

    let tax_amount = (premium / 100) * 18;

    final_premium = premium + tax_amount;
  }
  return final_premium;
};

export const cal_gst = (value: number) => {
  const gst_amount = (value / 118) * 18;

  return gst_amount;
};

export const TW_CREATE_PROPOSAL_REQUEST = (
  TWSlice: TTWSlice,
  CKYCSlice: TCKYC,
  ADD_FORM_RESPONSE: any
) => {
  console.log("ADD_FORM_RESPONSE", ADD_FORM_RESPONSE);

  const param = {
    UniqueRequestID:
      TWSlice.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
        .UniqueRequestID,
    kycPTransactionId: CKYCSlice.CKYC_DETAILS.transactionId,
    formsixty: CKYCSlice.VERIFY_KYC_FORM.form_sixety.value,
    utm_medium: ADD_FORM_RESPONSE?.utm_medium,
    utm_source: ADD_FORM_RESPONSE?.utm_source,
    vehicle_owned:
      TWSlice.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails
        .company_code === EMotorCompCode.ACKO
        ? TWSlice.OWNER_DETAILS.customer_type.value
        : CKYCSlice.VERIFY_KYC_FORM.customer_type.value,
    quote_no: TWSlice.ADD_FORM_RESPONSE.quote_no,
    product_code:
      TWSlice.SELECTED_QUOTE_DATA.quotationDetail?.productDetails.product_code,
    company_code:
      TWSlice.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code,
    prev_depth_cover: "",
    registrationCharge: TWSlice.ADD_FORM_RESPONSE.registration_no,
    firstYearInsurancePremium: "",
    customer_name: TWSlice.OWNER_DETAILS.name.value,
    customer_mobile: TWSlice.OWNER_DETAILS.mobile.value,
    customer_email: TWSlice.OWNER_DETAILS.email.value,
    customer_gender: TWSlice.OWNER_DETAILS.gender.value,
    customer_dob: TWSlice.OWNER_DETAILS.dob.value,
    customer_marital: TWSlice.OWNER_DETAILS.maritalStatus.value,
    customer_gst_no: "",
    chassis_number: TWSlice.VEHICLE_DETAILS.chasis_no.value,
    engine_number: TWSlice.VEHICLE_DETAILS.engine_no.value,
    existing_policy_no: TWSlice.VEHICLE_DETAILS.prev_policy_no.value,
    tp_existing_policy_no: TWSlice.VEHICLE_DETAILS.tp_policy.value,
    existing_premium_amount: "",
    pan_number: "",
    pre_ins_address: "",
    is_financed: TWSlice.VEHICLE_DETAILS.is_vehicle_on_loan ? "Yes" : "No",
    financier_name: TWSlice.VEHICLE_DETAILS.bank_name.value,
    financier_city: TWSlice.VEHICLE_DETAILS.city.value,
    nominee_relationship: TWSlice.NOMINEE_DETAILS.relationship.value,
    nominee_name: TWSlice.NOMINEE_DETAILS.fullName.value,
    nominee_dob: TWSlice.NOMINEE_DETAILS.dob.value,
    nominee_gender: "",
    address_p_house: TWSlice.ADDRESS_DETAILS.addressLineOne.value,
    address_p_area: TWSlice.ADDRESS_DETAILS.addressLineTwo.value,
    address_p_pincode: TWSlice.ADDRESS_DETAILS.pincode.value,
    address_p_district: "",
    address_p_city: TWSlice.ADDRESS_DETAILS.city,
    address_p_state: TWSlice.ADDRESS_DETAILS.state,
    address_mailing_same: TWSlice.ADDRESS_DETAILS.isMailingAddressSame
      ? "Yes"
      : "No",
    address_m_house: TWSlice.ADDRESS_DETAILS.mailingAddressLineOne.value,
    address_m_area: TWSlice.ADDRESS_DETAILS.mailingAddressLineTwo.value,
    address_m_pincode: TWSlice.ADDRESS_DETAILS.mailingPinCode.value,
    address_m_district: "",
    address_m_city: TWSlice.ADDRESS_DETAILS.mailingCity,
    address_m_state: TWSlice.ADDRESS_DETAILS.mailingState,
    address_i_house: "",
    address_i_area: "",
    address_i_pincode: "0",
    address_i_district: "",
    address_i_city: "",
    address_i_state: "",
    previous_policy_type: "",
    aadhar_number: "",
    inspection_mode: "",
    finalPremium: `${Math.round(
      TWPremiumCal(
        TWSlice.SELECTED_QUOTE_DATA.quotationDetail,
        TWSlice.ADDON_STATUS,
        CKYCSlice.VERIFY_KYC_FORM.customer_type.value
      )
    )}`,
    taxAmount: `${Math.round(
      cal_gst(
        TWPremiumCal(
          TWSlice.SELECTED_QUOTE_DATA.quotationDetail,
          TWSlice.ADDON_STATUS,
          CKYCSlice.VERIFY_KYC_FORM.customer_type.value
        )
      )
    )}`,
    inspectionMode: "",
    kycDetails: CKYCSlice.CKYC_DETAILS,
    create_propoal_response: TWSlice.create_proposal_response,
  };

  return param;
};
