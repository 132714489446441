import { createSlice } from "@reduxjs/toolkit";
import { TRAVEL_REDUCERS } from "./TravelReducer";
import { TTravelSlice } from "../../../types/Travel/TTravelSlice";

const initialState: TTravelSlice = {
  DROPDOWN_DATA: {
    CountryList: [],
    SUM_INSURED: [
      {
        label: "1,00,000",
        value: "100000",
      },
      {
        label: "2,00,000",
        value: "200000",
      },
    ],
  },
  ADD_FORM: {
    frequency: { value: "", warning: false },
    travelCountries: { value: [], warning: false },
    travelStartDate: { value: "", warning: false },
    travelEndDate: { value: "", warning: false },
    plan_type: { value: "", warning: false },
    travelDays: { value: "", warning: false },
    travelName: { value: "", warning: false },
    travelEmail: { value: "", warning: false },
    travelMobile: { value: "", warning: false },
    travelAges: [],
    memberDetails: {
      adult1_age: { value: "", warning: false },
      adult2_age: { value: "", warning: false },
      child1_age: { value: "", warning: false },
      child2_age: { value: "", warning: false },
      child3_age: { value: "", warning: false },
      child4_age: { value: "", warning: false },
    },
    travel_ped: { value: "", warning: false },
    sum_insured: { value: "", warning: false },
  },
  ADD_FORM_RESPONSE: {
    quote_no: "",
    utm_medium: "",
    utm_source: "",
  },
  REDIRECTED: false,
  SELECTED_QUOTE_DATA: {
    logo: "",
    companyCode: "",
    productID: "",
    sumInsured: "",
    loader: false,
    quote_details: {
      productDetails: {
        sum_insured: 0,
        id: 0,
        product_code: "",
        product_name: "",
        product_desc: "",
        buy_online_code: "",
      },
      premiumDetails: {
        premiumAmount: 0,
        discountAmount: 0,
        taxAmount: 0,
        finalPremium: 0,
      },
      companyDetails: {
        company_code: "",
        company_name: "",
        company_address: "",
        logo: "",
      },
    },
  },
  QUOTE_LOADER: false,
};

export const TravelSlice = createSlice({
  name: "Travel",
  initialState,
  reducers: TRAVEL_REDUCERS,
});

export default TravelSlice.reducer;
