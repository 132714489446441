import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../Services/Enum/EHome";
import {
  // ForShowOnPreviewPage,
  viral_fever_miltiple,
} from "../../../Container/Health/GODIGIT/MedicalDetailsContainerGoDigit/MedicalDropdownValue";
import { ForShowOnPreviewPage } from "../../../Container/Health/CIGNA/MedicalDropdownValue";
import { FIND_LABEL_OF_VALUE } from "../../../SupportingFiles/HelpingFunction";
import { TCignaMedicalQuestion } from "../../../types/Health/HealthQuotation/TCignaMedicalQuestion";

const MedicalDetailPreviewBoxCigna = () => {
  const {
    MEDICAL_QUESTION_DATA_CIGNA,
    SELECTED_QUOTE_DATA,
    INSURED_MEMBER_DETAILS,
    past_health_insurance_value,
    ADD_FORM_RESPONSE
  } = useAppSelector((state) => state.Health);
  const navigate = useNavigate();
  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TCignaMedicalQuestion>(MEDICAL_QUESTION_DATA_CIGNA);
  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;
  let founds = false;

  // Check if any main question's member has selection_status set to true
  Object.values(medicalQuestionData).forEach((main_data: any) => {
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.member_data
    ) {
      Object.values(main_data.main_question.member_data).forEach(
        (member_data: any) => {
          if (member_data && member_data.selection_status) {
            founds = true;
            console.log("main_data:", main_data);
            console.log("member_data:", member_data);
            console.log("founds:", founds);
            return; // Break out of the loop
          }
        }
      );
    }
  });

  // Check if any sub-question's member has selection_status set to true and the sub-question's status is true
  Object.values(medicalQuestionData).forEach((main_data: any) => {
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.sub_question
    ) {
      Object.values(main_data.main_question.sub_question).forEach(
        (sub_question_data: any) => {
          if (
            sub_question_data &&
            sub_question_data.status &&
            sub_question_data.member_data
          ) {
            Object.values(sub_question_data.member_data).forEach(
              (member_data: any) => {
                if (member_data && member_data.selection_status) {
                  found = true;
                  console.log("sub_question_data:", sub_question_data);
                  console.log("member_data:", member_data);
                  console.log("founds:", founds);
                  return; // Break out of the loop
                }
              }
            );
          }
        }
      );
    }
  });

  const getLabelByValue = (value: any) => {
    const item = viral_fever_miltiple.find((entry) => entry.value === value);
    return item ? item.label : value;
  };

  const getLabelsByValues = (values: any) => {
    return values.map((value: any) => getLabelByValue(value)).join(", ");
  };

  const [param, setParam] = React.useState("");
  React.useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const payment = params.get("cus_type");
    setParam(payment || "");
  }, []);

  console.log(medicalQuestionData, "medicalQuestionData");
  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found || founds ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.MEDICAL_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${param === "user" ? "&cus_type=user" : ""}`
              );
            }}
            className="previewBoxEdit"
          >
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>

      {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.CIGNA ? (
        <Grid xs={12}>
          {Object.values(medicalQuestionData).map((mainData: any) =>
            (mainData.main_question.id === "MQ01" ||
              mainData.main_question.id === "MQ03" ||
              mainData.main_question.id === "MQ04") &&
            mainData.main_question.status ? (
              <div key={mainData.main_question.id}>
                <h6 className="question">{mainData.main_question.title}</h6>
                <p className="mb-4">{mainData.main_question.description}</p>
                <ul className="member_list">
                  {Object.values(mainData.main_question.member_data || {}).map(
                    (memberData: any) =>
                      memberData.selection_status && (
                        <li>
                          <div
                            className={`${memberData.image_class} illness`}
                          ></div>
                          <p className="member_name">{memberData.name}</p>
                        </li>
                      )
                  )}
                </ul>
              </div>
            ) : null
          )}

          {Object.values(medicalQuestionData).map((mainData: any) =>
            mainData.main_question.id === "MQ06" ||
            mainData.main_question.id === "MQ07" ||
            mainData.main_question.id === "MQ08" ? (
              mainData.main_question.status ? (
                <div key={mainData.main_question.id}>
                  <h6 className="question">{mainData.main_question.title}</h6>
                  <p className="mb-4">{mainData.main_question.description}</p>
                </div>
              ) : null
            ) : null
          )}

          {Object.values(medicalQuestionData).map((mainData: any) =>
            mainData.main_question.status ? (
              <div key={mainData.main_question.id}>
                {Object.values(mainData.main_question.sub_question || {}).map(
                  (subQuestionData: any) =>
                    subQuestionData.status &&
                    Object.values(subQuestionData.member_data || {}).some(
                      (memberData: any) => memberData.selection_status
                    ) && (
                      <>
                        <Grid xs={12}>
                          <h6 className="question">{subQuestionData.title}</h6>
                        </Grid>
                        <ul className="member_list">
                          {Object.values(subQuestionData.member_data || {}).map(
                            (memberData: any) =>
                              memberData.selection_status && (
                                <li key={memberData.id}>
                                  <div
                                    className={`${memberData.image_class} illness`}
                                  />
                                  <p className="member_name">
                                    {memberData.name}
                                  </p>
                                  {/* {memberData.field_data &&
                                    Object.values(memberData.field_data).map(
                                      (fieldData: any) => {
                                        console.log(fieldData, "fieldData");
                                        const fieldValue = [
                                          "Treatment taken",
                                          "Current Status",
                                          "Complication/Recurrences",
                                          "Diagnosis year",
                                          "Last Consultation Date",
                                          "Histo-Pathology Examination Report",
                                          "Alcohol",
                                          "Smoking",
                                          "Tobacco",
                                        ].includes(fieldData.title)
                                          ? fieldData.value.value
                                          : FIND_LABEL_OF_VALUE(
                                              ForShowOnPreviewPage,
                                              fieldData.value.value
                                            );
                                        return (
                                          <p
                                            key={fieldData.id}
                                            className="member_name">
                                            {`${fieldData.title}: ${fieldValue}`}
                                          </p>
                                        );
                                      }
                                    )} */}
                                  {memberData.field_data &&
                                    Object.values(memberData.field_data).map(
                                      (fieldData: any) => {
                                        console.log(fieldData, "fieldData");

                                        const fieldValue = [
                                          "Treatment taken",
                                          "Current Status",
                                          "Complication/Recurrences",
                                          "Diagnosis year",
                                          "Last Consultation Date",
                                          "Histo-Pathology Examination Report",
                                          "Alcohol",
                                          "Smoking",
                                          "Tobacco",
                                        ].includes(fieldData.title)
                                          ? fieldData.value.value
                                          : FIND_LABEL_OF_VALUE(
                                              ForShowOnPreviewPage,
                                              fieldData.value.value
                                            );

                                        if (
                                          fieldData.title ===
                                          "Histo-Pathology Examination Report"
                                        ) {
                                          if (fieldData.value.value === "") {
                                            return;
                                          }
                                        }

                                        return (
                                          <p
                                            key={fieldData.id}
                                            className="member_name"
                                          >
                                            {`${fieldData.title}: ${fieldValue}`}
                                          </p>
                                        );
                                      }
                                    )}
                                </li>
                              )
                          )}
                        </ul>
                      </>
                    )
                )}
              </div>
            ) : null
          )}
        </Grid>
      ) : (
        <Grid container columnSpacing={2}>
          <Grid xs={12}></Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MedicalDetailPreviewBoxCigna;
