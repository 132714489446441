import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import AddonDetails from "../../../Page/Desktop/Health/CIGNA/AddonDetails";
import AddonDetailsPrimeActive from "../../../Page/Desktop/Health/CIGNA/AddonDetailsPrimeActive";
import { TADDON_DETAILS_CIGNA_PRIME_ACTIVE } from "../../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";
import { Box } from "@mui/material";
import MAddonDetailsPrimeActive from "../../../Page/Mobile/Health/CIGNA/MAddonDetailsPrimeActive";

export default function AddonDetailsContainerPrimeActive() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADDON_DETAILS_CIGNA_PRIME_ACTIVE,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const { Health,CKYC } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addonStatus, setAddonStatus] =
    useState<TADDON_DETAILS_CIGNA_PRIME_ACTIVE>(
      ADDON_DETAILS_CIGNA_PRIME_ACTIVE
    );
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    updatePreiumApi();
    dispatch(HealthSlice.actions.ADDON_DETAILS_CIGNA_PRIME_ACTIVE(addonStatus));
  }, [addonStatus]);

  const updateMasterState = (attrName: any, value: any) => {
    setAddonStatus((prev) => ({
      ...prev,
      [attrName]: value,
    }));
  };

  console.log(addonStatus, "addonStatus");

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_CIGNA_PRIME_ACTIVE: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      gender: ADD_FORM.gender.value,
      addon: {
        non_medical: addonStatus.non_medical === true ? "Yes" : "No",
        health_ckeck: addonStatus.health_ckeck === true ? "Yes" : "No",
        waiver: addonStatus.waiver === true ? "Yes" : "No",
        world_accident: addonStatus.world_accident === true ? "Yes" : "No",
        wellness: "Yes",
      },
    };
    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonCignaPrimeActive(
      onSuccess,
      onError,
      param
    );
  };

  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
        if (PAGE_STATUS) {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
      );
    } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}${cus_type === "user" ? "&cus_type=user" : ""}`
        // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
      );
    }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "add_ons_details",
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
          0
          ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0)
          }C`
          : ""
        }`,
      non_medical: addonStatus.non_medical === true ? "Yes" : "No",
      health_ckeck: addonStatus.health_ckeck === true ? "Yes" : "No",
      waiver: addonStatus.waiver === true ? "Yes" : "No",
      world_accident: addonStatus.world_accident === true ? "Yes" : "No",
      wellness: "Yes",
    };
    // if (PAGE_STATUS) {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
    //   );
    // } else {
    //   navigate(
    //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
    //     // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
    //   );
    // }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };


  
  const FinalPremium = ()=>{
    const onSuccess=(res:any)=>{
console.log("res",res);

    }

    const onError =(err:any)=>{
      console.log("err",err);
    }

    let param:any={
       quote_no: ADD_FORM_RESPONSE.quote_no,
       final_premium : SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium
    }
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  }


  
  useEffect(() => {
    FinalPremium()
}, [SELECTED_QUOTE_DATA]);

        useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {" "}
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}{" "}
      {isMobile ? <MAddonDetailsPrimeActive
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        /> : (
        <AddonDetailsPrimeActive
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
}
