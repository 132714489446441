import { Box, Button, Modal, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "../../../../Component/CustomButton/CustomButton";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Loader from "../../../../Component/Loader/Loader";
import MFooter from "../../../../Component/MFooter/MFooter";
import ToggleSwitch from "../../../../Component/ToggleSwitch/ToggleSwitch";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { useAppSelector } from "../../../../Store/hooks";
import {
  isEmpty,
  uploadImage,
} from "../../../../SupportingFiles/HelpingFunction";
import { TStarManualCKYCForm } from "../../../../types/CKYC/TCKYC";
const MSTARManualKyc = ({
  isLoading,
  showCKYCDetailsPopup,
  setShowCKYCDetailsPopup,
  showManualPopup,
  setShowManualPopup,
  updateMasterState,
  formDetails,
  validateForm,
  CONTINUE_TO_PROPOSAL,
  toggle_status,
  manualKycToggle,
  checkPan,
}: {
  isLoading: boolean;
  showCKYCDetailsPopup: boolean;
  setShowCKYCDetailsPopup: Function;
  showManualPopup: boolean;
  setShowManualPopup: Function;
  updateMasterState: Function;
  formDetails: TStarManualCKYCForm;
  validateForm: Function;
  CONTINUE_TO_PROPOSAL: Function;
  toggle_status: boolean;
  manualKycToggle: boolean;
  checkPan: string;
}) => {
  const navigate = useNavigate();
  const {
    POI,
    GENDER,
    CUSTOMER_TYPE,
    CITY,
    AREA_TOWN,
    INCOME_SOURCE,
    OCCUPATION,
    FAMILY_RELATIONSHIP,
  } = useAppSelector((state) => state.CommonDropdownData);
  const { MANUAL_KYC_THIRD_PARTY_URL, CKYC_DETAILS, MANUAL_KYC_STATUS } =
    useAppSelector((state) => state.CKYC);

  const [imageName, setImageName] = useState<{
    identity_doc: string;
    passport: string;
    form_sixty: string;
  }>({
    identity_doc: "",
    passport: "",
    form_sixty: "",
  });
  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <Modal
          open={showCKYCDetailsPopup}
          className="modalWrapperMobile mwMobile-kyc"
        >
          <Box className="modalContent">
            <Grid container spacing={2}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-2">CKYC Details</h4>
                <p className="grey_p">
                  We fetch these details as per record, please verify
                </p>
                <Link
                  to={""}
                  className="close-button"
                  onClick={() => {
                    setShowCKYCDetailsPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12} className="">
                <span className="policy_number_box my-4">
                  {CKYC_DETAILS.kycNo}
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={6}>
                <h6>
                  Full Name:
                  <span>{CKYC_DETAILS.kycCustomerName}</span>
                </h6>
              </Grid>
              <Grid xs={6}>
                <h6>
                  DOB:
                  <span>{CKYC_DETAILS.kycDOB}</span>
                </h6>
              </Grid>
              <Grid xs={12}>
                <h6>
                  Address:
                  <span>{`${CKYC_DETAILS.kycAddress1}, ${CKYC_DETAILS.kycAddress2}, ${CKYC_DETAILS.kycAddress3}, ${CKYC_DETAILS.kycCity}, ${CKYC_DETAILS.kycState}, ${CKYC_DETAILS.kycPincode}`}</span>
                </h6>
              </Grid>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} marginTop={"24px"}>
              {/* <Grid xs={6} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  onClickFunction={() => {
                    setShowCKYCDetailsPopup(false);
                  }}
                  text_name={
                    <>
                      <SentimentDissatisfiedOutlinedIcon className="mr-2" />{" "}
                      It's not me
                    </>
                  }
                  class_name="redMdBtn"
                />
              </Grid> */}
              <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  text_name={"Continue"}
                  class_name="regularPrimaryBtn"
                  onClickFunction={() => {
                    CONTINUE_TO_PROPOSAL();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={showManualPopup} className="modalWrapperMobile ">
          <Box className="modalContent xl-width kycPopups">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-5">CKYC Details</h4>
                <Link
                  to="#"
                  className="close-button"
                  onClick={() => {
                    setShowManualPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <p>
                  Sorry,we are not getting any information from insurer side,
                  please click below Button to upload or verify with other
                  Document
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn my-4">
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  text_name={"Verify with other Document"}
                  class_name="mediumSecondaryBtn"
                  onClickFunction={() => {
                    setShowManualPopup(false);
                  }}
                />
              </Grid>
              <Grid xs={12} style={{ textAlign: "right" }} className="ctaBtn">
                <CustomButton
                  text_name={"Manual KYC"}
                  class_name="mediumPrimaryBtn"
                  onClickFunction={() => {
                    setShowManualPopup(false);
                    navigate(
                      `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.MANUAL_CKYC}?type=HP`
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn my-4">
                <hr />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <h5 className="sectionTitle">CKYC/eKYC Details</h5>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h6 className="mb-4 question">Provide CKYC Details for Proposer</h6>
            <p className="textGrey ckyc_p">
              <span className="textBold">
                CKYC refers to Central KYC (Know Your Customer)
              </span>
              , an initiative of the Government of India. The aim of this
              initiative is to have a structure in place which allows investors
              to complete their KYC only once before interacting with various
              entities across the financial sector.
            </p>
          </Grid>
        </Grid>
        {MANUAL_KYC_STATUS === false ? (
          <Grid container spacing={3}>
            <Grid xs={6}>
              <h6>Do you have PAN?</h6>
            </Grid>
            <Grid xs={6} textAlign="right">
              <ToggleSwitch
                toggle_status={manualKycToggle}
                value_update={updateMasterState}
                attrName={"manualKycToggle"}
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={3}>
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Proposer Full Name"}
              value={formDetails.full_name.value}
              attrName={"full_name"}
              value_update={updateMasterState}
              warn_status={formDetails.full_name.warning}
              validation_type="NAME"
              error_message={
                isEmpty(formDetails?.full_name.value)
                  ? "Enter Full Name"
                  : "Enter Valid Full Name"
              }
            />
          </Grid>
          {formDetails.customer_type.value === "Organization" ? (
            <Grid xs={12}>
              <DatePicker
                class_name="inputField"
                title={"Proposer DOI"}
                value={formDetails.dob.value}
                attrName={"dob"}
                value_update={updateMasterState}
                error_message="Select DOI"
                warn_status={formDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={100}
                max_date={0}
                default_date={new Date()}
              />
            </Grid>
          ) : (
            <Grid xs={12}>
              <DatePicker
                class_name="inputField"
                title={"Proposer DOB"}
                value={formDetails.dob.value}
                attrName={"dob"}
                value_update={updateMasterState}
                error_message="Select DOB"
                warn_status={formDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={100}
                max_date={18}
                default_date={subYears(new Date(), 18)}
              />
            </Grid>
          )}
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Proposer Family Full Name"}
              value={formDetails.family_full_name.value}
              attrName={"family_full_name"}
              value_update={updateMasterState}
              warn_status={formDetails.full_name.warning}
              validation_type="NAME"
              error_message={
                isEmpty(formDetails?.family_full_name.value)
                  ? "Enter Family Full Name"
                  : "Enter Valid Family Full Name"
              }
            />
          </Grid>
          <Grid xs={12}>
            <SelectDropdown
              class_name="inputField"
              title="Gender"
              value={formDetails.gender.value}
              attrName={"gender"}
              value_update={updateMasterState}
              data={GENDER}
              warn_status={formDetails.gender.warning}
              error_message="Select Gender"
            />
          </Grid>
          <Grid xs={12}>
            <SearchDropdown
              class_name="inputField"
              title="Document For Verification"
              value={formDetails.doc_type.value}
              attrName={"doc_type"}
              value_update={updateMasterState}
              data={POI}
              warn_status={formDetails.doc_type.warning}
              error_message="Select Document For Verification"
            />
          </Grid>
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Document ID"}
              value={formDetails.doc_id.value}
              attrName={"doc_id"}
              value_update={updateMasterState}
              warn_status={formDetails.doc_id.warning}
              validation_type="ALPHANUMERIC"
              max_length={50}
              error_message="Enter Document ID Number"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Identity Document"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.identity_doc}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse{" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    const fileSizeInMB = file.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        identity_doc: file.name,
                      }));
                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState("identity_doc", base64String);
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.identity_doc.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Passport Size Photo"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.passport}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse{" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    const fileSizeInMB = file.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        passport: file.name,
                      }));
                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState("passport", base64String);
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.passport.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>
          {MANUAL_KYC_STATUS === true && (
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Pan/Form 60"
                value={checkPan}
                attrName={"Pan_Form_60"}
                value_update={updateMasterState}
                data={[
                  { label: "Pan Card", value: "Pan" },
                  { label: "Form 60", value: "form60" },
                ]}
                warn_status={false}
                error_message=""
              />
            </Grid>
          )}
          {checkPan === "Pan" ? (
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Pan Card"}
                value={formDetails.pan_card.value}
                attrName={"pan_card"}
                value_update={updateMasterState}
                warn_status={formDetails?.pan_card.warning}
                validation_type="ALPHANUMERIC"
                error_message={"Enter Pan"}
              />
            </Grid>
          ) : (
            <Grid xs={12}>
              <Box className="fieldbox">
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Form 60"
                  variant="outlined"
                  className="uploadField inputField"
                  value={imageName.form_sixty}
                />
                <Button
                  variant="contained"
                  className="browsebtn"
                  component="label"
                >
                  Browse{" "}
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={(e: any) => {
                      const file = e.target.files[0];
                      const fileSizeInMB = file.size / (1024 * 1024);

                      if (fileSizeInMB > 6) {
                        toast.error(
                          "File size exceeds the maximum limit of 5 MB."
                        );
                      } else {
                        setImageName((prev) => ({
                          ...prev,
                          form_sixty: file.name,
                        }));

                        uploadImage(
                          file,
                          (base64Image: string, base64String: string) => {
                            updateMasterState(
                              "imageFormSixetyName",
                              base64String
                            );
                          }
                        );
                      }
                    }}
                  />
                </Button>
                {formDetails.imageFormSixetyName.warning ? (
                  <span className="error">Select valid file</span>
                ) : null}
              </Box>
            </Grid>
          )}
          <Grid xs={12}>
            <SearchDropdown
              class_name="inputField"
              title="Occupation"
              value={formDetails.Occupation.value}
              attrName={"Occupation"}
              value_update={updateMasterState}
              data={OCCUPATION}
              warn_status={formDetails.Occupation.warning}
              error_message="Select Occupation"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <SearchDropdown
              class_name="inputField"
              title="Family Relationship"
              value={formDetails.Family_relationship.value}
              attrName={"Family_relationship"}
              value_update={updateMasterState}
              data={FAMILY_RELATIONSHIP}
              warn_status={formDetails.Family_relationship.warning}
              error_message="Select Family Relationship"
            />
          </Grid>
          <Grid xs={12}>
            <SearchDropdown
              class_name="inputField"
              title="Income Source"
              value={formDetails.income_source.value}
              attrName={"income_source"}
              value_update={updateMasterState}
              data={INCOME_SOURCE}
              warn_status={formDetails.income_source.warning}
              error_message="Select Income Source"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <p>
              Are you a PEP - Politically Exposed Person or Any insured members
              <br />
              is PEP or related to PEP?
            </p>
          </Grid>
          <Grid xs={12} alignItems="flex-end">
            <ToggleSwitch
              toggle_status={toggle_status}
              value_update={updateMasterState}
              attrName={"toggle_switch"}
            />
          </Grid>
        </Grid>
        {formDetails.toggle_switch ? (
          <Grid container spacing={3}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"PEP Remarks"}
                value={formDetails.pep_remark}
                attrName={"pep_remark"}
                value_update={updateMasterState}
                warn_status={false}
                validation_type="NAME"
                error_message={
                  isEmpty(formDetails?.pep_remark)
                    ? "Enter PEP Remarks"
                    : "Enter PEP Remarks"
                }
              />
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={3}>
          <Grid xs={12}>
            <RKTextField
              validation_type="NUMBER"
              max_length={6}
              class_name="inputField"
              title={"Pincode"}
              value={formDetails.pincode.value}
              attrName={"pincode"}
              value_update={updateMasterState}
              warn_status={formDetails.pincode.warning}
              error_message="Enter Pincode"
            />
          </Grid>
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"H.No. / Building"}
              value={formDetails.houseNo.value}
              attrName={"houseNo"}
              value_update={updateMasterState}
              warn_status={formDetails.houseNo.warning}
              error_message="Enter H.No. / Building"
            />
          </Grid>
          <Grid xs={12}>
            <SearchDropdown
              class_name="inputField"
              title="City"
              value={formDetails.city.value}
              attrName={"city"}
              value_update={updateMasterState}
              data={CITY}
              warn_status={formDetails.city.warning}
              error_message="Select City"
            />
          </Grid>
          <Grid xs={12}>
            <SearchDropdown
              class_name="inputField"
              title={"Area / Town / Locality"}
              value={formDetails.area_town.value}
              attrName={"area_town"}
              value_update={updateMasterState}
              data={AREA_TOWN}
              warn_status={formDetails.area_town.warning}
              error_message="Enter Area / Town / Locality"
            />
          </Grid>
        </Grid>
        <MFooter
          loader={isLoading}
          attrName={"pageStatus"}
          forward={validateForm}
          backward={() => {
            navigate(-1);
          }}
        />
      </Box>
    </>
  );
};

export default MSTARManualKyc;
