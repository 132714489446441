import { EMotorCompCode } from "../../../Enum/EMotorCompCode";
import { useAppSelector } from "../../../Store/hooks";
import { ACKO_ROUTES } from "./ProposalRoutes/Acko/AckoRoutes";
import { BAJAJ_ROUTES } from "./ProposalRoutes/Bajaj/BajajRoutes";
import { FutureGenerali_ROUTES } from "./ProposalRoutes/FutureGenerali/FutureGeneraliRoutes";
import { GO_DIGIT_ROUTES } from "./ProposalRoutes/GoDigit/GoDigitRoutes";
import { HDFC_ROUTES } from "./ProposalRoutes/HDFC/HDFCRoutes";
import { ICICI_ROUTES } from "./ProposalRoutes/ICICI/ICICIRoutes";
import { IFFCO_TOKIO_ROUTES } from "./ProposalRoutes/IffcoTokio/IffcoTokioRoutes";
import { KOTAK_ROUTES } from "./ProposalRoutes/Kotak/KotakRoutes";
import { MAGMA_ROUTES } from "./ProposalRoutes/Magma/MagmaRoutes";
import { RSA_ROUTES } from "./ProposalRoutes/RSA/RSARoutes";
import { Reliance_ROUTES } from "./ProposalRoutes/Reliance/RelianceRoutes";
import { TATA_ROUTES } from "./ProposalRoutes/Tata/TataRoutes";

function TWRoutes() {
  const company_code = useAppSelector(
    (state: any) =>
      state.TW.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code
  );
  return (
    <>
      {company_code === EMotorCompCode.HDFC
        ? HDFC_ROUTES
        : company_code === EMotorCompCode.ICICI
        ? ICICI_ROUTES
        : company_code === EMotorCompCode.GO_DIGIT
        ? GO_DIGIT_ROUTES
        : company_code === EMotorCompCode.RSA
        ? RSA_ROUTES
        : company_code === EMotorCompCode.BAJAJ
        ? BAJAJ_ROUTES
        : company_code === EMotorCompCode.ACKO
        ? ACKO_ROUTES
        : company_code === EMotorCompCode.Kotak
        ? KOTAK_ROUTES
        : company_code === EMotorCompCode.Reliance
        ? Reliance_ROUTES
        : company_code === EMotorCompCode.FutureGenerali
        ? FutureGenerali_ROUTES
        : company_code === EMotorCompCode.IffcoTokio
        ? IFFCO_TOKIO_ROUTES
        : company_code === EMotorCompCode.TATA
        ? TATA_ROUTES
        : company_code === EMotorCompCode.Magma
        ? MAGMA_ROUTES
        : null}
    </>
  );
}

export default TWRoutes;
