import { Box, Button, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "./../../../../../SCSS/CommonSCSS/ProposalForms.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import { TTermProposerData } from "../../../../../types/TTermSlice";
import CustomCheckbox from "../../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../../../Component/CustomButton/CustomButton";

const ProposerDetails = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
  isProposerSameInsured,
  setIsProposerSameInsured,
  isChecked,
  setChecked,
  openIPRUPDFModel,
  setOpenIPRUPDFModel,
  journeyBlock,
  GET_REDIRECTION_URL,
}: {
  proposerFields: TTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
  isProposerSameInsured: boolean;
  setIsProposerSameInsured: Function;
  isChecked: boolean;
  setChecked: Function;
  openIPRUPDFModel: boolean;
  setOpenIPRUPDFModel: Function;
  journeyBlock: boolean;
  GET_REDIRECTION_URL: Function;
}) => {
  const navigate = useNavigate();
  const { DROPDOWN_DATA, RIDERS_BENEFITS } = useAppSelector(
    (state) => state.Term
  );
  const handleCheckboxChange = (attrName: string, isChecked: boolean) => {
    setChecked(isChecked);
    console.log(isChecked, "isChecked");
  };

  const handleClick = () => {
    setIsProposerSameInsured((prevState: boolean) => !prevState);
  };
  console.log(
    "RIDERS_BENEFITS",
    RIDERS_BENEFITS.riders_benefits_response.policyPdf
  );
  return (
    <>
      <Modal open={openIPRUPDFModel} onClose={() => setOpenIPRUPDFModel(false)}>
        <Box className="modalWrapper">
          <Box
            sx={{
              transform: "translate(0,0)",
              transition: "trapnsform 0.3s",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box className="modalContent p-5">
              <Grid className="topbar" container alignItems="center">
                <Grid xs={12} textAlign={"right"} marginBottom={"12px"}>
                  <Button
                    disableRipple
                    className="closePopup"
                    onClick={() => {
                      setOpenIPRUPDFModel(false);
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignContent="flex-start">
                <Grid xs={12} textAlign={"center"}>
                  <div style={{ width: "100%", height: "500px" }}>
                    <iframe
                      src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                        RIDERS_BENEFITS.riders_benefits_response.policyPdf
                      )}&embedded=true`}
                      style={{
                        minWidth: "500px",
                        width: "100%",
                        height: "100%",
                        border: "none",
                      }}
                      title="PDF Viewer with Google Docs"
                    />
                  </div>
                </Grid>
                <Grid
                  xs={12}
                  textAlign={"center"}
                  marginTop={"12px"}
                  display={"flex"}
                  gap={"16px"}
                  justifyContent={"center"}
                >
                  <CustomButton
                    text_name={"Continue"}
                    class_name="regularPrimaryBtn"
                    onClickFunction={() => {
                      GET_REDIRECTION_URL();
                    }}
                    loading={loader}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box>
        <h5 className="sectionTitle">Proposer Details</h5>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Full Name"}
              validation_type="NAME"
              value={proposerFields.full_name.value}
              attrName={["full_name"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields?.full_name?.warning}
              max_length={75}
              error_message={
                isEmpty(proposerFields.full_name.value)
                  ? "Enter Full Name"
                  : "Enter Valid Full Name"
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={4}>
            <DatePicker
              class_name="inputField"
              title={"DOB"}
              value={proposerFields.dob.value}
              attrName={["dob"]}
              disabled={true}
              value_update={fieldsUpdateState}
              error_message="Enter DOB"
              warn_status={proposerFields?.dob?.warning}
            />
          </Grid>
          <Grid xs={4}>
            <SelectDropdown
              disabled
              class_name="inputField"
              title="Gender"
              value={proposerFields.gender.value}
              attrName={["gender"]}
              value_update={fieldsUpdateState}
              data={DROPDOWN_DATA.GENDER}
              warn_status={proposerFields?.gender?.warning}
              error_message="Select Gender"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={4}>
            <RKTextField
              disabled
              class_name="inputField"
              title={"Mobile"}
              value={proposerFields.mobile.value}
              attrName={["mobile"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields?.mobile?.warning}
              max_length={10}
              validation_type="NUMBER"
              error_message={
                isEmpty(proposerFields.mobile.value)
                  ? "Enter Mobile Number"
                  : "Enter Valid Mobile number"
              }
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Email"}
              value={proposerFields.email.value}
              attrName={["email"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields?.email?.warning}
              error_message={
                isEmpty(proposerFields.email.value)
                  ? "Enter Email"
                  : "Enter Valid Email"
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Pincode"}
              value={proposerFields.pincode.value}
              attrName={["pincode"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields?.pincode?.warning}
              validation_type="NUMBER"
              max_length={6}
              disabled={true}
              error_message={
                isEmpty(proposerFields.pincode.value)
                  ? "Enter Pincode"
                  : "Enter Valid Pincode"
              }
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Emergency Phone"}
              value={proposerFields.emergency_number.value}
              attrName={["emergency_number"]}
              value_update={fieldsUpdateState}
              warn_status={proposerFields?.emergency_number?.warning}
              max_length={10}
              validation_type="NUMBER"
              error_message={
                isEmpty(proposerFields.emergency_number.value)
                  ? "Enter Emergency Phone Number "
                  : "Enter Valid Emergency Phone Number"
              }
            />
          </Grid>
          <Grid xs={4}>
            <SelectDropdown
              class_name="inputField"
              title="Occupation"
              value={proposerFields?.occupation?.value}
              attrName={["occupation"]}
              value_update={fieldsUpdateState}
              data={DROPDOWN_DATA.OCCUPATION_DATA}
              warn_status={proposerFields?.occupation?.warning}
              error_message="Select Proposer Occupation"
            />
          </Grid>
          <Grid xs={4}>
            <SelectDropdown
              class_name="inputField"
              title="Qualification"
              value={proposerFields?.qualification?.value}
              attrName={["qualification"]}
              value_update={fieldsUpdateState}
              data={DROPDOWN_DATA.QUALIFICATION_DATA}
              warn_status={proposerFields?.qualification?.warning}
              error_message="Select Proposer Qualification"
            />
          </Grid>
        </Grid>

        <TermFooter
          loader={loader}
          forward={isChecked ? validateForm : handleClick}
          disableButton={journeyBlock}
        />
      </Box>
    </>
  );
};

export default ProposerDetails;
