import { useState } from "react";
import { useAppSelector } from "../../../../Store/hooks";
import { Box, Button, Modal, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../../Component/Loader/Loader";
import CustomButton from "../../../../Component/CustomButton/CustomButton";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import {
  isEmpty,
  uploadImage,
} from "../../../../SupportingFiles/HelpingFunction";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../Component/Footer/Footer";
import SearchDropdown from "../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";
import { toast } from "react-toastify";
import { TManualCKYCForm } from "../../../../types/CKYC/TCKYC";
const IffcoTokioManualKyc = ({
  isLoading,
  showCKYCDetailsPopup,
  setShowCKYCDetailsPopup,
  showManualPopup,
  setShowManualPopup,
  updateMasterState,
  formDetails,
  validateForm,
  CONTINUE_TO_PROPOSAL,
}: {
  isLoading: boolean;
  showCKYCDetailsPopup: boolean;
  setShowCKYCDetailsPopup: Function;
  showManualPopup: boolean;
  setShowManualPopup: Function;
  updateMasterState: Function;
  formDetails: TManualCKYCForm;
  validateForm: Function;
  CONTINUE_TO_PROPOSAL: Function;
  manualKycToggle: boolean;
}) => {
  const navigate = useNavigate();
  const { POI, POV, GENDER } = useAppSelector(
    (state) => state.CommonDropdownData
  );
  const { CKYC_DETAILS } =
    useAppSelector((state) => state.CKYC);

  const [imageName, setImageName] = useState<{
    doc_id_proof_image: string;
    doc_address_proof_image: string;
    photoDocument: String;
  }>({
    doc_id_proof_image: "",
    doc_address_proof_image: "",
    photoDocument: "",
  });
  const OCCUPATION = [
    { label: "Professional", value: "1" },
    { label: "Self Employed", value: "2" },
    { label: "Retired", value: "3" },
    { label: "House-wife", value: "4" },
    { label: "Student", value: "5" },
  ];

  const INCOME_SOURCE = [
    { label: "Salary", value: "1" },
    { label: "Business", value: "2" },
    { label: "Sale of Assets", value: "3" },
    { label: "Inheritence", value: "4" },
  ];
  const FAMILY_RELATIONSHIP = [
    { label: "Father", value: "1" },
    { label: "Spouse", value: "2" },
    { label: "Mother", value: "3" },
  ];

  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <Modal open={showCKYCDetailsPopup} className="modalWrapper">
          <Box className="modalContent xl-width kycPopups">
            <Grid container spacing={2}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-2">CKYC Details</h4>
                <p className="grey_p">
                  We fetch these details as per record, please verify
                </p>
                <Link
                  to={""}
                  className="close-button"
                  onClick={() => {
                    setShowCKYCDetailsPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12} className="">
                <span className="policy_number_box my-4">
                  {CKYC_DETAILS.kycNo}
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={6}>
                <h6>
                  Full Name:
                  <span>{CKYC_DETAILS.kycCustomerName}</span>
                </h6>
              </Grid>
              <Grid xs={6}>
                <h6>
                  DOB:
                  <span>{CKYC_DETAILS.kycDOB}</span>
                </h6>
              </Grid>
              <Grid xs={12}>
                <h6>
                  Address:
                  <span>{`${CKYC_DETAILS.kycAddress1}, ${CKYC_DETAILS.kycAddress2}, ${CKYC_DETAILS.kycAddress3}, ${CKYC_DETAILS.kycCity}, ${CKYC_DETAILS.kycState}, ${CKYC_DETAILS.kycPincode}`}</span>
                </h6>
              </Grid>
              <Grid xs={12}>
                <hr />
              </Grid>
            </Grid>
            <Grid container columnSpacing={3} marginTop={"24px"}>
              <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
                <CustomButton
                  text_name={"Continue"}
                  class_name="regularPrimaryBtn"
                  onClickFunction={() => {
                    CONTINUE_TO_PROPOSAL();
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={showManualPopup} className="modalWrapper ">
          <Box className="modalContent xl-width kycPopups">
            <Grid container spacing={3}>
              <Grid xs={12}>
                <h4 className="popup-heading mb-5">CKYC Details</h4>
                <Link
                  to="#"
                  className="close-button"
                  onClick={() => {
                    setShowManualPopup(false);
                  }}
                />
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12}>
                <p>
                  Sorry,we are not getting any information from insurer side,
                  please click below Button to upload or verify with other
                  Document
                </p>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn my-4">
                <hr />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={6} className="ctaBtn">
                <CustomButton
                  text_name={"Verify with other Document"}
                  class_name="mediumSecondaryBtn"
                  onClickFunction={() => {
                    setShowManualPopup(false);
                  }}
                />
              </Grid>
              <Grid xs={6} style={{ textAlign: "right" }} className="ctaBtn">
                <CustomButton
                  text_name={"Manual KYC"}
                  class_name="mediumPrimaryBtn"
                  onClickFunction={() => {
                    setShowManualPopup(false);
                    navigate(
                      `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.MANUAL_CKYC}?type=HP`
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} className="ctaBtn my-4">
                <hr />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <h5 className="sectionTitle">CKYC/eKYC Details</h5>
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h6 className="mb-4 question">Provide CKYC Details for Proposer</h6>
            <p className="textGrey ckyc_p">
              <span className="textBold">
                CKYC refers to Central KYC (Know Your Customer)
              </span>
              , an initiative of the Government of India. The aim of this
              initiative is to have a structure in place which allows investors
              to complete their KYC only once before interacting with various
              entities across the financial sector.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Customer Type"
              value={formDetails.customer_type.value}
              attrName={"customer_type"}
              value_update={updateMasterState}
              data={[
                {
                  label: "Individual",
                  value: "Individual",
                },
                {
                  label: "Organization",
                  value: "Organization",
                },
              ]}
              warn_status={formDetails.customer_type.warning}
              error_message="Customer Type"
            />
          </Grid>
          {formDetails.customer_type.value === "Organization" ? null : (
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={formDetails.gender.value}
                attrName={"gender"}
                value_update={updateMasterState}
                data={GENDER}
                warn_status={formDetails.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
          )}
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Proposer Full Name"}
              value={formDetails.full_name.value}
              attrName={"full_name"}
              value_update={updateMasterState}
              warn_status={formDetails.full_name.warning}
              validation_type="NAME"
              error_message={
                isEmpty(formDetails?.full_name?.value)
                  ? "Enter Full Name"
                  : "Enter Valid Full Name"
              }
            />
          </Grid>
          {formDetails.customer_type.value === "Organization" ? (
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"Proposer DOI"}
                value={formDetails.dob.value}
                attrName={"dob"}
                value_update={updateMasterState}
                error_message="Select DOI"
                warn_status={formDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={100}
                max_date={0}
                default_date={new Date()}
              />
            </Grid>
          ) : (
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"Proposer DOB"}
                value={formDetails.dob.value}
                attrName={"dob"}
                value_update={updateMasterState}
                error_message="Select DOB"
                warn_status={formDetails.dob.warning}
                date_validation_type="YEARS"
                min_date={100}
                max_date={18}
                default_date={subYears(new Date(), 18)}
              />
            </Grid>
          )}
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Related person Full Name"}
              value={formDetails.relatedPersonName?.value}
              attrName={"relatedPersonName"}
              value_update={updateMasterState}
              warn_status={formDetails.relatedPersonName?.warning}
              validation_type="NAME"
              error_message={
                isEmpty(formDetails?.relatedPersonName?.value)
                  ? "Enter Family Full Name"
                  : "Enter Valid Family Full Name"
              }
            />
          </Grid>
          <Grid xs={4}>
            <SearchDropdown
              class_name="inputField"
              title="Document For Id Proof"
              value={formDetails.doc_type_id_proof.value}
              attrName={"doc_type_id_proof"}
              value_update={updateMasterState}
              data={POV}
              warn_status={formDetails.doc_type_id_proof.warning}
              error_message="Select Document For Verification"
            />
          </Grid>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Document no. of ID Proof"}
              value={formDetails.doc_no_id_proof.value}
              attrName={"doc_no_id_proof"}
              value_update={updateMasterState}
              warn_status={formDetails.doc_no_id_proof.warning}
              validation_type="ALPHANUMERIC"
              max_length={50}
              error_message="Enter Document no. of ID Proof"
            />
          </Grid>
          <Grid xs={4}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Select Document Proof"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.doc_id_proof_image}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse{" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    const fileSizeInMB = file.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        doc_id_proof_image: file.name,
                      }));
                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState("doc_id_proof_image", base64String);
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.doc_id_proof_image.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>
          <Grid xs={4}>
            <SearchDropdown
              class_name="inputField"
              title="Document For Address Proof"
              value={formDetails.doc_type_address_proof.value}
              attrName={"doc_type_address_proof"}
              value_update={updateMasterState}
              data={POI}
              warn_status={formDetails.doc_type_address_proof.warning}
              error_message="Select Document For Verification"
            />
          </Grid>
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Document Address Proof Number"}
              value={formDetails.doc_address_id_proof?.value}
              attrName={"doc_address_id_proof"}
              value_update={updateMasterState}
              warn_status={formDetails.doc_address_id_proof?.warning}
              validation_type="ALPHANUMERIC"
              max_length={50}
              error_message="Enter Document ID Number"
            />
          </Grid>
          <Grid xs={4}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Select Document Proof"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.doc_address_proof_image}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse{" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    const fileSizeInMB = file.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        doc_address_proof_image: file.name,
                      }));
                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState(
                            "doc_address_proof_image",
                            base64String
                          );
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.doc_address_proof_image.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <SearchDropdown
              class_name="inputField"
              title="Family Relationship"
              value={formDetails?.relationshipType?.value}
              attrName={"relationshipType"}
              value_update={updateMasterState}
              data={FAMILY_RELATIONSHIP}
              warn_status={!!formDetails?.relationshipType?.warning}
              error_message="Select Family Relationship"
            />
          </Grid>
          <Grid xs={6}>
            <Box className="fieldbox">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Select Customer Image"
                variant="outlined"
                className="uploadField inputField"
                value={imageName.photoDocument}
              />
              <Button
                variant="contained"
                className="browsebtn"
                component="label"
              >
                Browse{" "}
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    const fileSizeInMB = file.size / (1024 * 1024);

                    if (fileSizeInMB > 6) {
                      toast.error(
                        "File size exceeds the maximum limit of 5 MB."
                      );
                    } else {
                      setImageName((prev) => ({
                        ...prev,
                        photoDocument: file.name,
                      }));
                      uploadImage(
                        file,
                        (base64Image: string, base64String: string) => {
                          updateMasterState("photoDocument", base64String);
                        }
                      );
                    }
                  }}
                />
              </Button>
              {formDetails.photoDocument?.warning ? (
                <span className="error">Select valid file</span>
              ) : null}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <RKTextField
              validation_type="NUMBER"
              max_length={6}
              class_name="inputField"
              title={"Pincode"}
              value={formDetails.kycPincode?.value}
              attrName={"kycPincode"}
              value_update={updateMasterState}
              warn_status={formDetails.kycPincode?.warning}
              error_message="Enter Pincode"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"H.No. / Building"}
              value={formDetails.addressPHouse?.value}
              attrName={"addressPHouse"}
              value_update={updateMasterState}
              warn_status={formDetails.addressPHouse?.warning}
              error_message="Enter H.No. / Building"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              max_length={6}
              class_name="inputField"
              title={"State"}
              value={formDetails?.addressPState?.value}
              attrName={"addressPState"}
              value_update={updateMasterState}
              warn_status={formDetails.addressPState?.warning}
              error_message="Enter State"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              max_length={6}
              class_name="inputField"
              title="City"
              value={formDetails?.addressPCity?.value}
              attrName={"addressPCity"}
              value_update={updateMasterState}
              warn_status={!!formDetails?.addressPCity?.warning}
              error_message="Select City"
            />
          </Grid>
        </Grid>
        <Footer
          loader={isLoading}
          attrName={"pageStatus"}
          forward={validateForm}
          backward={() => {
            navigate(-1);
          }}
        />
      </Box>
    </>
  );
};
export default IffcoTokioManualKyc;
