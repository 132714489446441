import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../Store/hooks";
import {
  formatAmountToCurrency,
  formatToCurrency,
} from "../../SupportingFiles/HelpingFunction";

const TravelLeftSidebar = () => {
  const { ADD_FORM, SELECTED_QUOTE_DATA, QUOTE_LOADER } = useAppSelector(
    (state) => state.Travel
  );
  const [adultCount, setAdultCount] = useState<number>(0);
  const [childCount, setChildCount] = useState<number>(0);

  useEffect(() => {
    // Calculate the adult and child counts based on the member details in ADD_FORM
    let newAdultCount = 0;
    let newChildCount = 0;

    if (ADD_FORM.memberDetails.adult1_age.value) newAdultCount++;
    if (ADD_FORM.memberDetails.adult2_age.value) newAdultCount++;
    if (ADD_FORM.memberDetails.child1_age.value) newChildCount++;
    if (ADD_FORM.memberDetails.child2_age.value) newChildCount++;
    if (ADD_FORM.memberDetails.child3_age.value) newChildCount++;
    if (ADD_FORM.memberDetails.child4_age.value) newChildCount++;

    // Update the state only if the counts have changed
    setAdultCount(newAdultCount);
    setChildCount(newChildCount);

    console.log(newAdultCount, 'adultCount', newChildCount);
  }, [ADD_FORM]);



  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={"healthIcon"}></Box>
      <h6 className="mb-2">
        <span className="p-1">
          {
            childCount == 0 ? `${adultCount}A` : `${adultCount}A${childCount}C`
          } Travellers
        </span>
      </h6>
      <img
        alt=""
        className="insurerLogo"
        src={SELECTED_QUOTE_DATA?.logo}
      />
      <h6 className="mb-6">
        {SELECTED_QUOTE_DATA.quote_details?.productDetails?.product_name}
      </h6>

      <h5>
        {formatToCurrency(
          Number(SELECTED_QUOTE_DATA?.sumInsured)
        )}
      </h5>
      <p className="mb-6">Sum Insured</p>
      <Box>
        <h5>
        </h5>
        <p className="mb-6">Country</p>
      </Box>
      <Box>
        <h5>{ADD_FORM?.travelStartDate?.value}
        </h5>
        <p className="mb-6">Start Date</p>
      </Box>
      <Box>
        <h5>{ADD_FORM?.travelEndDate?.value}
        </h5>
        <p className="mb-6">End Date</p>
      </Box>
      <h5 className="premium">
        {QUOTE_LOADER ? (
          <img src="../images/loader.svg" alt="" height="12px" />
        ) : (
          <>{formatAmountToCurrency(Number(SELECTED_QUOTE_DATA.quote_details?.premiumDetails?.finalPremium))}</>
        )}
      </h5>
      <p className="mb-6">Premium (Incl. GST)</p>
    </Grid>
  );
};

export default TravelLeftSidebar;
