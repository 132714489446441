import { CommonURLs } from "../../URLCollection/Common/CommonURLs";
import { PostAPI } from "../../Axios/AxiosInstanceToken";

export type TPaymentConf = {
  quote_no: string;
  product_code: string;
  company_code: string;
  transaction_id: string;
  insurance_type: string;
  payment_date: string;
  utm_medium: string;
  utm_source?: string;
};

const GET_PREMIUM_CONF = (
  onSuccess: Function,
  onError: Function,
  dto: TPaymentConf
) => {
  PostAPI.call(
    CommonURLs.PREMIUM_CONFIRMATION,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const DOWNLOAD_POLICY = (onSuccess: Function, onError: Function, data: any) => {
  PostAPI.call(
    CommonURLs.DOWNLOAD_POLICY,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const PREMIUM_CONFIRMATION_SERVICES = {
  GET_PREMIUM_CONF,
  DOWNLOAD_POLICY
};
